import { useEffect } from 'react';

import { useSidebarStore } from '@/store';
import { LOGO, ROUTES } from '@/utils/constants';
import { Backdrop, SideBarMenu } from '@/components';
import { useIsDeviceTabletOrLarger } from '@/components/hooks';

const Sidebar = () => {
  const {
    isSidebarIconOnlyView,
    isSidebarVisible,
    setIsSidebarVisible,
    setSidebarIconOnlyView,
  } = useSidebarStore();

  // Access the ability from the AbilityContext
  // const ability = useContext(AbilityContext);

  const SIDEBAR_MENU = [
    {
      title: 'General',
      items: [
        {
          name: 'Overview',
          link: ROUTES.OVERVIEW,
          icons: 'fill-overview',
        },
        {
          name: 'Products',
          link: ROUTES.PRODUCT.ALL_PRODUCTS,
          icons: 'fill-product',
          children: [
            {
              name: 'All Products',
              link: ROUTES.PRODUCT.ALL_PRODUCTS,
            },
            {
              name: 'Comparison',
              link: ROUTES.PRODUCT.COMPARISON,
            },
          ],
        },
        {
          name: 'Real Time Stock',
          link: ROUTES.REAL_TIME_STOCK,
          icons: 'fill-realtime-stock',
        },
        {
          name: 'Orders',
          link: ROUTES.ORDERS.MY_ORDERS,
          icons: 'fill-orders',
          children: [
            {
              name: 'My Orders',
              link: ROUTES.ORDERS.MY_ORDERS,
            },
            // {
            //   name: 'My Returns',
            //   link: ROUTES.ORDERS.MY_RETURNS,
            // },
          ],
        },
        // {
        //   name: 'Invoices',
        //   link: ROUTES.INVOICES,
        //   icons: 'fill-invoice',
        // },
      ],
    },
    {
      title: 'Management',
      items: [
        {
          name: 'Users & Teams',
          link: ROUTES.USER_AND_TEAMS.USER.LIST,
          icons: 'reg-users-and-teams',
          children: [
            {
              name: 'Users',
              link: ROUTES.USER_AND_TEAMS.USER.LIST,
            },
            {
              name: 'Teams',
              link: ROUTES.USER_AND_TEAMS.TEAM.LIST,
            },
          ],
        },
        {
          name: 'Marketing Resources',
          link: ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS,
          icons: 'fill-loud-speaker',
          children: [
            {
              name: 'Flash Promotions',
              link: ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS,
            },
          ],
        },
        {
          name: 'Settings',
          link: ROUTES.SETTINGS.GENERAL_SETTINGS,
          icons: 'fill-setting',
          children: [
            {
              name: 'General Settings',
              link: ROUTES.SETTINGS.GENERAL_SETTINGS,
            },
            {
              name: 'Personal Settings',
              link: ROUTES.SETTINGS.PERSONAL_SETTINGS,
            },
            // Conditionally include the "Credit Information" menu item
            // ...(ability.can(
            //   PERMISSIONS.MANAGE,
            //   RESTRICTED_SECTIONS.CREDIT_INFORMATION,
            // )
            //   ? [
            //       {
            //         name: 'Credit Information',
            //         link: ROUTES.SETTINGS.CREDIT_INFORMATION_SETTINGS,
            //       },
            //     ]
            //   : []),
          ],
        },
      ],
    },
  ];

  const isTabletOrLarger: boolean = useIsDeviceTabletOrLarger();

  useEffect(() => {
    setSidebarIconOnlyView(false);
    setIsSidebarVisible(false);
  }, [isTabletOrLarger]);

  return (
    <>
      <SideBarMenu
        expandSideBar={!isSidebarIconOnlyView}
        sidebarMenus={SIDEBAR_MENU}
        imageSrc={LOGO.WHITE_REG}
      />
      {isSidebarVisible && <Backdrop />}
    </>
  );
};

export default Sidebar;
