import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/utils/constants';
import { TOrder } from '@/schemas/orders/my-orders';
import { formatDate } from '@/utils/helper/datetime.helper';
import { IconCopy } from '@/components/ui/icons/regular/copy';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { scrollToTop, useAlert, useClipboard } from '@/components/hooks';
import { IconCheckCircle } from '@/components/ui/icons/fill/checkCircle';
import { Button, Dropdown, IconsComponent, Link, Modal } from '@/components';
import { useReOrderProduct } from '@/components/hooks/api/orders/userReorderProduct';
import { useGetOrderByCompany } from '@/components/hooks/api/orders/useGetOrderByCompany';
import { formatCompanyPrice } from '@/utils/helper/country-locale';

function ActionColumn(info: CellContext<TOrder, unknown>) {
  const order = info.row.original;

  const [showReOrderModal, setShowReOrderModal] = useState(false);

  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { copied, copyToClipboard } = useClipboard();

  const { mutate: reOrderProduct, isPending: isReOrdering } =
    useReOrderProduct();
  const { refetch: refetchOrderDetails } = useGetOrderByCompany();

  const handleReOrder = () => {
    if (order.id) {
      reOrderProduct(order.id, {
        onSuccess: () => {
          refetchOrderDetails().then((response) => {
            if (response.isSuccess) navigate(`/${ROUTES.CART}`);
          });
        },
        onError: (error) => {
          showAlert(error.message, 'error');
          scrollToTop();
        },
        onSettled: () => {
          setShowReOrderModal(false);
        },
      });
    }
  };

  const handleCopyID = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!order.id) return;

    await copyToClipboard(order.customId);
  };

  return (
    <>
      <Dropdown className='action'>
        <Dropdown.Toggle
          size='icon'
          intent='tertiary'
        >
          <IconsComponent
            icon='reg-dots-three'
            size='sm'
          />
        </Dropdown.Toggle>
        <Dropdown.Content position='right'>
          <Link
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            to={`/${ROUTES.ORDERS.MY_ORDERS}/${order.id}`}
          >
            View Order
          </Link>

          <Button
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            onClick={() => setShowReOrderModal(true)}
          >
            Re-Order
          </Button>
          <Button
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            onClick={handleCopyID}
          >
            {!copied ? (
              <>
                Copy Id{' '}
                <IconCopy
                  size={20}
                  fill='gray'
                />
              </>
            ) : (
              <>
                Copied
                <IconCheckCircle
                  size={20}
                  fill='green'
                />
              </>
            )}
          </Button>
        </Dropdown.Content>
      </Dropdown>
      <Modal
        showModal={showReOrderModal}
        setShowModal={setShowReOrderModal}
      >
        <Modal.Content className='lg:max-w-[600px]'>
          <Modal.Title>
            <h6>Re-Order</h6>
          </Modal.Title>
          <Modal.Body className='space-y-4'>
            <p className='text-base text-gray-800'>
              You are about to re-order product from order ID{' '}
              <span className='font-bold'>#{order.customId}</span>. Are you sure
              you want to continue to re-order?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Modal.CloseButton>Cancel</Modal.CloseButton>
            <Button
              onClick={handleReOrder}
              disabled={isReOrdering}
            >
              {isReOrdering ? 'Processing...' : 'Confirm Reorder'}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}

export function useMyOrderColumn() {
  const columns = useMemo<ColumnDef<TOrder>[]>(
    () => [
      {
        accessorKey: 'customId',
        header: 'Order ID',
        cell: (info) => (info.getValue() ? `#${info.getValue()}` : 'N/A'),
      },
      {
        accessorKey: 'product',
        header: 'No. of Products',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'createdAt',
        header: 'Order Placed Date',
        cell: (info) => {
          const date = info.row.original.createdAt;
          return <p className='text-gray-800'>{formatDate(date)}</p>;
        },
      },
      {
        accessorKey: 'placedBy',
        header: 'Order Placed By',
        enableSorting: false,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'amount',
        header: 'Order Amount',
        cell: (info) => {
          const amount = formatCompanyPrice(Number(info.row.original.amount));
          return <p className='text-gray-800'>{amount}</p>;
        },
      },
      {
        accessorKey: 'action',
        header: 'Action',
        enableSorting: false,
        cell: (info) => <ActionColumn {...info} />,
      },
    ],
    [],
  );
  return { columns };
}
