import { useState } from 'react';
import IconsComponent from '@/components/ui/icons/IconsComponent';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { countryCodeToFlag } from '@/utils/helper';

interface AddressInfo {
  fullName?: string;
  location?: string;
  address?: string;
  contact?: string;
  countryCode?: string;
}

interface DeliveryBillingProps {
  deliveryInfo: AddressInfo;
  billingInfo: AddressInfo;
}

export function DeliveryBilling({
  deliveryInfo,
  billingInfo,
}: DeliveryBillingProps) {
  const [expandedSections, setExpandedSections] = useState({
    delivery: false,
    billing: false,
  });

  const countryFlag = countryCodeToFlag(
    (deliveryInfo.countryCode ?? billingInfo.countryCode)!,
  );

  const toggleSection = (section: 'delivery' | 'billing') => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className='mt-5 shadow-sm'>
      {/* Delivery Information */}
      <div className='border-b bg-white'>
        <Button
          onClick={() => toggleSection('delivery')}
          intent='tertiary'
          className='flex w-full items-center justify-between p-4 text-left'
        >
          <div className='flex items-center gap-3'>
            <IconsComponent
              icon='reg-shipment'
              fill='#1F2937'
            />
            <span className='text-xl font-extrabold text-gray-800'>
              Delivery Information
            </span>
          </div>
          <div className='flex items-center gap-3'>
            <Badge variant='default'>{deliveryInfo.location}</Badge>
            <IconsComponent
              icon={
                expandedSections.delivery
                  ? 'reg-chevron-up'
                  : 'reg-chevron-down'
              }
              fill='#6E2534'
            />
          </div>
        </Button>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            expandedSections.delivery ? 'max-h-screen' : 'max-h-0'
          }`}
        >
          <div className='p-4'>
            <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3'>
              <div>
                <label
                  htmlFor='fullName'
                  className='text-base text-gray-500'
                >
                  Addressee
                </label>
                <p className='text-base font-bold text-gray-800'>
                  {deliveryInfo.fullName}
                </p>
              </div>
              <div>
                <label
                  htmlFor='shippingAddress'
                  className='text-base text-gray-500'
                >
                  Address
                </label>
                <p className='text-base font-bold text-gray-800'>
                  {deliveryInfo.address}
                </p>
              </div>
              <div>
                <label
                  htmlFor='contactPhoneNumber'
                  className='text-base text-gray-500'
                >
                  Contact Phone Number
                </label>
                <p className='text-base font-bold text-gray-800'>
                  <span className='emoji pr-2'>{countryFlag}</span>
                  {deliveryInfo.contact}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Billing Information */}
      <div className='bg-white'>
        <Button
          intent='tertiary'
          onClick={() => toggleSection('billing')}
          className='flex w-full items-center justify-between p-4 text-left'
        >
          <div className='flex items-center gap-3'>
            <IconsComponent
              icon='reg-receipt'
              fill='#1F2937'
            />
            <span className='text-xl font-extrabold text-gray-800'>
              Billing Information
            </span>
          </div>
          <div className='flex items-center gap-3'>
            <Badge variant='default'>{billingInfo.location}</Badge>
            <IconsComponent
              icon={
                expandedSections.billing ? 'reg-chevron-up' : 'reg-chevron-down'
              }
              fill='#6E2534'
            />
          </div>
        </Button>

        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            expandedSections.billing ? 'max-h-screen' : 'max-h-0'
          }`}
        >
          <div className='p-4'>
            <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3'>
              <div>
                <label
                  htmlFor='contactName'
                  className='text-base text-gray-500'
                >
                  Addressee
                </label>
                <p className='text-base font-bold text-gray-800'>
                  {billingInfo.fullName}
                </p>
              </div>
              <div>
                <label
                  htmlFor='address'
                  className='text-base text-gray-500'
                >
                  Address
                </label>
                <p className='text-base font-bold text-gray-800'>
                  {billingInfo.address}
                </p>
              </div>
              <div>
                <label
                  htmlFor='contactPhoneNumber'
                  className='text-base text-gray-500'
                >
                  Contact Phone Number
                </label>
                <p className='text-base font-bold text-gray-800'>
                  <span className='emoji pr-2'>{countryFlag}</span>
                  {billingInfo.contact}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
