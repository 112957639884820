import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  useAlert,
  useBulkUpdateUserStatus,
  useScrollToTop,
} from '@/components/hooks';
import { Label, FormGroup } from '@/components';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { ToggleSwitch } from '@/components/ui/switch/ToggleSwitch';
import { Status } from '@/schemas';
import { handleApiError } from '@/utils/helper';

export function ChangeStatus({
  status,
  isInvited,
  id,
}: {
  status: string | undefined;
  role?: string;
  isInvited?: boolean;
  id: string;
}) {
  const isUserActive = status === Status.ACTIVE;
  const [isChecked, setIsChecked] = useState(isUserActive);

  const queryClient = useQueryClient();

  const scrollToTop = useScrollToTop();

  const { showAlert } = useAlert();

  const { mutate, isPending } = useBulkUpdateUserStatus();

  const handleUpdateUserStatus = () => {
    setIsChecked((previousState) => !previousState);
    const updateUserStatus = {
      users: [id],
      accountStatus: isChecked ? Status.INACTIVE : Status.ACTIVE, // Use Status enum based on isChecked
    };
    mutate(updateUserStatus, {
      onSuccess: (response) => {
        queryClient.invalidateQueries({
          queryKey: [TANSTACK_QUERY_KEY.USER],
        });
        queryClient.invalidateQueries({
          queryKey: [TANSTACK_QUERY_KEY.USER_DETAIL],
        });
        showAlert(response.message, 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
        setIsChecked((previousState) => !previousState);
      },
      onSettled: () => {
        scrollToTop();
      },
    });
  };

  const isButtonDisabled = () => {
    return isInvited ? false : true;
  };

  return (
    <FormGroup
      title='Status'
      description="Inactive users won't have login access"
    >
      <div className='flex items-center space-x-2'>
        <ToggleSwitch
          disabled={
            status === Status.INVITE_PENDING || isPending || isButtonDisabled()
          }
          checked={isChecked}
          onChange={handleUpdateUserStatus}
        />
        <Label
          htmlFor='status'
          className='text-sm font-semibold text-gray-800'
        >
          Set status as active
        </Label>
      </div>
    </FormGroup>
  );
}
