import { useState } from 'react';

import { Button, Modal, FormGroup } from '@/components';
import {
  useAlert,
  useResendInvitationLink,
  useScrollToTop,
} from '@/components/hooks';
import { handleApiError } from '@/utils/helper';

export function ResendInvitationLink({
  userId,
  email,
  isInvited,
}: {
  userId: string;
  email: string;
  isInvited?: boolean;
}) {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const { showAlert } = useAlert();
  const scrollToTop = useScrollToTop();

  const { mutate, isPending } = useResendInvitationLink(userId);

  const handleResendInvitationLink = () => {
    mutate(undefined, {
      onSuccess: ({ message }) => {
        showAlert(message, 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        setShowResetPasswordModal(false);
        scrollToTop();
      },
    });
  };

  return (
    <FormGroup
      title='Resend Invitation Link'
      description='User will receive new invitation link.password reset link.'
      className='items-center'
    >
      <Modal
        showModal={showResetPasswordModal}
        setShowModal={setShowResetPasswordModal}
      >
        <Modal.Toggle
          intent='outline'
          variant='blue'
          className='w-full sm:w-auto'
          disabled={!isInvited}
        >
          Resend Invitation Link
        </Modal.Toggle>
        <Modal.Content>
          <Modal.Title>
            <h6>Resend Invitation Link</h6>
          </Modal.Title>
          <Modal.Body className='space-y-4'>
            <p className='text-base text-gray-800'>
              You are about to resend invitation link to{' '}
              <span className='font-bold'>{email}.</span>
            </p>
            <p className='text-base font-bold text-gray-800'>
              Are you sure you want to resend invitation link?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Modal.CloseButton>Cancel</Modal.CloseButton>
            <Button
              disabled={isPending}
              onClick={handleResendInvitationLink}
            >
              Resend Invitation Link
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </FormGroup>
  );
}
