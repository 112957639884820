import { LineItem } from '@/pages/orders/my-orders/order-details/LineItems';
import {
  TOrderItemSchema,
  TOrderItemVariantSchema,
  TSingleOrderItemSchema,
} from '@/schemas/api/orders/single-order-detail.schema';
import { formatDate } from './datetime.helper';
import { TSingleProductVariantSchema } from '@/schemas';
import { MediaType } from '../constants/app.key.constants';
import { limitNameWithLength } from './limitNameWithLength';
import { PaymentMethod } from '../constants/payment.constant';

interface TransformedOrderDetail {
  orderInfo: {
    orderDate: string;
    orderedBy: string;
    orderedByAvatar?: string;
    orderProgress: string;
    paymentStatus: string;
    totalAmount: number;
    orderId?: string;
    customId?: string;
    companyName?: string;
  };
  deliveryInfo: {
    fullName: string;
    location: string;
    address: string;
    contact: string;
    countryCode: string;
  };
  billingInfo: {
    fullName: string;
    location: string;
    address: string;
    contact: string;
    countryCode: string;
  };
  lineItems: LineItem[];
  orderSummary: {
    subtotal: number;
    carriageCost: number;
    estimatedVAT: number;
    orderTotal: number;
    shippingAmount: number;
  };
}

export interface Size {
  size: string;
  quantity: number;
  amount: number;
}

interface ProductVariant {
  size: number | string;
  quantity: number;
  amount: number;
}

// Function to create line items
function generateLineItems(
  orderItemVariants: TOrderItemVariantSchema[],
  items: TOrderItemSchema,
): ProductVariant[] {
  // Create a map of variant IDs to variants for faster lookup
  const variantMap = new Map<string, TSingleProductVariantSchema>();
  items.product.variants?.forEach((variant) => {
    variantMap.set(variant.id!, variant);
  });

  return orderItemVariants.map((orderItemVariant) => {
    const variant = variantMap.get(orderItemVariant.variants.variantId ?? '');

    if (!variant) {
      throw new Error(
        `Variant not found for variant ID: ${orderItemVariant.variants.variantId}`,
      );
    }

    // Calculate the amount (quantity * product price)
    const amount = orderItemVariant.quantity * Number(items.salePrice);

    return {
      size: variant.size!,
      quantity: orderItemVariant.quantity,
      amount: amount,
    };
  });
}

export function transformOrderDetail(
  apiResponse: TSingleOrderItemSchema,
): TransformedOrderDetail {
  const orderTotalAmount = Number(apiResponse.totalAmount);
  const billingAddress = [
    apiResponse.billingAddress?.city,
    apiResponse.billingAddress?.state,
    apiResponse.billingAddress?.country,
  ]
    .filter(Boolean) // Remove undefined, null, or empty values
    .join(', '); // Combine into a string

  const shippingAddress = [
    apiResponse.shippingAddress?.city,
    apiResponse.shippingAddress?.state,
    apiResponse.shippingAddress?.country,
  ]
    .filter(Boolean) // Remove undefined, null, or empty values
    .join(', '); // Combine into a string

  return {
    orderInfo: {
      orderDate: formatDate(apiResponse.createdAt),
      orderedBy:
        apiResponse.user.firstName && apiResponse.user.lastName
          ? `${apiResponse.user.firstName} ${apiResponse.user.lastName}`
          : 'N/A',
      orderedByAvatar: apiResponse.user.avatar ?? '',
      orderProgress: 'Order Placed',
      paymentStatus:
        apiResponse.transaction.paymentMethod === PaymentMethod.CREDIT
          ? 'By Credit'
          : 'Paid',
      totalAmount: orderTotalAmount,
      orderId: apiResponse.id,
      customId: apiResponse.customId,
      companyName: apiResponse.company.name,
    },
    deliveryInfo: {
      fullName: apiResponse.shippingAddress?.contactName ?? 'N/A',
      location: limitNameWithLength(
        apiResponse.shippingAddress?.title ?? 'N/A',
        50,
      ),
      address: shippingAddress,
      contact: apiResponse.shippingAddress?.phone ?? 'N/A',
      countryCode: apiResponse.shippingAddress?.countryCode ?? 'N/A',
    },
    billingInfo: {
      fullName: apiResponse.billingAddress?.contactName ?? 'N/A',
      location: limitNameWithLength(
        apiResponse.billingAddress?.title ?? 'N/A',
        50,
      ),
      address: billingAddress,
      contact: apiResponse.billingAddress?.phone ?? 'N/A',
      countryCode: apiResponse.billingAddress?.countryCode ?? '',
    },
    lineItems: apiResponse.orderItem.map((item) => ({
      id: item.id,
      sku: item.product.skuCode,
      name: item.product.name,
      color: item.product.colourName,
      colorCode: `#${item.product.colourHex}`,
      quantity: item.quantity,
      amount: item.totalPrice,
      sizes: generateLineItems(item.orderItemVariant, item), // This will now return Size[]
      image:
        item.product.medias?.find((media) => media.type === MediaType.THUMBNAIL)
          ?.url ?? '',
    })),
    orderSummary: {
      subtotal: Number(apiResponse.amount),
      carriageCost: 0.0,
      estimatedVAT: Number(apiResponse.vatAmount),
      orderTotal: orderTotalAmount,
      shippingAmount: Number(apiResponse.shippingAmount),
    },
  };
}
