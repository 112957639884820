import { Link, useNavigate, useParams } from 'react-router-dom';

import placholderImage from '@/assets/images/placeholder-image.png';

import {
  TProductCardSchema,
  TProductCardPriceDetailsSchema,
  TProductCardPriceDetailsMoqAndRrpSchema,
  TSingleProductDetailResponseSchema,
} from '@/schemas';
import {
  scrollToTop,
  useImageFallback,
  useURLSearchParams,
} from '@/components/hooks';
import {
  ImageComponent,
  IconsComponent,
  Button,
  Badge,
  Checkbox,
  Tooltip,
} from '@/components';
import {
  PRICETYPE,
  PRODUCTTYPE,
} from '@/utils/constants/product-stock.constants';
import { cn } from '@/utils/cn';
import { QUERY_CONSTANTS, ROUTES } from '@/utils/constants';
import { ProductType } from '@/interface';
import { AsyncLoaderImage } from '../image/AsyncImage';
import useCompareProductsStore from '@/store/compare-products.store';
import { formatCompanyPrice } from '@/utils/helper/country-locale';

export interface ProductCardPropsInterface extends TProductCardSchema {
  viewRangeToggler?: () => void;
  productType?: ProductType;
  productData?: TSingleProductDetailResponseSchema;
}

const PriceDetail = ({
  isDynamicPricing,
  buyPrice,
  priceType,
  rrp,
}: TProductCardPriceDetailsSchema) => {
  return (
    <div className='py-4 font-semibold text-gray-400'>
      BUY PRICE <br />
      <div className='flex gap-1'>
        <>
          <span className='text-xl font-bold text-gray-800'>
            {formatCompanyPrice(buyPrice)}
          </span>
          {rrp !== null && priceType !== PRICETYPE.RRP && buyPrice !== rrp && (
            <span
              className={cn(
                'text-xl font-bold text-gray-800',
                isDynamicPricing && 'font-normal text-gray-400 line-through',
              )}
            >
              {formatCompanyPrice(Number(rrp))}
            </span>
          )}
        </>
        {rrp !== null && priceType !== PRICETYPE.RRP && buyPrice !== rrp && (
          <Badge
            variant={'blue'}
            className='ml-3'
          >
            {priceType}
          </Badge>
        )}
      </div>
    </div>
  );
};

const NewProduct = ({ isProductNew }: { isProductNew: boolean }) => {
  return (
    <>
      {isProductNew && (
        <Badge
          variant={'green'}
          className='absolute right-2 top-2'
        >
          New
        </Badge>
      )}
    </>
  );
};

const ProductDetailMoqAndRrp = ({
  moq,
  rrp,
}: TProductCardPriceDetailsMoqAndRrpSchema) => {
  return (
    <div className='flex gap-4'>
      {moq && (
        <p className='font-semibold tracking-wider text-gray-400'>
          MOQ <span className='font-bold text-gray-800'>{moq}+</span>
        </p>
      )}
      {rrp && (
        <p className='font-semibold tracking-wider text-gray-400'>
          RRP&nbsp;
          <span className='font-bold text-gray-800'>
            {formatCompanyPrice(Number(rrp))}
          </span>
        </p>
      )}
    </div>
  );
};

export function ProductCard({
  buyPrice,
  imageSrc,
  imageAlt,
  productCode,
  productName,
  rrp,
  isProductNew,
  isDynamicPricing,
  moq,
  id,
  priceType,
  viewRangeToggler,
  productType = PRODUCTTYPE.PRODUCT,
  productData,
  categoryId,
}: ProductCardPropsInterface) {
  const { categoryId: urlCategoryId, promotionId } = useParams();
  const navigate = useNavigate();
  const {
    compareProducts,
    setProductsToShow,
    setCompareProducts,
    showCompareProductsModal,
    toggleShowCompareProductsModal,
  } = useCompareProductsStore();

  const { searchValue: productSubCategoryId } = useURLSearchParams(
    QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.SUB_CATEGORY,
  );

  const productCardImage = useImageFallback(imageSrc);
  const categoryIdToUse = urlCategoryId ?? categoryId;
  const isFlashPromotionProduct = productType === PRODUCTTYPE.FLASH_PROMOTION;

  const link = isFlashPromotionProduct
    ? `/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}/${promotionId}/detail/${id}`
    : productSubCategoryId
      ? `/${ROUTES.PRODUCT.ALL_PRODUCTS}/${categoryIdToUse}/details/${id}?sub-category=${productSubCategoryId}`
      : `/${ROUTES.PRODUCT.ALL_PRODUCTS}/${categoryIdToUse}/details/${id}`;

  const handleProductAddToCompare = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    if (productData) {
      if (!showCompareProductsModal) {
        toggleShowCompareProductsModal();
      }
      if (compareProducts.length < 5) {
        const newProductData = [...compareProducts, productData];

        const compareProductsSet = new Set(
          newProductData.map((product) => product.id),
        );

        const uniqueProducts = Array.from(compareProductsSet).map(
          (productId) =>
            newProductData.find((product) => product.id === productId)!,
        );

        setCompareProducts(uniqueProducts);
        setProductsToShow(uniqueProducts.length);
      }
    }
  };

  return (
    <Link
      to={link}
      className='group relative flex h-full flex-col justify-between overflow-hidden rounded border border-gray-100 bg-white shadow-sm'
    >
      <figure
        className='relative w-full before:block before:w-full before:pt-[100%] before:content-[""]'
        data-cy='product-card'
      >
        <ImageComponent
          src={productCardImage ?? placholderImage}
          alt={imageAlt ?? undefined}
          classes='absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2'
        />
        <NewProduct isProductNew={isProductNew} />
      </figure>
      <div className='product-card-details'>
        <div
          className='flex flex-grow flex-col justify-between p-5'
          data-cy={`${productName} with price ${buyPrice}`}
        >
          <p>{productCode}</p>
          <p className='text-2xl font-semibold text-gray-800'>{productName}</p>
          <PriceDetail
            buyPrice={buyPrice}
            rrp={rrp}
            isDynamicPricing={isDynamicPricing}
            priceType={priceType}
          />
          <ProductDetailMoqAndRrp
            moq={!isFlashPromotionProduct ? moq : undefined}
            rrp={rrp}
          />
        </div>
        <div
          className={`absolute bottom-[-200px] grid h-auto w-full ${isFlashPromotionProduct ? 'grid-cols-1' : 'grid-cols-12'} items-center gap-2 border-y border-gray-100 bg-white px-5 py-4 shadow-max transition-bottom duration-500 group-hover:bottom-0`}
        >
          <div className='col-span-7'>
            <Button
              variant='primary'
              intent='solid'
              className='w-full'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (viewRangeToggler) {
                  navigate(
                    productSubCategoryId
                      ? `${location.pathname}?sub-category=${productSubCategoryId}&${QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_ID}=${id}`
                      : `${location.pathname}?${QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_ID}=${id}`,
                    { preventScrollReset: true },
                  );
                  viewRangeToggler();
                }
              }}
            >
              View Range
            </Button>
          </div>
          {!isFlashPromotionProduct && (
            <div className='col-span-5 ml-auto'>
              <Button
                variant='primary'
                intent='tertiary'
                className='flex w-auto gap-2 p-0 uppercase'
                onClick={handleProductAddToCompare}
              >
                <IconsComponent
                  icon='fill-compare'
                  fill='#6E2534'
                  size='md'
                />
                Compare
              </Button>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export function FlashPromotionProductCard({
  buyPrice,
  imageSrc,
  imageAlt,
  productCode,
  productName,
  rrp,
  isProductNew,
  moq,
  priceType,
}: Omit<TProductCardSchema, 'isDynamicPricing'>) {
  const productCardImage = useImageFallback(imageSrc);
  return (
    <div
      className='group relative flex h-full flex-col justify-between overflow-hidden rounded border border-gray-100 bg-white shadow-sm'
      data-cy='product-card'
    >
      <figure
        className='relative w-full before:block before:w-full before:pt-[100%] before:content-[""]'
        data-cy='product-card'
      >
        <ImageComponent
          src={productCardImage ?? placholderImage}
          alt={imageAlt ?? undefined}
          classes='absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2'
        />
        <NewProduct isProductNew={isProductNew} />
      </figure>
      <div
        className='flex flex-grow flex-col justify-between p-5'
        data-cy={`${productName} with price ${buyPrice}`}
      >
        <p>{productCode}</p>
        <p className='text-2xl font-semibold text-gray-800'>{productName}</p>
        <PriceDetail
          buyPrice={buyPrice}
          isDynamicPricing={false}
          rrp={rrp}
          priceType={priceType}
        />
        <ProductDetailMoqAndRrp
          moq={moq}
          rrp={rrp}
        />
      </div>
    </div>
  );
}

interface CompareProductProductCardProps extends TProductCardSchema {
  productData: TSingleProductDetailResponseSchema;
}

export function CompareProductProductCard({
  buyPrice,
  imageSrc,
  imageAlt,
  productCode,
  productName,
  rrp,
  isProductNew,
  priceType,
  productData,
  isDynamicPricing,
}: CompareProductProductCardProps) {
  const productCardImage = useImageFallback(imageSrc);
  const { compareProducts, setCompareProducts } = useCompareProductsStore();

  const handleCheckboxChange = () => {
    const isSelected = compareProducts.some(
      (product) => product.id === productData.id,
    );

    if (isSelected) {
      const updatedCompareProducts = compareProducts.filter(
        (product) => product.id !== productData.id,
      );
      setCompareProducts(updatedCompareProducts);
    } else if (compareProducts.length < 5) {
      setCompareProducts([...compareProducts, productData]);
    }
  };

  const isProductSelected = compareProducts.find(
    (product) => product.id === productData.id,
  );

  return (
    <figure
      className='relative flex h-full flex-col justify-between overflow-hidden rounded border border-gray-100 bg-white shadow-sm'
      data-cy='product-card'
    >
      <div className='group absolute left-4 top-4 z-10'>
        <Tooltip>
          <Tooltip.Trigger>
            <Checkbox
              variant='blue'
              id={productData.id!}
              checked={
                !!compareProducts.find(
                  (product) => product.id === productData.id,
                )
              }
              disabled={compareProducts.length === 5 && !isProductSelected}
              onChange={handleCheckboxChange}
            />
          </Tooltip.Trigger>

          {compareProducts.length === 5 && !isProductSelected && (
            <Tooltip.Content
              position={'right'}
              intent={'hover'}
            >
              <p className='text-sm font-medium text-gray-300'>
                Cannot add more than 5 <br />
                products to compare
              </p>
            </Tooltip.Content>
          )}
        </Tooltip>
      </div>

      <AsyncLoaderImage
        className='aspect-square h-64 w-56'
        style={{ objectFit: 'contain' }}
        src={productCardImage ?? placholderImage}
        alt={imageAlt ?? undefined}
      />
      <NewProduct isProductNew={isProductNew} />
      <figcaption
        className='flex flex-grow flex-col justify-between p-5'
        data-cy={`${productName} with price ${buyPrice}`}
      >
        <p>{productCode}</p>
        <p className='text-2xl font-semibold text-gray-800'>{productName}</p>
        <PriceDetail
          buyPrice={buyPrice}
          rrp={rrp}
          isDynamicPricing={isDynamicPricing}
          priceType={priceType}
        />
      </figcaption>
    </figure>
  );
}

export function ProductComparisonRecommendedProductCard({
  buyPrice,
  imageSrc,
  imageAlt,
  productCode,
  productName,
  rrp,
  isProductNew,
  isDynamicPricing,
  moq,
  priceType,
  productType = PRODUCTTYPE.PRODUCT,
  productData,
}: ProductCardPropsInterface) {
  const { setCompareProducts, setProductsToShow, compareProducts } =
    useCompareProductsStore();

  const productCardImage = useImageFallback(imageSrc);

  const isFlashPromotionProduct = productType === PRODUCTTYPE.FLASH_PROMOTION;

  const isAddToCompareButtonDisaled = compareProducts.length === 5;

  const handleProductAddToCompare = () => {
    if (productData) {
      const newArrayOfProduct = [...compareProducts, productData];

      const uniqueProducts = Array.from(
        new Map(newArrayOfProduct.map((item) => [item.id, item])).values(),
      );

      setProductsToShow(uniqueProducts.length);
      setCompareProducts(uniqueProducts);
      scrollToTop();
    }
  };

  return (
    <div className='group relative flex h-full flex-col justify-between overflow-hidden rounded border border-gray-100 bg-white shadow-sm'>
      <figure
        className='relative w-full before:block before:w-full before:pt-[100%] before:content-[""]'
        data-cy='product-card'
      >
        <ImageComponent
          src={productCardImage ?? placholderImage}
          alt={imageAlt ?? undefined}
          classes='absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2'
        />
        <NewProduct isProductNew={isProductNew} />
      </figure>
      <div className='product-card-details'>
        <div
          className='flex flex-grow flex-col justify-between p-5'
          data-cy={`${productName} with price ${buyPrice}`}
        >
          <p>{productCode}</p>
          <p className='text-2xl font-semibold text-gray-800'>{productName}</p>
          <PriceDetail
            buyPrice={buyPrice}
            rrp={rrp}
            isDynamicPricing={isDynamicPricing}
            priceType={priceType}
          />
          <ProductDetailMoqAndRrp
            moq={!isFlashPromotionProduct ? moq : undefined}
            rrp={rrp}
          />
        </div>
        <Button
          variant='primary'
          intent='tertiary'
          className='absolute bottom-[-200px] flex h-auto w-full items-center gap-2 border-y border-gray-100 bg-white p-3 px-5 py-4 shadow-max transition-bottom duration-500 group-hover:bottom-0'
          onClick={handleProductAddToCompare}
          disabled={isAddToCompareButtonDisaled}
        >
          <Tooltip>
            <Tooltip.Trigger>
              <div className='flex items-center gap-6 font-semibold'>
                <p>
                  <IconsComponent
                    icon='fill-compare'
                    fill='#6E2534'
                    size='md'
                  />
                </p>
                ADD TO Compare
              </div>
            </Tooltip.Trigger>

            {isAddToCompareButtonDisaled && (
              <Tooltip.Content
                position={'top'}
                intent={'hover'}
              >
                <p className='text-sm font-medium text-gray-300'>
                  Cannot add more than 5 <br />
                  products to compare
                </p>
              </Tooltip.Content>
            )}
          </Tooltip>
        </Button>
      </div>
    </div>
  );
}
