import { Button, Modal } from '@/components';
import {
  scrollToTop,
  useAlert,
  useBulkSend2faResetLink,
} from '@/components/hooks';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { handleApiError } from '@/utils/helper';
import { useQueryClient } from '@tanstack/react-query';

export interface ModalProps {
  selectedRowId: string[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleResetRowSelection: () => void;
}

/**
 * @description Send2FAInstructionModal component
 */
export function Send2FAInstructionModal({
  setShowModal,
  showModal,
  selectedRowId,
  handleResetRowSelection,
}: ModalProps) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useBulkSend2faResetLink();

  const handleSend2faReset = () => {
    const bulkSendPasswordResetLink = {
      users: selectedRowId,
    };
    mutate(bulkSendPasswordResetLink, {
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: [TANSTACK_QUERY_KEY.USER] });
        handleResetRowSelection();
        showAlert(response.message, 'success');
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        scrollToTop();
        setShowModal(false);
      },
    });
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <Modal.Content className='lg:min-w-[600px]'>
        <Modal.Title>
          <h6 className=''>Send 2FA Instruction</h6>
        </Modal.Title>
        <Modal.Body>
          <div className='space-y-4'>
            <p className='text-base text-gray-800'>
              You are about to reset 2FA for the{' '}
              <span className='font-bold'>{selectedRowId.length}</span> users.
            </p>
            <div>
              <p className='text-base font-semibold text-gray-800'>This will</p>
              <ul className='ml-3 list-inside list-disc text-base font-normal text-gray-800'>
                <li className='font-normal'>Logout all selected users.</li>
                <li className='font-normal'>
                  Users wont be granted access until they have logged in again.
                </li>
              </ul>
            </div>
            <p className='text-base font-bold text-gray-800'>
              Are you sure you want to reset 2FA?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            disabled={isPending}
            onClick={handleSend2faReset}
          >
            Send 2FA Instruction
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
