import { z } from 'zod';

export const DeleteMemberOnTeamRequestSchema = z.object({
  userIds: z.array(z.string()).optional(),
});
export const DeleteMemberOnTeamResponseSchema = z.object({
  message: z.string(),
});

export type TDeleteMemberOnTeam = z.infer<
  typeof DeleteMemberOnTeamRequestSchema
>;
