import React, { useCallback, useMemo, useState } from 'react';

import {
  productBuyPrice,
  productPriceType,
  isProductDynamicPricing,
} from '@/utils/helper/product/product-price';
import { Button } from '../button';
import { Select } from '../select';
import { Modal } from '../modal/Modal';
import { SearchInput } from '../input-field';
import { MediaType } from '@/utils/constants';
import { EmptyMessage } from '../empty-message';
import { CompareProductProductCard } from '../card/ProductCard';
import useCompareProductsStore from '@/store/compare-products.store';
import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { debounce, fisherYatesShuffle, isEmpty } from '@/utils/helper';
import { ProductCardSkeletons } from '../skeleton/ProductCardSkeletons';
import { useIsDeviceMobileOrLarger } from '@/components/hooks/utils/useMediaQuery';
import { useGetAllCategories } from '@/components/hooks/api/products/useGetAllCategories';
import { useProductComparisonList } from '@/components/hooks/api/products/useProductComparisonList';
interface AddProductModalProps {
  showAddProductModal: boolean;
  setShowAddProductModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompareProductsModalProductList = () => {
  const isMobileOrLarger: boolean = useIsDeviceMobileOrLarger();

  const { data: allCategories } = useGetAllCategories();

  const categoryOptions = allCategories?.data.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const [searchProduct, setSearchProduct] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const handleCategoryChange = useCallback((newValue: unknown) => {
    const selectedCategory = newValue as { value: string; label: string };
    setSelectedCategoryId(selectedCategory.value);
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchProduct(query);
      }, 300),
    [],
  );

  const queryParams = {
    search: searchProduct,
    categoryId: selectedCategoryId,
  };

  const {
    data: allProductsData,
    isLoading: isAllProductDataLoading,
    isError: allProductsDataError,
  } = useProductComparisonList(queryParams);

  const randomizedProducts = React.useMemo(() => {
    return fisherYatesShuffle([...(allProductsData?.data ?? [])]);
  }, [allProductsData]);

  return (
    <div className='grid gap-4'>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-8'>
          <SearchInput
            onChange={(e) => debouncedSearch(e.target.value)}
            placeholder='Search Product'
            className='h-12 w-full border border-gray-200 placeholder:text-base placeholder:font-normal placeholder:text-gray-400'
          />
        </div>
        <div className='col-span-4'>
          <div>
            <Select
              closeMenuOnSelect
              menuPosition='fixed'
              className='h-12 w-full'
              options={categoryOptions}
              onChange={handleCategoryChange}
              placeholder='Select categories'
              classNames={{ container: () => 'z-50' }}
              closeMenuOnScroll={() => true}
            />
          </div>
        </div>
      </div>
      <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
        {allProductsDataError ? (
          <div className='h-96 w-96'>
            <EmptyMessage message='Failed to fetch products' />
          </div>
        ) : isAllProductDataLoading ? (
          <div className='flex h-96 w-96 gap-4'>
            <ProductCardSkeletons length={isMobileOrLarger ? 3 : 1} />
          </div>
        ) : !isEmpty(allProductsData?.data) ? (
          randomizedProducts.slice(0, 9).map((product) => {
            const imageSrc = product.medias
              ? (product.medias.find(
                  (media) => media.type === MediaType.THUMBNAIL,
                )?.url ?? '')
              : '';
            const imageAlt = product.medias
              ? (product.medias.find(
                  (media) => media.type === MediaType.THUMBNAIL,
                )?.altText ?? '')
              : '';

            return (
              <CompareProductProductCard
                key={`product-${product.id}`}
                buyPrice={productBuyPrice(product.prices)}
                imageSrc={imageSrc}
                imageAlt={imageAlt}
                productCode={product.skuCode ?? ''}
                productName={product.name ?? ''}
                rrp={Number(product.price)}
                isProductNew={product.isNew ?? false}
                moq={undefined}
                id={product.id ?? ''}
                productData={product}
                isDynamicPricing={isProductDynamicPricing(product.prices)}
                priceType={productPriceType(product.prices) as PRICETYPE}
              />
            );
          })
        ) : (
          <div className='h-96 w-96'>
            <EmptyMessage message='Products not found' />
          </div>
        )}
      </div>
    </div>
  );
};

export const AddProductModal = ({
  showAddProductModal,
  setShowAddProductModal,
}: AddProductModalProps) => {
  const { compareProducts, setProductsToShow } = useCompareProductsStore();

  const handleAddProductsToCompareStore = () => {
    setProductsToShow(compareProducts.length);
    setShowAddProductModal(false);
  };

  return (
    <Modal
      showModal={showAddProductModal}
      setShowModal={setShowAddProductModal}
    >
      <Modal.Content className='md:max-w-[900px]'>
        <Modal.Title className='grid gap-2'>
          <h6>Product</h6>
          <p className='text-sm'>
            <span className='font-medium text-gray-800'>
              {compareProducts.length}&nbsp;/&nbsp;
            </span>
            5 Items Selected
          </p>
        </Modal.Title>
        <Modal.Body className='max-h-[540px] overflow-y-auto'>
          <CompareProductsModalProductList />
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton
            data-cy='modal-close-button'
            onClick={() => setShowAddProductModal(false)}
          >
            Cancel
          </Modal.CloseButton>
          <Button
            onClick={handleAddProductsToCompareStore}
            disabled={compareProducts.length === 0}
          >
            Add Selected
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
