import React from 'react';
import { IconProps } from '../iconProps';

export const IconSmileyHappy: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2363_21)'>
        <path
          d='M12 24C5.3835 24 0 18.6165 0 12C0 5.3835 5.3835 0 12 0C18.6165 0 24 5.3835 24 12C24 18.6165 18.6165 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM12 21C5.32725 21 5.25 15.06 5.25 15C5.25 14.5852 5.586 14.25 6 14.25H18C18.4147 14.25 18.75 14.5852 18.75 15C18.75 15.06 18.6728 21 12 21ZM6.8295 15.75C7.07325 17.0513 8.0565 19.5 12 19.5C15.9233 19.5 16.9155 17.0333 17.166 15.75H6.8295ZM18 12C17.5898 12 17.2568 11.6707 17.25 11.262C17.2395 11.031 17.1082 10.5 16.125 10.5C15.054 10.5 15 11.1248 15 11.25C15 11.664 14.6648 12 14.25 12C13.8352 12 13.5 11.664 13.5 11.25C13.5 10.3455 14.199 9 16.125 9C18.051 9 18.75 10.3455 18.75 11.25C18.75 11.664 18.4147 12 18 12ZM9.75 12C9.33975 12 9.00675 11.6707 9 11.262C8.9895 11.031 8.85825 10.5 7.875 10.5C6.804 10.5 6.75 11.1248 6.75 11.25C6.75 11.664 6.414 12 6 12C5.586 12 5.25 11.664 5.25 11.25C5.25 10.3455 5.949 9 7.875 9C9.801 9 10.5 10.3455 10.5 11.25C10.5 11.664 10.164 12 9.75 12Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2363_21'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
