import { useLocation } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';

import {
  Header,
  RestricedRoutesHeader,
  Sidebar,
} from '@/components/ui/section';
import { cn } from '@/utils/cn';
import { Button } from '../ui/button';
import { useIsDeviceMediumOrLarger, useIsDeviceTabletOrLarger } from '../hooks';
import { LoadingContent } from '../ui/loading/Loading';
import IconsComponent from '../ui/icons/IconsComponent';
import { useAlertStore, useSidebarStore } from '@/store';
import { CompareProducts } from '../ui/compare-products';
import { ROUTES } from '@/utils/constants/routes.constant';
import useCompareProductsStore from '@/store/compare-products.store';

const useAlertDismissal = () => {
  const location = useLocation();
  const { setIsAlertDismissed, setIsAlertVisible } = useAlertStore();

  useEffect(() => {
    setIsAlertDismissed(true); // Dismiss alert on route change
    setIsAlertVisible(false);
  }, [location, setIsAlertDismissed]);
};

// render when user is authenticated / logged in
export const PrivateRouteLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    compareProducts,
    toggleShowCompareProductsModal,
    showCompareProductsModal,
  } = useCompareProductsStore();
  const { isSidebarIconOnlyView, isSidebarVisible } = useSidebarStore();
  const { isAlertVisible, isAlertDismissed } = useAlertStore();

  const isTabletOrLarger: boolean = useIsDeviceTabletOrLarger();
  const isDeviceMediumOrLarger = useIsDeviceMediumOrLarger();

  useAlertDismissal();

  const isCompareButtonVisible =
    isDeviceMediumOrLarger &&
    !showCompareProductsModal &&
    compareProducts.length > 0;

  return (
    <main
      className={cn(
        'clear-both flex flex-wrap bg-background',
        isSidebarVisible ? 'min-h-screen' : '',
      )}
    >
      <Sidebar />
      <div
        className={cn(
          'main-content ml-auto transition-width duration-200 ease-in-out',
          {
            'w-[calc(100%_-_288px)]': !isSidebarIconOnlyView,
            'w-[calc(100%_-_96px)]': isSidebarIconOnlyView,
            'w-full': !isTabletOrLarger,
          },
        )}
      >
        <Header />
        <div className='container m-0 mx-auto pb-[94px] pt-8'>
          <Suspense fallback={<LoadingContent />}>
            <div
              className={
                !isAlertDismissed && isAlertVisible
                  ? '-translate-y-[86px] translate-x-[0px] transition-transform delay-5000 duration-1000 ease-in-out'
                  : ''
              }
            >
              {children}
            </div>
            {!location.pathname.includes(ROUTES.PRODUCT.COMPARISON) && (
              <>
                <div
                  className={cn(
                    'fixed bottom-0 left-1/2 z-10 -translate-x-1/2 transform pl-4 transition-bottom duration-300 ease-in-out',
                  )}
                >
                  {isCompareButtonVisible && (
                    <Button
                      className={cn('bg-gray-800')}
                      intent='solid'
                      variant='gray'
                      size='xs'
                      onClick={toggleShowCompareProductsModal}
                    >
                      <p className='flex items-center gap-4 text-white'>
                        <span className='text-sm font-medium'>
                          Compare Products ({compareProducts.length})
                        </span>
                        <IconsComponent
                          icon={'reg-chevron-up'}
                          fill='white'
                        />
                      </p>
                    </Button>
                  )}
                </div>
                <CompareProducts />
              </>
            )}
          </Suspense>
        </div>
      </div>
    </main>
  );
};

// render when user is not authenticated / logged in
export const RestrictedRouteLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAlertVisible, isAlertDismissed } = useAlertStore();
  // Dismiss alert immediately when the route changes
  useAlertDismissal(); // Use the custom hook to handle alert dismissal

  return (
    <main>
      <header>
        <RestricedRoutesHeader />
      </header>
      <section className='container'>
        <Suspense fallback={<LoadingContent />}>
          <div
            className={
              !isAlertDismissed && isAlertVisible
                ? '-translate-y-[86px] translate-x-[0px] transition-transform delay-5000 duration-1000 ease-in-out'
                : ''
            }
          >
            {children}
          </div>
        </Suspense>
      </section>
    </main>
  );
};
