/**
 * @description Tanstack Query key constant
 */

const PRODUCTS = {
  ALL_PRODUCTS_CATEGORY: 'all-product-categories',
  PRODUCT_CATEGORY_BY_ID: 'product-categories-by-id',
  ALL_PRODUCTS: 'all-products',
  PRODUCT_BY_ID: 'product-by-id',
  RECENTLY_VIEWED_PRODUCTS: 'recently-viewed-products',
  EXPORT: 'export-product-list',
};

export const TANSTACK_QUERY_KEY = {
  USER: 'user',
  USER_DETAIL: 'user-detail',
  TEAM: 'team',
  TEAM_DETAIL: 'team-detail',
  USER_2FA: 'user-2fa',
  USER_INVITE: 'user-invite',
  TEAM_OPTIONS: 'team-options',
  PRODUCTS,
  COMPANY_SYSTEM_ADDRESS: 'company-system-address',
  PROMOTION: 'promotion',
  PROMOTION_DETAIL: 'promotion-detail',
  COMPANY_DETAILS: 'company-details',
  ORDER_DETAILS: 'order-details',
  ORDER: 'order',
  ORDER_ANALYTICS: 'order-analytics',
  ORDER_EXPORT: 'order-export',
  PENDING_ORDERS: 'pending-orders',
  PAYMENT_CONFIRM: 'payment-confirm',
  OVERVIEW: {
    RECENT_ORDER: 'recent-order',
    FREQUENTLY_PURCCHASED_PRODUCT: 'frequently-purchased-product',
    RECENTLY_ADDED_USER: 'recently-added-user',
    RECENTLY_VIEWED_PRODUCTS: 'recently-viewed-products',
    SPENDING_SUMMARY: 'spending-summary',
    SPENDING_CATEGORY: 'spending-category',
    PURCHASING_METHOD: 'purchasing-method',
    TOTAL_SPENDING: 'total-spending',
    TOTAL_ORDER_AMOUNT: 'total-order-amount',
  },
};
