import { useParams } from 'react-router-dom';

import {
  Reset2FA,
  ActionBar,
  AssignTeam,
  ChangeStatus,
  ResetPassword,
  UserDetailsForm,
  RolesAndPermission,
  ResendInvitationLink,
} from '@/pages/users-and-teams/users/user-details';
import { Alert } from '@/components';
import { Status, TPersonalDetails } from '@/schemas';
import { useAlert, useGetUserDetail } from '@/components/hooks';
import { LoadingContent } from '@/components/ui/loading/Loading';
import { ErrorFound } from '@/components/ui/no-result/Error';

export default function UserDetailsPage() {
  const { id } = useParams();

  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert();

  const { data: user, isError, isLoading } = useGetUserDetail(id!);

  if (isLoading) return <LoadingContent />;

  if (isError) return <ErrorFound />;

  const initialValues: TPersonalDetails = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email,
    phone: `${user?.countryCode}-${user?.phone}`,
    designation: user?.designation ?? '',
    profileImage: user?.avatar ?? '',
  };

  const userId = user?.id;
  const userRole = user?.role ?? '';

  const teams = user?.teams.map((team) => ({
    name: team.name,
    id: team.id,
  }));

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mb-4'
          title={alertMessage}
        />
      )}
      <ActionBar
        firstName={user?.firstName ?? ''}
        lastName={user?.lastName ?? ''}
      />
      <hr />
      <div className='py-10 2xl:px-60'>
        <UserDetailsForm
          isInvited={user?.isInvited}
          initialValues={initialValues}
          accountStatus={user?.accountStatus}
          id={userId}
        />
        <hr className='my-8' />
        <RolesAndPermission
          id={userId!}
          isInvited={user?.isInvited}
          userRole={userRole}
        />
        <hr className='my-8' />
        <AssignTeam
          teams={teams}
          isInvited={user?.isInvited}
          userId={userId!}
        />
        <hr className='my-8' />
        <ChangeStatus
          status={user?.accountStatus}
          isInvited={user?.isInvited}
          role={user?.role}
          id={userId!}
        />
        <hr className='my-8' />
        <Reset2FA
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          id={userId!}
          twoFactorSetAt={user?.twoFactorSetAt}
          isInvited={user?.isInvited}
        />
        <hr className='my-8' />
        <ResetPassword
          email={user?.email}
          isInvited={user?.isInvited}
          isActive={user?.accountStatus === Status.ACTIVE}
        />

        {/* resend invitation link to invite-pending user only */}
        {user?.accountStatus === Status.INVITE_PENDING && (
          <>
            <hr className='my-8' />
            <ResendInvitationLink
              email={user.email}
              userId={userId!}
              isInvited={user.isInvited}
            />
          </>
        )}
      </div>
    </>
  );
}
