import { useContext, useState } from 'react';
import { Form } from 'react-router-dom';

import { useFilterSubmit } from '@/components/hooks';
import { RoleOptions, StatusOptions } from '@/data/Users.data';
import { QUERY_CONSTANTS, HttpMethods } from '@/utils/constants';
import { useGetAllTeamsList } from '@/components/hooks/api/useTeams';
import {
  Button,
  Dropdown,
  DropDownLoadingSkeleton,
  FilterCheckboxOption,
  IconsComponent,
  Modal,
} from '@/components';
import { limitNameWithLength } from '@/utils/helper/limitNameWithLength';
import { FilterContext } from '@/components/context/FilterContext';

function TeamNameFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { setShowModal } = useContext(FilterContext);
  const {
    handleSubmit,
    selectedOptions,
    isButtonDisabled,
    setSelectedOptions,
  } = useFilterSubmit(QUERY_CONSTANTS.TEAMS);

  const { query } = useGetAllTeamsList();

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
  } = query;

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Team Name
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <Form
          method={HttpMethods.GET}
          onSubmit={(event) => {
            handleSubmit(event);
            setIsDropDownVisible(false);
            setShowModal(false);
          }}
        >
          <p className='px-5 pt-4 text-base font-bold text-gray-800'>
            Team Name
          </p>
          {isLoading ? (
            <DropDownLoadingSkeleton />
          ) : isError ? (
            <div className='px-2 py-4 text-center font-medium text-red-500'>
              Error! Please try again
            </div>
          ) : (
            <div
              className='max-h-36 overflow-y-auto p-2'
              onScroll={handleScroll}
            >
              {data ? (
                [
                  ...new Map(
                    data.pages.flat().map((team) => [team.name, team]),
                  ).values(),
                ].map((team) => (
                  <FilterCheckboxOption
                    key={team._id.toString()}
                    name={QUERY_CONSTANTS.TEAMS}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    value={team.name}
                    label={limitNameWithLength(team.name, 25)}
                  />
                ))
              ) : (
                <p>No teams available</p>
              )}
              {isFetchingNextPage && <DropDownLoadingSkeleton />}
            </div>
          )}
          <hr />
          <div className='p-4'>
            <Button
              size='xs'
              type='submit'
              className='w-full'
              disabled={isButtonDisabled}
            >
              Apply Filter
            </Button>
          </div>
        </Form>
      </Dropdown.Content>
    </Dropdown>
  );
}

function StatusFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { setShowModal } = useContext(FilterContext);

  const {
    handleSubmit,
    selectedOptions,
    isButtonDisabled,
    setSelectedOptions,
  } = useFilterSubmit(QUERY_CONSTANTS.STATUS);

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Status
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <Form
          method={HttpMethods.GET}
          onSubmit={(event) => {
            handleSubmit(event);
            setIsDropDownVisible(false);
            setShowModal(false);
          }}
        >
          <p className='px-5 pt-4 text-base font-bold text-gray-800'>Status</p>
          <div className='p-2'>
            {StatusOptions.map((status) => (
              <FilterCheckboxOption
                name={QUERY_CONSTANTS.STATUS}
                key={status.value}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                {...status}
              />
            ))}
          </div>
          <hr />
          <div className='p-4'>
            <Button
              size='xs'
              type='submit'
              className='w-full'
              disabled={isButtonDisabled}
            >
              Apply Filter
            </Button>
          </div>
        </Form>
      </Dropdown.Content>
    </Dropdown>
  );
}

function RolesFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { setShowModal } = useContext(FilterContext);
  const {
    handleSubmit,
    selectedOptions,
    isButtonDisabled,
    setSelectedOptions,
  } = useFilterSubmit(QUERY_CONSTANTS.ROLES);

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Roles
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <Form
          method={HttpMethods.GET}
          onSubmit={(event) => {
            handleSubmit(event);
            setIsDropDownVisible(false);
            setShowModal(false);
          }}
        >
          <p className='px-5 pt-4 text-base font-bold text-gray-800'>Roles</p>
          <div className='p-2'>
            {RoleOptions.map((role) => (
              <FilterCheckboxOption
                key={role.value}
                name={QUERY_CONSTANTS.ROLES}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                {...role}
              />
            ))}
          </div>
          <hr />
          <div className='p-4'>
            <Button
              size='xs'
              type='submit'
              className='w-full'
              disabled={isButtonDisabled}
            >
              Apply Filter
            </Button>
          </div>
        </Form>
      </Dropdown.Content>
    </Dropdown>
  );
}

export function FilterForm() {
  return (
    <>
      <TeamNameFilter />
      <StatusFilter />
      <RolesFilter />
    </>
  );
}

export function MobileResponsiveFilterForm() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <Modal.Toggle
        size='xs'
        variant='gray'
        intent='tertiary'
        className='px-3'
      >
        <IconsComponent
          fill='gray'
          icon='reg-filter'
          size='md'
        />
        Filter By
      </Modal.Toggle>
      <Modal.Content className='h-[calc(100vh_-_4rem)]'>
        <Modal.Title>
          <h6>Filter By</h6>
        </Modal.Title>
        <Modal.Body>
          <div className='flex flex-col gap-4'>
            <FilterContext.Provider value={{ setShowModal }}>
              <FilterForm />
            </FilterContext.Provider>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
