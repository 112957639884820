import React from 'react';
import { IconProps } from '../iconProps';

export const IconSmileyMeh: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2363_22)'>
        <path
          d='M12 24C5.3835 24 0 18.6165 0 12C0 5.3835 5.3835 0 12 0C18.6165 0 24 5.3835 24 12C24 18.6165 18.6165 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM17.25 17.25H6.75C6.336 17.25 6 16.9147 6 16.5C6 16.0853 6.336 15.75 6.75 15.75H17.25C17.6647 15.75 18 16.0853 18 16.5C18 16.9147 17.6647 17.25 17.25 17.25ZM15.75 13.5C15.3352 13.5 15 13.1648 15 12.75V9C15 8.586 15.3352 8.25 15.75 8.25C16.1647 8.25 16.5 8.586 16.5 9V12.75C16.5 13.1648 16.1647 13.5 15.75 13.5ZM8.25 13.5C7.836 13.5 7.5 13.1648 7.5 12.75V9C7.5 8.586 7.836 8.25 8.25 8.25C8.664 8.25 9 8.586 9 9V12.75C9 13.1648 8.664 13.5 8.25 13.5Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2363_22'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
