import { z } from 'zod';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { PaymentAPI } from '@/services/api/payment';
import { PaymentMethod } from '@/utils/constants/payment.constant';
import { API_ENDPOINT, TANSTACK_QUERY_KEY } from '@/utils/constants';
import { cartAddressUpdateResponseSchema } from '@/schemas/createShippingAddress.schema';

/**
 * @description hook to make payment via stripe and credit card
 */
export function usePayment() {
  return useMutation({
    mutationFn: ({
      cartId,
      paymentType,
      formData,
    }: {
      cartId: string;
      paymentType: keyof typeof PaymentMethod;
      formData: {
        purchaseOrderNumber: string;
        expectedDeliveryDate: string;
      };
    }) => {
      const path = `${API_ENDPOINT.ORDERS}/${cartId}/${
        paymentType === PaymentMethod.DIRECT
          ? API_ENDPOINT.PAYMENT.DIRECT
          : API_ENDPOINT.PAYMENT.CREDIT
      }`;

      return PaymentAPI.payment(path)(formData);
    },
  });
}

export function usePaymentConfirm({
  cartId,
  sessionId,
}: {
  cartId: string;
  sessionId: string;
}) {
  const path = `${API_ENDPOINT.ORDERS}/${cartId}/${API_ENDPOINT.PAYMENT.CONFIRM}`;

  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.PAYMENT_CONFIRM],
    queryFn: () => PaymentAPI.paymentConfirm(path)({ sessionId }),
    enabled: !!cartId && !!sessionId,
  });
}

export function useUpdateCartAddressPrimary() {
  return useMutation<
    z.infer<typeof cartAddressUpdateResponseSchema>, // Success response type
    AxiosError, // Error response type
    {
      cartId: string;
      data: {
        billingAddressId: string;
        shippingAddressId: string;
      };
    }
  >({
    mutationFn: ({ cartId, data }) =>
      PaymentAPI.cartAddressPrimary({ cartId })(data),
  });
}
