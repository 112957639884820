import { useSearchParams } from 'react-router-dom';

import {
  useFilterSubmit,
  useGetAllProducts,
  useQueryParamSearch,
} from '@/components/hooks';
import { isEmpty } from '@/utils/helper';
import { useProductFilterStore } from '@/store';
import { QUERY_CONSTANTS } from '@/utils/constants';
import { useIsProductFiltersSelected } from './index';
import { IconsComponent, InputSkeletons, Label } from '@/components';
import { ProductFilterStateKeys } from '@/store/product-filter.store';
import { STOCK_DIVISONS } from '@/utils/constants/product-stock.constants';
import { formatCompanyPrice } from '@/utils/helper/country-locale';

export const SelectedFilterItems = ({
  filterArray,
  filterKey,
}: {
  filterArray: string[];
  filterKey: keyof typeof QUERY_CONSTANTS.PRODUCT_FILTERS;
}) => {
  const {
    shoeSizeSelected,
    setProductFilter,
    shoeColorSelected,
    shoeTechnologySelected,
    availableColourOptions,
    shoeIndustryForSelected,
    shoeAvailableStockSelected,
  } = useProductFilterStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading: isAllProductDataLoading } = useGetAllProducts();

  const deleteSearchParam = (
    paramKey: keyof typeof QUERY_CONSTANTS.PRODUCT_FILTERS,
    productKey: string,
  ) => {
    searchParams.delete(QUERY_CONSTANTS.PRODUCT_FILTERS[paramKey], productKey);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const getFilterLabel = (value: string) => {
    switch (filterKey) {
      case 'SHOE_PRICE_RANGE':
        return `Price Range (${formatCompanyPrice(Number(value.split('_')[0]))} - 
        ${formatCompanyPrice(Number(value.split('_')[1]))})`;
      case 'SHOE_SIZE':
        return `Size (UK) - ${value}`;
      case 'SHOE_COLOR': {
        const colorObject = availableColourOptions.find(
          (color) => color.hex === value,
        );

        return colorObject ? (
          <Label className='flex items-center gap-1'>
            <div
              style={{
                background: `#${colorObject.hex}`,
              }}
              className='h-4 w-4 rounded-full'
            />
            <div className='font-semibold text-gray-900'>
              {colorObject.name}
            </div>
          </Label>
        ) : null;
      }
      case 'SHOE_AVAILABLE_STOCKS':
        return `Available Stocks (${STOCK_DIVISONS.find((stockOption) => Number(stockOption.threshold) === Number(value))?.options})`;
      case 'SHOE_TECHNOLOGY':
      case 'SHOE_INDUSTRY_FOR':
        return value;
    }
  };

  const handleDeleteCategoryKey = (productKey: string) => {
    switch (filterKey) {
      case 'SHOE_PRICE_RANGE':
        searchParams.delete(QUERY_CONSTANTS.PRICE_RANGE.FROM);
        searchParams.delete(QUERY_CONSTANTS.PRICE_RANGE.TO);
        setSearchParams(searchParams, { preventScrollReset: true });
        break;
      case 'SHOE_SIZE':
        deleteSearchParam('SHOE_SIZE', productKey);
        setProductFilter(
          ProductFilterStateKeys.ShoeSizeOptionsSelected,
          shoeSizeSelected.filter((size) => size !== productKey),
        );
        break;
      case 'SHOE_COLOR':
        deleteSearchParam('SHOE_COLOR', productKey);
        setProductFilter(
          ProductFilterStateKeys.ShoeColorOptionsSelected,
          shoeColorSelected.filter((color) => color !== productKey),
        );
        break;
      case 'SHOE_AVAILABLE_STOCKS':
        deleteSearchParam('SHOE_AVAILABLE_STOCKS', productKey);
        setProductFilter(
          ProductFilterStateKeys.ShoeAvailableStockOptionsSelected,
          shoeAvailableStockSelected.filter((color) => color !== productKey),
        );
        break;
      case 'SHOE_INDUSTRY_FOR':
        deleteSearchParam('SHOE_INDUSTRY_FOR', productKey);
        setProductFilter(
          ProductFilterStateKeys.ShoeIndustryForOptionsSelected,
          shoeIndustryForSelected.filter((color) => color !== productKey),
        );
        break;
      case 'SHOE_TECHNOLOGY':
        deleteSearchParam('SHOE_TECHNOLOGY', productKey);
        setProductFilter(
          ProductFilterStateKeys.ShoeTechnologyOptionsSelected,
          shoeTechnologySelected.filter((color) => color !== productKey),
        );
        break;
    }
  };

  return (
    <>
      {isAllProductDataLoading ? (
        <InputSkeletons height={12} />
      ) : (
        !isEmpty(filterArray) &&
        filterArray.map((productKey, productKeyIndex) =>
          !isEmpty(getFilterLabel(productKey)) ? (
            <div
              className='flex w-[fit-content] animate-fade items-center gap-2 rounded-full border border-gray-300 px-5 py-2'
              key={productKeyIndex}
            >
              <div className='font-semibold text-gray-900'>
                {getFilterLabel(productKey)}
              </div>
              <button onClick={() => handleDeleteCategoryKey(productKey)}>
                <IconsComponent
                  icon='reg-close'
                  fill='#111827'
                  size='sm'
                />
              </button>
            </div>
          ) : null,
        )
      )}
    </>
  );
};

export const SelectedFilterItemsByCategory = () => {
  const isProductFiltersSelected = useIsProductFiltersSelected();
  const queryFrom = useQueryParamSearch(QUERY_CONSTANTS.PRICE_RANGE.FROM);
  const queryTo = useQueryParamSearch(QUERY_CONSTANTS.PRICE_RANGE.TO);
  const priceRange = `${queryFrom}_${queryTo}`;

  const filterData = [
    {
      filterKey: 'SHOE_PRICE_RANGE',
      filterArray: !isEmpty(queryFrom) ? [priceRange] : [],
    },
    {
      filterKey: 'SHOE_SIZE',
      filterArray: useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE)
        .selectedOptions,
    },
    {
      filterKey: 'SHOE_COLOR',
      filterArray: useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR)
        .selectedOptions,
    },
    {
      filterKey: 'SHOE_AVAILABLE_STOCKS',
      filterArray: useFilterSubmit(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_AVAILABLE_STOCKS,
      ).selectedOptions,
    },
    {
      filterKey: 'SHOE_INDUSTRY_FOR',
      filterArray: useFilterSubmit(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_INDUSTRY_FOR,
      ).selectedOptions,
    },
    {
      filterKey: 'SHOE_TECHNOLOGY',
      filterArray: useFilterSubmit(
        QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_TECHNOLOGY,
      ).selectedOptions,
    },
  ];

  return isProductFiltersSelected ? (
    <div className='pb-3 pt-6'>
      <div className='flex flex-wrap gap-2'>
        {filterData.map(
          ({ filterKey, filterArray }) =>
            filterArray.length > 0 && (
              <SelectedFilterItems
                key={filterKey as keyof typeof QUERY_CONSTANTS.PRODUCT_FILTERS}
                filterArray={filterArray}
                filterKey={
                  filterKey as keyof typeof QUERY_CONSTANTS.PRODUCT_FILTERS
                }
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};
