import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { FC, useEffect } from 'react';

import { ROUTES } from '@/utils/constants';
import { PrivateRouteLayout, RestrictedRouteLayout } from '@/components';
import { useUserStore } from '@/store/user-store-new';

const TWOFA_VALIDATE_RESET_PATH = /users\/[0-9a-fA-F-]{36}\/2fa-reset/;

// Define an enum for route types
export enum RouteType {
  Private = 'private',
  Restricted = 'restricted',
}

// Define a single interface for the route guard props
export interface RouteGuardProps {
  type: RouteType;
}

export const RouteGuard: FC<RouteGuardProps> = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { auth, removeStorage } = useUserStore();
  const isUserLoggedIn = auth?.valid;

  useEffect(() => {
    const handleNavigation = () => {
      if (type === RouteType.Private) {
        if (!isUserLoggedIn) {
          navigate(`/${ROUTES.LOGIN}${location.search}`, { replace: true });
        } else if (location.pathname === '/') {
          navigate(`/${ROUTES.OVERVIEW}`, { replace: true });
        }
      } else {
        // type === 'restricted'
        const isRegisterRoute =
          location.pathname.split('/')[1] === ROUTES.REGISTER.REGISTER ||
          location.pathname.split('/')[1] === 'companies' ||
          location.pathname.split('/')[1] === 'users';

        const isForgotPasswordRoute = location.pathname.startsWith(
          `/${ROUTES.FORGOT_PASSWORD.FORGOT_PASSWORD}`,
        );
        const isCreateAccountRoute = location.pathname.startsWith(
          `/${ROUTES.CREATE_ACCOUNT.NEW_PASSWORD}`,
        );

        if (
          !isUserLoggedIn &&
          !isRegisterRoute &&
          !isForgotPasswordRoute &&
          !isCreateAccountRoute
        ) {
          navigate(`/${ROUTES.LOGIN}${location.search}`, {
            replace: true,
          });
        } else if (isForgotPasswordRoute && !isUserLoggedIn) {
          navigate(`${location.pathname}${location.search}`, { replace: true });
        } else if (isUserLoggedIn) {
          navigate(`/${ROUTES.OVERVIEW}`);
        }
      }
    };

    handleNavigation();
  }, [
    isUserLoggedIn,
    location.pathname,
    location.search,
    navigate,
    type,
    removeStorage,
  ]);

  if (
    (type === RouteType.Private && !isUserLoggedIn) ||
    (type === RouteType.Restricted && isUserLoggedIn)
  ) {
    return null;
  }

  // Check if the current path is for 2FA reset
  const isTwofaValidateReset = TWOFA_VALIDATE_RESET_PATH.test(
    location.pathname,
  );

  const Layout =
    type === RouteType.Restricted || isTwofaValidateReset
      ? RestrictedRouteLayout
      : PrivateRouteLayout;

  return (
    <Layout>
      <Outlet />
      <ScrollRestoration />
    </Layout>
  );
};
