import { useMutation, useQuery } from '@tanstack/react-query';

import {
  TCountriesResponseSchema,
  TSingleStateResponseSchema,
} from '@/schemas';
import { API_ENDPOINT } from '@/utils/constants';
import { countryStateCityFetchApi } from '@/utils/helper';

export const useFetchAllCountryData = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: async (): Promise<TCountriesResponseSchema> => {
      const response = await countryStateCityFetchApi(
        API_ENDPOINT.COUNTRY_STATE.COUNTRY,
      );
      return response.data as TCountriesResponseSchema;
    },
    retry: false,
  });
};

export const useFetchStateByCountry = () => {
  return useMutation({
    mutationKey: ['states'],
    mutationFn: async (
      countryCode: string,
    ): Promise<TSingleStateResponseSchema> => {
      const response = await countryStateCityFetchApi(
        `${API_ENDPOINT.COUNTRY_STATE.COUNTRY}/${countryCode}/states`,
      );
      return response.data as TSingleStateResponseSchema;
    },
    retry: false,
  });
};
