import { z } from 'zod';

import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { DiscountLabel } from '@/utils/constants/promotion.constant';

export const MediaType = z.enum(['IMAGE', 'THUMBNAIL']);

const ColourSchema = z.object({
  colourName: z.string().optional().nullable(),
  colourHex: z.string().optional().nullable(),
});

export const SingleProductBaseResponseSchema = ColourSchema.extend({
  id: z.string().uuid().nullable().optional(),
  categoryId: z.string().uuid().nullable().optional(),
  productTypeId: z.string().uuid().nullable().optional(),
  name: z.string().nullable().optional(),
  skuCode: z.string().optional().nullable(),
  slug: z.string().nullable().optional(),
  isNew: z.boolean().nullable().optional(),
  moq: z.union([z.number(), z.string()]).optional().nullable(),
  specs: z.string().nullable(),
  description: z.string().nullable(),
  features: z.string().nullable(),
  price: z.union([z.string(), z.number()]).nullable().optional(),
  rrp: z.string().nullable().optional(),
  vat: z.union([z.string(), z.number()]).nullable().optional(),
  stock: z.number().nullable().optional(),
});

export const SingleProductAttributesSchema = z.object({
  vegan: z.boolean().nullable().optional(),
  waterProof: z.boolean().nullable().optional(),
  metalFree: z.boolean().nullable().optional(),
  antiStatic: z.boolean().nullable().optional(),
  esd: z.boolean().nullable().optional(),
  upperWaterResistant: z.boolean().nullable().optional(),
  slipRating: z.string().nullable().optional(),
  material: z.string().nullable().optional(),
  type: z.string().nullable().optional(),
  heatResistancy: z.number().nullable().optional(),
  chemicalResistance: z.string().nullable().optional(),
  isWomenFit: z.boolean().nullable().optional(),
  midsole: z.string().nullable().optional(),
  toecap: z.string().nullable().optional(),
  weight: z.union([z.number(), z.string()]).nullable().optional(),
});

export const SingleProductDetailDynamicPriceSchema = z.object({
  salePrice: z.number().nullable().optional(),
  discount: z.number().nullable().optional(),
  discountLabel: z.nativeEnum(DiscountLabel).nullable().optional(),
  priceType: z.nativeEnum(PRICETYPE).nullable().optional(),
  moq: z.union([z.number(), z.string()]).optional().nullable(),
});

export const SingleProductDetailIndustrySchema = z.object({
  title: z.string().nullable().optional(),
});

export type TSingleProductDetailIndustrySchema = z.infer<
  typeof SingleProductDetailIndustrySchema
>;

export const SingleProductDetailTechnologySchema = z.object({
  title: z.string().nullable().optional(),
  media: z.object({
    url: z.string().nullable().optional(),
    type: MediaType,
  }),
});

export type TSingleProductDetailTechnologySchema = z.infer<
  typeof SingleProductDetailTechnologySchema
>;

export const SingleProductMediaSchema = z.object({
  id: z.string().uuid().nullable().optional(),
  type: MediaType,
  sort: z.number().nullable().optional(),
  altText: z.string().nullable().optional(),
  url: z.string().nullable().optional(),
});

export const SingleProductDownloadSchema = z.object({
  url: z.string().nullable().optional(),
  title: z.string().nullable().optional(),
});

export type TSingleProductDownloadSchema = z.infer<
  typeof SingleProductDownloadSchema
>;

export const SingleProductVariantSchema = z.object({
  id: z.string().uuid().nullable().optional(),
  stock: z.number().nullable().optional(),
  size: z.union([z.string(), z.number()]).nullable().optional(),
  productId: z.string().uuid().nullable().optional(),
  cart: z.object({ quantity: z.number() }).nullable().optional(),
  variantId: z.string().uuid().nullable().optional(),
});

export type TSingleProductVariantSchema = z.infer<
  typeof SingleProductVariantSchema
>;

export const SingleProductDrawerHeaderSchema =
  SingleProductBaseResponseSchema.omit({
    id: true,
    slug: true,
    isNew: true,
    specs: true,
    description: true,
  }).merge(SingleProductDetailDynamicPriceSchema);

export type TSingleProductDrawerHeaderSchema = z.infer<
  typeof SingleProductDrawerHeaderSchema
>;
