import { create } from 'zustand';

import { isEmpty } from '@/utils/helper';
import { TSingleOrderDetailItemVariantSchema } from '@/schemas/api/orders/single-order-detail.schema';

export type CartDataLookup = Record<
  string,
  TSingleOrderDetailItemVariantSchema[]
>;

interface CartState {
  cartTableData: CartDataLookup;
  isCartProductQuantityDirty: boolean;
}

interface CartStateActions {
  setCartTableData: (cartTableData: CartDataLookup) => void;
  getNoOfProductsById: (productId: string) => number | false;
  getCartTotalPerCardById: (productId: string, productPrice: number) => number;
  getCartTotal: () => number;
  cleanCartStore: () => void;
  setIsCartProductQuantityDirty: (isCartProductQuantityDirty: boolean) => void;
}

type TCartState = CartState & CartStateActions;

const getProductQuantity = (
  cartTableData: CartDataLookup,
  productId: string,
): number | false => {
  const products = cartTableData[productId];

  return (
    !isEmpty(products) &&
    products.reduce((acc, curr) => acc + Number(curr.quantity), 0)
  );
};

const initialState: CartState = {
  cartTableData: {},
  isCartProductQuantityDirty: false,
};

const useCartStore = create<TCartState>((set, get) => ({
  ...initialState,
  setCartTableData: (cartTableData) =>
    set(() => ({
      cartTableData,
    })),
  getNoOfProductsById: (productId: string) => {
    const { cartTableData } = get();
    return getProductQuantity(cartTableData, productId);
  },
  getCartTotalPerCardById: (productId: string, productPrice: number) => {
    const { cartTableData } = get();
    const productsQuantity = getProductQuantity(cartTableData, productId);

    return productsQuantity ? productsQuantity * productPrice : 0;
  },
  getCartTotal: () => {
    const { cartTableData } = get();

    const cartTotal = Object.keys(cartTableData).reduce((total, cartItem) => {
      const itemTotal = cartTableData[cartItem].reduce(
        (innerItemTotal, item) => {
          const price = Number(item.salePrice);
          const quantity = item.quantity;
          return innerItemTotal + price * Number(quantity);
        },
        0,
      );
      return total + itemTotal;
    }, 0);

    return cartTotal;
  },
  setIsCartProductQuantityDirty: (isCartProductQuantityDirty: boolean) => {
    set(() => ({
      isCartProductQuantityDirty,
    }));
  },

  cleanCartStore: () => set(initialState),
}));

export default useCartStore;
