import { cn } from '@/utils/cn';
import { cva, VariantProps } from 'class-variance-authority';

export const colorBadgeVariants = cva(
  'rounded-full size-4 border border-gray-300',
  {
    variants: {
      variant: {
        black: 'bg-gray-950',
        blue: 'bg-blue-600',
        brown: 'bg-yellow-950',
        yellow: 'bg-yellow-600',
        gray: 'bg-gray-600',
        tan: 'bg-tan',
        red: 'bg-red-600',
        transparent: 'bg-transparent',
      },
    },
    defaultVariants: {
      variant: 'brown',
    },
  },
);

export interface ColorBadgeProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof colorBadgeVariants> {
  shoeProductColor?: string;
}

export const ColorBadge: React.FC<ColorBadgeProps> = ({
  className,
  variant,
  children,
  shoeProductColor,
  ...props
}: ColorBadgeProps) => {
  return (
    <p className='inline-flex items-center gap-x-2 capitalize text-gray-800'>
      <span
        className={cn(colorBadgeVariants({ variant }), className)}
        data-cy={`${variant}-badge`}
        style={{ backgroundColor: `#${shoeProductColor}` }}
        {...props}
      />
      {children}
    </p>
  );
};
