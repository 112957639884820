import React from 'react';

import {
  IconNotification,
  IconNotificationFilled,
} from './regular/notification';
import { Lock } from './fill/lock';
import { IconXls } from './fill/xls';
import { IconCube } from './fill/cube';
import { IconFlag } from './fill/flag';
import { IconGear } from './fill/gear';
import { IconInfo } from './fill/Info';
import { IconStar } from './fill/star';
import { IconAdd } from './regular/add';
import { IconPdf } from './regular/pdf';
import { IconError } from './fill/error';
import { IconHappy } from './fill/happy';
import { IconPoint } from './fill/point';
import { IconStock } from './fill/stock';
import { IconUsers } from './fill/users';
import { Invoice } from './fill/invoice';
import { IconHide } from './regular/Hide';
import { IconUser } from './regular/user';
import { IconChat } from './regular/chat';
import { IconEdit } from './regular/edit';
import { IconLink } from './regular/link';
import { IconList } from './regular/list';
import { IconCopy } from './regular/copy';
import { ImageFilled } from './fill/image';
import { IconLeader } from './fill/leader';
import { IconLogout } from './fill/logout';
import { IconOrders } from './fill/orders';
import { IconCheck } from './regular/check';
import { IconClose } from './regular/close';
import { IconStack } from './regular/stack';
import { IconTrash } from './regular/trash';
import { IconCrown } from './regular/crown';
import { IconHeart } from './regular/heart';
import { IconRuler } from './regular/ruler';
import { IconMinus } from './regular/minus';
import { IconReset } from './regular/reset';
import { IconMapIn } from './regular/mapIn';
import { IconPhone } from './regular/phone';
import { IconImage } from './regular/image';
import { IconThumb } from './regular/thumb';
import { EyeOpen } from './regular/eyeOpen';
import { RegIconInfo } from './regular/info';
import { IconFilePdf } from './fill/filePdf';
import { IconFilePng } from './fill/filePng';
import { IconProduct } from './fill/product';
import { IconSetting } from './fill/setting';
import { IconSidebar } from './fill/sidebar';
import { IconSparkle } from './fill/sparkle';
import { IconSupport } from './fill/support';
import { IconWarning } from './fill/warning';
import { IconWebsite } from './fill/website';
import { IconSearch } from './regular/search';
import { IconExport } from './regular/export';
import { IconIdCard } from './regular/idCard';
import { IconFilter } from './regular/filter';
import { IconUpload } from './regular/upload';
import { IconTrenUp } from './regular/trendUp';
import { IconOverview } from './fill/overview';
import { IconHeadset } from './regular/headset';
import { IconArrowUp } from './regular/arrowUp';
import { IconCompare } from './regular/compare';
import { IconPrinter } from './regular/printer';
import { IconReceipt } from './regular/receipt';
import { IconSpinner } from './regular/spinner';
import { IconVisa } from './payment-cards/visa';
import { IconAmex } from './payment-cards/amex';
import { IconChecklist } from './fill/checklist';
import { IconResources } from './fill/resources';
import { IconWarehouse } from './fill/warehouse';
import { LoudSpeaker } from './fill/loudSpeaker';
import { IconBuilding } from './regular/building';
import { IconFileText } from './regular/fileText';
import { IconEnvelope } from './regular/envelope';
import { IconShipment } from './regular/shipment';
import { IconQuestion } from './regular/questions';
import { IconDispatched } from './fill/dispatched';
import { IconUserCircle } from './fill/userCircle';
import { getIconSize, SizeType } from './iconProps';
import { IconArrowLeft } from './regular/arrowLeft';
import { IconTrendDown } from './regular/trendDown';
import { IconChevronUp } from './regular/chevronUp';
import { IconAddCircle } from './regular/addCircle';
import { IconUserThree } from './regular/userThree';
import { IconDotsThree } from './regular/dotsThree';
import { IconPaperClip } from './regular/paperClip';
import { IconMagicWand } from './regular/magicWand';
import { IconCaretUpDown } from './fill/caretUpDown';
import { IconColorSwatch } from './fill/ColorSwatch';
import { IconOrderPicked } from './fill/orderPicked';
import { CheckBoxTick } from './regular/CheckboxTick';
import { IconArrowRight } from './regular/arrowRight';
import { IconArrowsDown } from './regular/arrowsDown';
import { IconMapPinArea } from './regular/mapPinArea';
import { IconSmartPhone } from './regular/smartPhone';
import { IconChevronDown } from './regular/chevronDown';
import { IconCheckCircle } from './regular/checkCircle';
import { IconSquaresFour } from './regular/squaresFour';
import { IconChevronLeft } from './regular/chevronleft';
import { UsersAndTeams } from './regular/usersAndTeams';
import { IconDiscover } from './payment-cards/discover';
import { IconOrderReceived } from './fill/orderReceived';
import { IconRealTimeStock } from './fill/realtimeStock';
import { IconChevronRight } from './regular/chevronRight';
import { IconArrowDownUp } from './regular/arrowsDownUp-';
import { IconPencilSimple } from './regular/pencilSimple';
import { IconExternalLink } from './regular/externalLink';
import { IconCart, IconCartFilled } from './regular/cart';
import { IconMasterCard } from './payment-cards/masterCard';
import { IconArrowLineLeft } from './regular/arrowLineLeft';
import { IconSidebarExpand } from './regular/sidebarExpand';
import { IconSortAscending } from './regular/sortAscending';
import { IconFileArrowDown } from './regular/fileArrowDown';
import { IconCalendarBlank } from './regular/calendarBlank';
import { IconOrderProcessing } from './fill/orderProcessing';
import { IconPaperPlaneRight } from './fill/paperPlaneRight';
import { IconArrowLineRight } from './regular/arrowLineRight';
import { IconSortDescending } from './regular/sortDescending';
import { IconDownloadSimple } from './regular/downloadSimple';
import { IconBuildingOffice } from './regular/buildingOffice';
import { IconEnvelopeSimple } from './regular/envelopeSimple';
import { IconChatCircleDots } from './regular/chatCircleDots';
import { IconOrderConfirmation } from './fill/orderConfirmation';
import { GoogleAuthenticator } from './fill/googleAuthenticator';
import { IconSidebarCollapsed } from './regular/sidebarCollapsed';
import { IconDotsThreeVertical } from './regular/dotsThreeVertical';
import { IconMarkettingResources } from './fill/markettingResources';
import { IconListMagnifyingGlass } from './regular/listMagnifyingGlass';
import { IconArrowCounterClockwise } from './regular/arrowCounterClockwise';
import { IconHeartEye } from './fill/heartEye';
import { IconSmileyHappy } from './fill/smileyHappy';
import { IconSmileyMeh } from './fill/smileyMeh';
import { IconSmileySad } from './fill/smileySad';
import { IconSmileyNervous } from './fill/smileyNervous';

const PaymentCardsIcons = {
  visa: IconVisa,
  amex: IconAmex,
  discover: IconDiscover,
  masterCard: IconMasterCard,
};

export const icons = {
  ...PaymentCardsIcons,
  'reg-check': IconCheck,
  'reg-close': IconClose,
  'reg-eye-open': EyeOpen,
  'reg-hide': IconHide,
  'reg-arrow-left': IconArrowLeft,
  'reg-arrow-right': IconArrowRight,
  'reg-headset': IconHeadset,
  'reg-question': IconQuestion,
  'reg-building': IconBuilding,
  'reg-user': IconUser,
  'reg-check-circle': IconCheckCircle,
  'reg-chevron-right': IconChevronRight,
  'reg-chevron-left': IconChevronLeft,
  'reg-search': IconSearch,
  'reg-cart': IconCart,
  'reg-chat': IconChat,
  'reg-notification': IconNotification,
  'reg-trend-up': IconTrenUp,
  'reg-trend-down': IconTrendDown,
  'reg-arrows-up': IconArrowUp,
  'reg-arrows-down-up': IconArrowDownUp,
  'reg-arrows-down': IconArrowsDown,
  'reg-arrow-line-left': IconArrowLineLeft,
  'reg-arrow-line-right': IconArrowLineRight,
  'reg-dots-three-vertical': IconDotsThreeVertical,
  'reg-sidebar-expand': IconSidebarExpand,
  'reg-sort-ascending': IconSortAscending,
  'reg-sort-descending': IconSortDescending,
  'reg-edit': IconEdit,
  'reg-chevron-up': IconChevronUp,
  'reg-chevron-down': IconChevronDown,
  'reg-add': IconAdd,
  'reg-add-circle': IconAddCircle,
  'reg-export': IconExport,
  'reg-user-three': IconUserThree,
  'reg-id-card': IconIdCard,
  'reg-dots-three': IconDotsThree,
  'reg-filter': IconFilter,
  'reg-trash': IconTrash,
  'reg-upload': IconUpload,
  'reg-crown': IconCrown,
  'reg-compare': IconCompare,
  'reg-heart': IconHeart,
  'reg-pdf': IconPdf,
  'reg-stack': IconStack,
  'reg-link': IconLink,
  'reg-sidebar-collapsed': IconSidebarCollapsed,
  'reg-ruler': IconRuler,
  'reg-minus': IconMinus,
  'reg-arrow-counter-clockwise': IconArrowCounterClockwise,
  'reg-reset': IconReset,
  'reg-map-in': IconMapIn,
  'reg-phone': IconPhone,
  'reg-image': IconImage,
  'reg-thumb': IconThumb,
  'reg-list': IconList,
  'reg-squares-four': IconSquaresFour,
  'reg-list-magnifying-glass': IconListMagnifyingGlass,
  'reg-file-arrow-down': IconFileArrowDown,
  'reg-download-simple': IconDownloadSimple,
  'reg-printer': IconPrinter,
  'reg-building-office': IconBuildingOffice,
  'reg-envelope-simple': IconEnvelopeSimple,
  'reg-map-pin-area': IconMapPinArea,
  'reg-pencil-simple': IconPencilSimple,
  'reg-receipt': IconReceipt,
  'reg-calendar-blank': IconCalendarBlank,
  'reg-file-text': IconFileText,
  'reg-paperclip': IconPaperClip,
  'reg-spinner': IconSpinner,
  'reg-magic-wand': IconMagicWand,
  'reg-chat-circle-dots': IconChatCircleDots,
  'reg-smartphone': IconSmartPhone,
  'reg-envelope': IconEnvelope,
  'reg-shipment': IconShipment,
  'reg-copy': IconCopy,
  'reg-info': RegIconInfo,
  'fill-carret-up-down': IconCaretUpDown,
  'fill-cart': IconCartFilled,
  'fill-chat': IconChat,
  'fill-check-circle': IconCheckCircle,
  'fill-checkList': IconChecklist,
  'fill-color-swatch': IconColorSwatch,
  'fill-compare': IconCompare,
  'fill-cube': IconCube,
  'fill-dispatched': IconDispatched,
  'fill-error': IconError,
  'fill-file-pdf': IconFilePdf,
  'fill-file-png': IconFilePng,
  'fill-flag': IconFlag,
  'fill-gear': IconGear,
  'fill-happy': IconHappy,
  'fill-heart-eye': IconHeartEye,
  'fill-smiley-happy': IconSmileyHappy,
  'fill-smiley-meh': IconSmileyMeh,
  'fill-smiley-nervous': IconSmileyNervous,
  'fill-smiley-sad': IconSmileySad,
  'fill-info': IconInfo,
  'fill-leader': IconLeader,
  'fill-logout': IconLogout,
  'fill-marketing-resources': IconMarkettingResources,
  'fill-notifications': IconNotificationFilled,
  'fill-order-confirmation': IconOrderConfirmation,
  'fill-order-picked': IconOrderPicked,
  'fill-order-processing': IconOrderProcessing,
  'fill-order-received': IconOrderReceived,
  'fill-orders': IconOrders,
  'fill-overview': IconOverview,
  'fill-paper-plane-right': IconPaperPlaneRight,
  'fill-point': IconPoint,
  'fill-product': IconProduct,
  'fill-question': IconQuestion,
  'fill-realtime-stock': IconRealTimeStock,
  'fill-receipt': IconReceipt,
  'fill-resources': IconResources,
  'fill-setting': IconSetting,
  'fill-sidebar': IconSidebar,
  'fill-sparkle': IconSparkle,
  'fill-star': IconStar,
  'fill-stock': IconStock,
  'fill-support': IconSupport,
  'fill-user': IconUser,
  'fill-user-circle': IconUserCircle,
  'fill-users': IconUsers,
  'fill-warehouse': IconWarehouse,
  'fill-warning': IconWarning,
  'fill-website': IconWebsite,
  'fill-xls': IconXls,
  'reg-checkbox-tick': CheckBoxTick,
  'reg-external-link': IconExternalLink,
  'fill-invoice': Invoice,
  'reg-users-and-teams': UsersAndTeams,
  'fill-loud-speaker': LoudSpeaker,
  'fill-image': ImageFilled,
  'google-authenticator': GoogleAuthenticator,
  'fill-lock': Lock,
};
export interface IconProps {
  fill?: string;
  size?: SizeType;
  icon: keyof typeof icons;
}

function IconsComponent({
  icon,
  fill,
  size,
}: Readonly<IconProps>): React.ReactNode {
  const sizes = size ? getIconSize(size) : 24;
  const Component = icons[icon];

  return (
    <Component
      fill={fill ?? '#000000'}
      size={sizes}
    />
  );
}

export default IconsComponent;
