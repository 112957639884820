import { z } from 'zod';

export const DeleteTeamRequestSchema = z.object({
  ids: z.array(z.string()),
});
export const DeleteTeamResponseSchema = z.object({
  //   status: z.boolean(),
  message: z.string(),
  timestamp: z.string().optional(),
  data: z.object({
    count: z.number(),
  }),
});

export const DeleteUserOnTeamResponseSchema = z.object({
  //   status: z.boolean(),
  message: z.string(),
});

export type TDeleteTeam = z.infer<typeof DeleteTeamRequestSchema>;

// BULK delete teams
export const DeleteBulkTeamRequestSchema = z.object({
  ids: z.array(z.string()),
});
export type TDeleteBulkTeam = z.infer<typeof DeleteBulkTeamRequestSchema>;

export const DeleteBulkTeamResponseSchema = z.object({
  message: z.string(),
});
