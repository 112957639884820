import { Skeleton } from '@/components/lib/Skeleton';

export function ProductCardSkeleton() {
  return (
    <div
      className='ProductCardSkeleton relative h-auto max-h-[516px] min-w-[268px] overflow-hidden rounded border border-gray-100 bg-white shadow-sm'
      data-cy='product-card-skeleton'
    >
      <Skeleton className='h-60' />
      <div className='p-5'>
        <Skeleton className='mb-2 h-5 w-full md:w-40' />
        <Skeleton className='mb-6 h-5 w-full md:w-48' />
      </div>
      <div className='absolute bottom-[-200px] flex h-20 w-full border-y border-gray-100 bg-white px-5 py-4 shadow-max'>
        <Skeleton className='mr-4 h-8 w-full md:w-20' />
        <Skeleton className='h-8 w-full md:w-24' />
      </div>
    </div>
  );
}

export const ProductCardSkeletons = ({ length }: { length: number }) => {
  return Array.from({ length: length }).map((_, index) => (
    <ProductCardSkeleton key={index} />
  ));
};
