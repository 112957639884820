import { useNavigate, useLocation } from 'react-router-dom';

export const useRemoveQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const removeQueryParam = (keys: string | string[]) => {
    const searchParams = new URLSearchParams(location.search);

    const keysArray = Array.isArray(keys) ? keys : [keys];

    keysArray.forEach((key) => {
      searchParams.delete(key);
    });

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { preventScrollReset: true },
    );
  };

  return { removeQueryParam };
};
