import { Link } from 'react-router-dom';

import WaitingForApprovalIllustration from '@/assets/images/illustration/waitingForApproval.svg';

import { IconsComponent } from '@/components';
import ImageComponent from '@/components/ui/image/Image';
import { V12_EXPLORE_CATEGORIES } from '@/utils/constants';

const WaitingForApproval = () => {
  return (
    <div className='flex flex-col items-center justify-center'>
      <ImageComponent
        src={WaitingForApprovalIllustration}
        alt='Waiting for approval illustration'
        classes='w-[400px] h-[240px]'
      />
      <div className='mt-4 text-center'>
        <h4>Waiting for approval</h4>
        <div className='mt-2'>
          Thank you for requesting a new account. Your account is currently on
          hold and under review. This process may take 2-3 business days.
        </div>
      </div>

      <div className='mt-14 text-center'>
        <div className='mb-6 mt-2'>
          While waiting for approval, why not explore our products?
        </div>

        <Link
          to={V12_EXPLORE_CATEGORIES}
          className='ml-auto flex items-center justify-center gap-2 text-base font-semibold text-primary-600'
        >
          <span>Explore Our Products</span>
          <IconsComponent
            icon='reg-external-link'
            size='sm'
            fill='#6e2534'
          />
        </Link>
      </div>
    </div>
  );
};

export default WaitingForApproval;
