import { Skeleton } from '@/components/lib/Skeleton';

export function AnalyticCardSkeletion() {
  return (
    <div className='grid gap-4 sm:grid-cols-2 md:grid-cols-3'>
      {...Array.from({ length: 2 }).map((_, index) => (
        <div key={index}>
          <Skeleton className='h-[120px]' />
        </div>
      ))}
    </div>
  );
}

export function AnalyticChartCardSkeletion() {
  return (
    <div className='flex flex-col gap-4 sm:flex-row'>
      {Array.from({ length: 1 }).map((_, index) => (
        <div
          key={index}
          className='flex flex-col rounded bg-white px-4 py-3 sm:w-[520px] sm:flex-row sm:gap-4'
        >
          <div className='mb-4 w-full sm:mb-0 sm:w-1/2'>
            <Skeleton className='mb-2 h-4 w-24' />
            <Skeleton className='mb-4 h-8 w-32' />
            <Skeleton className='h-4 w-40' />
          </div>
          <div className='w-full sm:w-1/2'>
            <Skeleton className='h-[100px] w-full' />
          </div>
        </div>
      ))}
    </div>
  );
}
