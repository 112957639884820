import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Table } from '@tanstack/react-table';

import { Status, TBulkExportUser, TUser } from '@/schemas';
import { ROUTES } from '@/utils/constants/routes.constant';
import {
  FilterForm,
  MobileResponsiveFilterForm,
} from '@/pages/users-and-teams/users/FilterForm';
import {
  Button,
  Dropdown,
  Breadcrumb,
  FilterBadge,
  SlideInPanel,
  BreadcrumbItem,
  IconsComponent,
  SearchInputForm,
} from '@/components';
import {
  CreateUserModal,
  DeleteUserModal,
  UserStatusModal,
  Send2FAInstructionModal,
  SendPasswordResetLinkModal,
} from '@/pages/users-and-teams/users/modals';
import { useExportUsers } from '@/components/hooks/api/users';
import { Can, PERMISSIONS, RESTRICTED_SECTIONS } from '@/utils/helper';
import { useIsDeviceMobileOrLarger } from '@/components/hooks';

export function ActionButton({ table }: { table: Table<TUser> }) {
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  const selectedRowId = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original.id);

  const numberOfSelectedRows = selectedRowId.length;

  const handleResetRowSelection = () => table.resetRowSelection();

  const isInvited = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original)
    .every((row) => row.isInvited === true);

  const isInvitePending = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original)
    .some((row) => row.status === Status.INVITE_PENDING);

  const { handleExport, isExporting } = useExportUsers();

  const handleExportClick = () => {
    const bulkExportUser: TBulkExportUser = {
      users: selectedRowId,
    };
    handleExport(bulkExportUser);
  };

  return (
    numberOfSelectedRows > 0 &&
    createPortal(
      <div className='flex flex-col gap-4 sm:flex-row sm:items-center'>
        <p>{numberOfSelectedRows} Selected</p>
        <div className='flex flex-col gap-y-2 sm:flex-row sm:items-center sm:gap-x-2'>
          <Can
            I={PERMISSIONS.UPDATE}
            a={RESTRICTED_SECTIONS.CHANGE_STATUS}
          >
            <UserStatusModal
              isInvited={isInvited}
              isInvitePending={isInvitePending}
              selectedRowId={selectedRowId}
              handleResetRowSelection={handleResetRowSelection}
            />
          </Can>
          <Button
            size='xs'
            variant='blue'
            intent='outline'
            className='px-3'
            disabled={isExporting}
            onClick={handleExportClick}
          >
            Export Selected
          </Button>
          <Can
            I={PERMISSIONS.DELETE}
            a={RESTRICTED_SECTIONS.DELETE_USERS}
          >
            <DeleteUserModal
              selectedRowId={selectedRowId}
              handleResetRowSelection={handleResetRowSelection}
            />
          </Can>
          <Can
            I={PERMISSIONS.UPDATE}
            a={RESTRICTED_SECTIONS.SEND_2FA_PASSWORD_RESET_INSTRUCTIONS}
          >
            <Dropdown>
              <Dropdown.Toggle
                size='xs'
                variant='gray'
                intent='outline'
                className='w-full px-3'
              >
                More{' '}
                <IconsComponent
                  icon='reg-dots-three'
                  size='sm'
                />
              </Dropdown.Toggle>
              <Dropdown.Content position='center'>
                <Button
                  size='xs'
                  variant='gray'
                  intent='tertiary'
                  className='w-full justify-start px-3 py-2 text-gray-800 hover:bg-gray-100'
                  onClick={() => setShowInstructionModal(true)}
                >
                  Send 2FA Instruction
                </Button>
                <Button
                  size='xs'
                  variant='gray'
                  intent='tertiary'
                  className='w-full justify-start px-3 py-2 text-gray-800 hover:bg-gray-100'
                  onClick={() => setShowPasswordResetModal(true)}
                >
                  Send Password Reset Link
                </Button>
              </Dropdown.Content>
            </Dropdown>
            <Send2FAInstructionModal
              selectedRowId={selectedRowId}
              showModal={showInstructionModal}
              setShowModal={setShowInstructionModal}
              handleResetRowSelection={handleResetRowSelection}
            />
            <SendPasswordResetLinkModal
              showModal={showPasswordResetModal}
              setShowModal={setShowPasswordResetModal}
              selectedRowId={selectedRowId}
              handleResetRowSelection={handleResetRowSelection}
            />
          </Can>
        </div>
      </div>,
      document.getElementById('action-button') as Element,
    )
  );
}

export function ActionBar() {
  const { handleExport, isExporting } = useExportUsers();
  const isMobileOrLarger = useIsDeviceMobileOrLarger();

  const handleExportClick = () => {
    const bulkExportUser: TBulkExportUser = {
      users: [], // Fill in with the appropriate data
    };
    handleExport(bulkExportUser);
  };
  return (
    <>
      <div className='pb-8'>
        <Breadcrumb>
          <BreadcrumbItem href={`/${ROUTES.OVERVIEW}`}>
            Dashboard
          </BreadcrumbItem>
          <BreadcrumbItem>Users</BreadcrumbItem>
        </Breadcrumb>
        <div className='items-center justify-between gap-4 space-y-4 sm:flex sm:space-y-0'>
          <h4>Users</h4>
          <div className='items-center justify-between gap-4 space-y-4 sm:flex sm:space-y-0'>
            <Button
              variant='gray'
              intent='outline'
              className='w-full'
              disabled={isExporting}
              onClick={handleExportClick}
            >
              <IconsComponent
                size='md'
                icon='reg-export'
              />{' '}
              Export
            </Button>
            <CreateUserModal />
          </div>
        </div>
      </div>
      <div className='-mb-4 flex flex-wrap items-center justify-end gap-4 py-3'>
        <div
          id='action-button'
          className='w-full sm:mr-auto'
        />
        <div className='z-10 flex flex-wrap items-center gap-4 space-x-2'>
          <SearchInputForm />
          {isMobileOrLarger ? (
            <SlideInPanel direction='right'>
              <FilterForm />
            </SlideInPanel>
          ) : (
            <MobileResponsiveFilterForm />
          )}
        </div>
      </div>
      <FilterBadge />
    </>
  );
}
