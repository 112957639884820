import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import {
  Button,
  TableCheckbox,
  Badge,
  Dropdown,
  Modal,
  IconsComponent,
  Link,
  Avatar,
  Tooltip,
} from '@/components';
import { TUser } from '@/schemas';
import { ROUTES, TANSTACK_QUERY_KEY, UserDelete } from '@/utils/constants';
import {
  scrollToTop,
  useAlert,
  useBulkDeleteUser as useDeleteUser,
} from '@/components/hooks';
import {
  AbilityContext,
  PERMISSIONS,
  RESTRICTED_SECTIONS,
} from '@/utils/helper/roles';
import { useUserStore } from '@/store/user-store-new';
import { countryCodeToFlag, handleApiError } from '@/utils/helper';
import { limitNameWithLength } from '@/utils/helper/limitNameWithLength';

type ProfileStatus = 'ACTIVE' | 'INACTIVE' | 'INVITED' | 'APPROVED';

/**
 * @description UserStatus component to display user status
 */
function UserStatus({ status }: { status: ProfileStatus }) {
  switch (status) {
    case 'ACTIVE':
    case 'APPROVED':
      return <Badge variant='green'>Active</Badge>;
    case 'INACTIVE':
      return <Badge>Inactive</Badge>;
    case 'INVITED':
      return <Badge variant='yellow'>Invite Pending</Badge>;
    default:
      return 'N/A';
  }
}
/**
 * @description TeamColumn component to display teams for user
 */
function TeamColumn(info: CellContext<TUser, unknown>) {
  const teams = info.row.original.teams;
  const maxTeamsToShow = teams.slice(0, 2);
  const remainingTeams = teams.slice(2);

  return (
    <>
      {maxTeamsToShow.length > 0
        ? maxTeamsToShow.map((team) => limitNameWithLength(team, 25)).join(', ')
        : 'N/A'}
      {remainingTeams.length > 0 && (
        <Tooltip className='action'>
          <Tooltip.Trigger>
            <Button
              intent='tertiary'
              variant='gray'
              className='p-0 font-normal text-gray-500'
              size='xs'
            >
              + {remainingTeams.length}{' '}
              {remainingTeams.length === 1 ? 'team' : 'teams'}
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <ul className='h-full max-h-24 overflow-y-auto'>
              {remainingTeams.map((team) => (
                <li key={team}>{limitNameWithLength(team, 25)}</li>
              ))}
            </ul>
          </Tooltip.Content>
        </Tooltip>
      )}
    </>
  );
}

/**
 * @description ActionColumn component to display action buttons for user
 */
function ActionColumn(info: CellContext<TUser, unknown>) {
  const { firstName, lastName, id } = info.row.original;
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const queryClient = useQueryClient();

  const { showAlert } = useAlert();
  const { isPending, mutate } = useDeleteUser();
  const { user } = useUserStore();

  const isUserLoggedIn = user?.id === id;
  const handleDeleteUser = () => {
    const deleteUser = {
      users: [id],
    };
    mutate(deleteUser, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [TANSTACK_QUERY_KEY.USER] });
        navigate(`/${ROUTES.USER_AND_TEAMS.USER.LIST}`);
        setTimeout(() => {
          showAlert(UserDelete, 'success');
        }, 150);
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        scrollToTop();
        setShowDeleteModal(false);
      },
    });
  };
  return (
    <>
      <Dropdown className='action'>
        <Dropdown.Toggle
          size='icon'
          intent='tertiary'
        >
          <IconsComponent
            icon='reg-dots-three'
            size='sm'
          />
        </Dropdown.Toggle>
        <Dropdown.Content position='right'>
          <Link
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            to={`/${ROUTES.USER_AND_TEAMS.USER.LIST}/${id}`}
          >
            Edit
          </Link>

          <Button
            size='xs'
            variant='gray'
            intent='tertiary'
            className='w-full justify-start px-3 py-2 font-normal text-gray-800 hover:bg-gray-100'
            onClick={() => setShowDeleteModal(true)}
            disabled={isUserLoggedIn}
          >
            Delete
          </Button>
        </Dropdown.Content>
      </Dropdown>
      <Modal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      >
        <Modal.Content>
          <Modal.Title>
            <h6>Delete User</h6>
          </Modal.Title>
          <Modal.Body className='space-y-4'>
            <p className='text-base text-gray-800'>
              You are about to delete{' '}
              <span className='font-bold'>
                {firstName} {lastName}.{' '}
              </span>
              All the information related to these user will be lost.
            </p>
            <p className='text-base font-bold text-gray-800'>
              Are you sure you want to delete user?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Modal.CloseButton>Cancel</Modal.CloseButton>
            <Button
              variant='red'
              disabled={isPending}
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}

/**
 * @description Hook to define columns for the user table
 */
export function useUserColumn() {
  const ability = useContext(AbilityContext); // Get the ability instance

  const getUserRole = (role: string) => {
    switch (role) {
      case 'SUPER_ADMIN':
        return 'Super Admin';
      case 'ADMIN':
        return 'Admin';
      case 'STAFF':
        return 'Staff';
      default:
        return 'N/A';
    }
  };

  const columns = useMemo<ColumnDef<TUser>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <TableCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <TableCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        accessorKey: 'email',
        header: 'Name/Email',
        cell: (info) => {
          const { firstName, lastName, email, image } = info.row.original;
          const name = `${firstName} ${lastName}`;
          return (
            <div className='flex items-center space-x-3'>
              <Avatar
                avatar={[
                  {
                    imageSrc: image,
                    name: name,
                  },
                ]}
                defaultAvatarLetter={name.charAt(0).toUpperCase()}
              />
              <div>
                <p className='text-black'>{name}</p>
                <p className='text-gray-500'>{email}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone No.',
        enableSorting: false,
        cell: (info) => {
          const { phoneNumber, countryCode } = info.row.original;
          const countryFlag = countryCodeToFlag(countryCode);
          return (
            <span className='whitespace-nowrap'>
              <span className='emoji pr-2'>{countryFlag}</span>
              {phoneNumber}
            </span>
          );
        },
      },
      {
        accessorKey: 'teams',
        header: 'Team',
        enableSorting: false,
        cell: (info) => <TeamColumn {...info} />,
      },
      {
        accessorKey: 'designation',
        header: 'Designation',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        cell: (info) => {
          const status = info.row.original.status;
          return <UserStatus status={status} />;
        },
      },
      {
        accessorKey: 'role',
        header: 'Role',
        enableSorting: false,
        cell: (info) => {
          const role = info.row.original.role;
          return <span>{getUserRole(role)}</span>;
        },
      },
      ...(ability.can(PERMISSIONS.UPDATE, RESTRICTED_SECTIONS.EDIT_USERS)
        ? [
            {
              accessorKey: 'actions',
              header: 'Actions',
              enableSorting: false,
              cell: (info: CellContext<TUser, unknown>) => (
                <ActionColumn {...info} />
              ),
            },
          ]
        : []),
    ],
    [],
  );
  return { columns };
}
