import { useState, useEffect } from 'react';
import { useSubmit, useLocation, useSearchParams } from 'react-router-dom';

import { scrollToTop } from '@/components/hooks/utils/useScrollToTop';

interface Range {
  inputFrom: number;
  inputTo: number;
}

interface UseRangeFilterProps {
  queryKeyFrom: string;
  queryKeyTo: string;
  min: number;
  max: number;
  initialRange: Range;
}

/**
 * @description Custom hook to manage range filter state and submission.
 * @returns The range state, setRange function, handleSubmit function, and isButtonDisabled flag.
 */
export function useRangeFilter({
  min,
  max,
  queryKeyTo,
  queryKeyFrom,
  initialRange,
}: UseRangeFilterProps) {
  const submit = useSubmit();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [range, setRange] = useState<Range>(initialRange);

  const defaultFrom = Number(searchParams.get(queryKeyFrom));
  const defaultTo = Number(searchParams.get(queryKeyTo));

  const isButtonDisabled =
    defaultFrom === range.inputFrom && defaultTo === range.inputTo;

  const handleSubmit = () => {
    try {
      const queryParams = new URLSearchParams(searchParams);

      queryParams.delete(queryKeyFrom);
      queryParams.delete(queryKeyTo);

      queryParams.append(queryKeyFrom, String(range.inputFrom));
      queryParams.append(queryKeyTo, String(range.inputTo));

      submit(queryParams, {
        action: location.pathname,
      });
    } catch (error) {
      console.error(error);
    } finally {
      scrollToTop();
    }
  };

  useEffect(() => {
    const isValidFromCondition = defaultFrom > min && defaultFrom < defaultTo;
    const isValidToCondition =
      defaultTo > min && defaultTo >= defaultFrom && defaultTo < max;

    setRange((prev) => {
      const newRangeValues = { ...prev };

      if (isValidFromCondition) {
        newRangeValues.inputFrom = defaultFrom;
      }

      if (isValidToCondition) {
        newRangeValues.inputTo = defaultTo;
      }

      return newRangeValues;
    });

    if (!isValidFromCondition && !isValidToCondition) {
      setRange(initialRange);

      const queryParams = new URLSearchParams(searchParams);

      queryParams.delete(queryKeyFrom);
      queryParams.delete(queryKeyTo);

      queryParams.append(queryKeyFrom, String(initialRange.inputFrom));
      queryParams.append(queryKeyTo, String(initialRange.inputTo));
    }
  }, [searchParams, initialRange]);

  return {
    range,
    setRange,
    handleSubmit,
    isButtonDisabled,
  };
}
