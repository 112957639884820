import React from 'react';
import { IconProps } from '../iconProps';

export const IconSmileyNervous: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2363_20)'>
        <path
          d='M12 24C5.3835 24 0 18.6165 0 12C0 5.3835 5.3835 0 12 0C18.6165 0 24 5.3835 24 12C24 18.6165 18.6165 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM12 20.25C10.3455 20.25 9 18.9045 9 17.25C9 15.5955 10.3455 14.25 12 14.25C13.6545 14.25 15 15.5955 15 17.25C15 18.9045 13.6545 20.25 12 20.25ZM12 15.75C11.1727 15.75 10.5 16.4228 10.5 17.25C10.5 18.0772 11.1727 18.75 12 18.75C12.8273 18.75 13.5 18.0772 13.5 17.25C13.5 16.4228 12.8273 15.75 12 15.75ZM16.125 12.75C14.199 12.75 13.5 11.4045 13.5 10.5C13.5 10.086 13.8352 9.75 14.25 9.75C14.6602 9.75 14.9932 10.0793 15 10.488C15.0105 10.719 15.1418 11.25 16.125 11.25C17.196 11.25 17.25 10.6252 17.25 10.5C17.25 10.086 17.5853 9.75 18 9.75C18.4147 9.75 18.75 10.086 18.75 10.5C18.75 11.4045 18.051 12.75 16.125 12.75ZM7.875 12.75C5.949 12.75 5.25 11.4045 5.25 10.5C5.25 10.086 5.586 9.75 6 9.75C6.41025 9.75 6.74325 10.0793 6.75 10.488C6.7605 10.719 6.89175 11.25 7.875 11.25C8.946 11.25 9 10.6252 9 10.5C9 10.086 9.336 9.75 9.75 9.75C10.164 9.75 10.5 10.086 10.5 10.5C10.5 11.4045 9.801 12.75 7.875 12.75Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2363_20'>
          <rect
            width='24'
            height='24'
            rx='12'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
