export const V12_CURRENCY_TYPES = [
  { value: 'GBP', label: 'GBP' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
];

export enum V12CurrencySymbols {
  GBP = '£',
  USD = '$',
  EUR = '€',
}
