import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Badge,
  Button,
  Dropdown,
  FormGroup,
  SearchInput,
  IconsComponent,
  FilterCheckboxOption,
  Modal,
  DropDownLoadingSkeleton,
} from '@/components';
import {
  useAlert,
  useScrollToTop,
  useUpdateAssignTeam,
} from '@/components/hooks';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import {
  useDeleteMemberOnTeam,
  useGetAllTeamsList,
} from '@/components/hooks/api/useTeams';
import { limitNameWithLength } from '@/utils/helper/limitNameWithLength';
import { handleApiError } from '@/utils/helper';

interface ITeam {
  name: string;
  id: string;
}
interface IDeleteAssignTeamModal {
  team: ITeam;
  userId: string;
  teams: ITeam[] | undefined;
  setSelectedTeam: React.Dispatch<React.SetStateAction<string[]>>;
}

function DeleteAssignTeamModal({
  team,
  userId,
  setSelectedTeam,
}: IDeleteAssignTeamModal) {
  const queryClient = useQueryClient();
  const scrollToTop = useScrollToTop();
  const { showAlert } = useAlert();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate, isPending } = useDeleteMemberOnTeam();

  const handleRemoveTeam = (teamId: string) => {
    mutate(
      {
        id: teamId ? teamId : '',
        data: {
          userIds: [userId],
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [TANSTACK_QUERY_KEY.USER],
          });
          queryClient.invalidateQueries({
            queryKey: [TANSTACK_QUERY_KEY.USER_DETAIL],
          });
          setSelectedTeam((prev) => prev.filter((item) => item !== teamId));
          showAlert(
            'The team has been successfully removed from the user.',
            'success',
          );
        },
        onError: (error) => {
          showAlert(handleApiError(error), 'error');
        },
        onSettled: () => {
          scrollToTop();
          setShowDeleteModal(false);
        },
      },
    );
  };

  return (
    <Modal
      showModal={showDeleteModal}
      setShowModal={setShowDeleteModal}
    >
      <Modal.Toggle
        intent='tertiary'
        variant='gray'
        className='p-0'
      >
        <IconsComponent
          size='xs'
          icon='reg-close'
          fill='#111827'
        />
      </Modal.Toggle>
      <Modal.Content className='max-w-[920px]'>
        <Modal.Title>
          <h6>Remove from team</h6>
        </Modal.Title>
        <Modal.Body>
          <p className='text-base font-normal normal-case text-gray-800'>
            Are you sure you want to remove{' '}
            <span className='font-bold'>{team.name}</span> from this user?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            disabled={isPending}
            onClick={() => handleRemoveTeam(team.id)}
          >
            Confirm Remove
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export function AssignTeam({
  teams,
  isInvited,
  userId,
}: {
  userId: string;
  isInvited?: boolean;
  teams: { name: string; id: string }[] | undefined;
}) {
  const queryClient = useQueryClient();
  const scrollToTop = useScrollToTop();
  const { showAlert } = useAlert();

  const [searchQuery, setSearchQuery] = useState('');

  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const { query, setSearch } = useGetAllTeamsList();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchQuery); // Update search query
    }, 300);

    return () => clearTimeout(delayDebounceFn); // Cleanup debounce
  }, [searchQuery, setSearch]);

  const {
    isLoading,
    isError,
    data: TeamOptions,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = query;

  const { mutate, isPending, isSuccess } = useUpdateAssignTeam(userId);

  const defaultSelectedTeam = teams?.map((team) => team.id) ?? [];

  const [selectedTeam, setSelectedTeam] =
    useState<string[]>(defaultSelectedTeam);

  /**
   * @description Filter team options based on search query and selected team
   */
  const teamOptions = useMemo(() => {
    if (!TeamOptions?.pages) return []; // Return an empty array if TeamOptions or its pages are undefined

    return TeamOptions.pages
      .flat()
      .filter((team) =>
        team.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .filter(
        (team) =>
          !teams?.some((selectedTeams) => selectedTeams.id === team._id),
      );
  }, [searchQuery, TeamOptions, isSuccess, teams]);

  // This useEffect is implemented to handle pagination when the number of selected teams exceeds 5.
  // When the `selectedTeam` array length surpasses 5, we fetch the next page of data to ensure additional teams can be selected.
  useEffect(() => {
    if (selectedTeam.length > 5) {
      fetchNextPage();
    }
  }, [selectedTeam]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const handleUpdateAssignTeam = () => {
    const updateTeam = TeamOptions?.pages
      .flat()
      .filter((team) => selectedTeam.includes(team._id))
      .map((team) => team._id);

    mutate(
      { teams: updateTeam },
      {
        onSuccess: (response) => {
          queryClient.invalidateQueries({
            queryKey: [TANSTACK_QUERY_KEY.USER],
          });
          queryClient.invalidateQueries({
            queryKey: [TANSTACK_QUERY_KEY.USER_DETAIL],
          });
          showAlert(response.message, 'success');
        },
        onError: (error) => {
          showAlert(error.message, 'error');
        },
        onSettled: () => {
          setIsDropDownVisible(false);
          scrollToTop();
        },
      },
    );
  };

  return (
    <FormGroup
      title='Assign to Team'
      description='Add user to the team. A user can be assigned to multiple teams.'
    >
      <div className='space-y-5'>
        <div>
          <Button
            size='xs'
            variant='blue'
            className={!isInvited ? 'px-2' : 'px-0'}
            intent='tertiary'
            onClick={() => setIsDropDownVisible((prev) => !prev)}
            disabled={!isInvited}
          >
            <IconsComponent
              size='md'
              icon='reg-add'
              fill='#2563eb'
            />
            Add Teams
          </Button>
          <Dropdown
            isVisbile={isDropDownVisible}
            setIsVisible={setIsDropDownVisible}
          >
            <Dropdown.Content className='w-60 p-0'>
              <div className='p-2'>
                <SearchInput
                  placeholder='Search teams'
                  initialState
                  className='w-full bg-transparent hover:shadow-none focus:border-0 focus:shadow-none'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <hr />
              {isLoading ? (
                <DropDownLoadingSkeleton />
              ) : isError ? (
                <div className='px-2 py-4 text-center font-medium text-red-500'>
                  Error! Please try again
                </div>
              ) : (
                <div
                  className='max-h-36 overflow-y-auto p-2'
                  onScroll={handleScroll}
                >
                  {teamOptions.length > 0 ? (
                    teamOptions.map((team) => (
                      <FilterCheckboxOption
                        name='team-name'
                        label={limitNameWithLength(team.name, 25)}
                        value={team._id}
                        key={team._id}
                        selectedOptions={selectedTeam}
                        setSelectedOptions={setSelectedTeam}
                      />
                    ))
                  ) : (
                    <p className='p-2 text-center text-sm font-normal text-gray-400'>
                      No team found
                    </p>
                  )}
                  {isFetchingNextPage && <DropDownLoadingSkeleton />}
                </div>
              )}
              <hr />
              <div className='p-2'>
                <Button
                  size='xs'
                  className='w-full'
                  onClick={handleUpdateAssignTeam}
                  disabled={isPending || selectedTeam.length === teams?.length}
                >
                  Assign Team
                </Button>
              </div>
            </Dropdown.Content>
          </Dropdown>
        </div>
        <div className='space-y-3'>
          <p className='text-base font-semibold text-gray-800'>
            Current Teams ({teams?.length})
          </p>
          <div className='flex flex-wrap gap-2'>
            {teams?.map((team) => (
              <Badge
                key={team.id}
                className='rounded-full border border-gray-200 bg-background px-5 py-2 text-sm font-semibold'
              >
                {limitNameWithLength(team.name, 25)}

                <DeleteAssignTeamModal
                  team={team}
                  teams={teams}
                  userId={userId}
                  setSelectedTeam={setSelectedTeam}
                />
              </Badge>
            ))}
          </div>
        </div>
      </div>
    </FormGroup>
  );
}
