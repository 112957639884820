import { useAlert, useTable } from '@/components/hooks';
import {
  Alert,
  DataTable,
  Pagination,
  RealTimeStockSkeleton,
} from '@/components';
import { ActionBar } from '@/pages/real-time-stock/ActionBar';
import {
  RealTimeStockProductList,
  useRealTimeStockColumn,
} from '@/pages/real-time-stock/RealTimeStockColumn';
import {
  useGetCategoriesFilterOptions,
  useGetRealTimeStockList,
} from '@/components/hooks/api/stock';
import { ErrorFound } from '@/components/ui/no-result/Error';
import { TProductFilterSchema } from '@/schemas/api/products/product-filter.schema';
import { FilterForm } from './FilterForm';
import { createContext } from 'react';

interface RealTimeStockTableProps {
  filterable: TProductFilterSchema | null;
  totalCount: number;
  totalPages: number;
  productList: RealTimeStockProductList[];
}

export const TableFilterContext = createContext<TProductFilterSchema | null>(
  null,
);

const RealTimeStockTable = ({
  filterable,
  productList,
  totalCount,
  totalPages,
}: RealTimeStockTableProps) => {
  const { columns } = useRealTimeStockColumn();
  const { table } = useTable(columns, productList, 'id');
  return (
    <>
      <TableFilterContext.Provider value={filterable}>
        <FilterForm />
      </TableFilterContext.Provider>
      <DataTable
        table={table}
        columns={columns}
      />
      <Pagination
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </>
  );
};

const RealTimeStock = () => {
  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert();
  const { data: realTimeStock, isPending, isError } = useGetRealTimeStockList();
  useGetCategoriesFilterOptions();

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mb-4'
          title={alertMessage}
        />
      )}
      <ActionBar />
      {(() => {
        switch (true) {
          case isPending:
            return <RealTimeStockSkeleton />;
          case isError:
            return <ErrorFound />;
          default:
            return (
              <RealTimeStockTable
                filterable={realTimeStock.filterable}
                productList={realTimeStock.productList}
                totalCount={realTimeStock.totalProducts}
                totalPages={realTimeStock.totalPages}
              />
            );
        }
      })()}
    </>
  );
};
export default RealTimeStock;
