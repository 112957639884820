import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { ErrorFound } from '@/components/ui/no-result/Error';
import { Notfound } from '@/components/ui/no-result/NotFound';
import { PromotionCardSkeletion } from '@/components/ui/skeleton/PromotionCardSkeleton';
import { PromotionCard } from '@/pages/marketing-resources/flash-promotions/PromotionCard';
import { useGetPromotionInfiniteList } from '@/components/hooks/api/marketing-resource/usePromotion';
import { QUERY_CONSTANTS, ROUTES } from '@/utils/constants';
import { STATUS } from '@/utils/constants/promotion.constant';
import { useQueryParamSearch } from '@/components/hooks';

export function FlashPromotion() {
  const { ref, inView } = useInView();
  const searchParams = useQueryParamSearch(QUERY_CONSTANTS.STATUS);

  const status = searchParams ?? STATUS.ACTIVE;

  const isExpired = status === STATUS.INACTIVE.toString();

  const { data, isError, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetPromotionInfiniteList();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (isLoading) return <PromotionCardSkeletion />;

  if (isError) return <ErrorFound />;

  if (data?.pages.every((page) => page.data.length === 0))
    return (
      <Notfound
        description='It seems there are no promotions currently available.'
        navigationUrl={`/${ROUTES.MARKETING_RESOURCES.FLASH_PROMOTIONS}?status=${isExpired ? STATUS.ACTIVE : STATUS.INACTIVE}`}
      />
    );

  return (
    <div className='grid gap-6 sm:grid-cols-2 lg:grid-cols-1'>
      {data?.pages.map((page) => (
        <Fragment key={page.meta.pagination.page}>
          {page.data.map((promotion) => (
            <PromotionCard
              key={promotion.id}
              promotion={promotion}
            />
          ))}
        </Fragment>
      ))}

      <div ref={ref}>
        {isFetchingNextPage && <PromotionCardSkeletion length={1} />}
      </div>
    </div>
  );
}
