import {
  ShoeSizeFilter,
  IndustryFilter,
  ShoeTechnology,
  ShoeColorFilter,
  PriceRangeFilter,
  AvailableStocksFilter,
  useIsProductFiltersSelected,
  SelectedFilterItemsByCategory,
} from './index';
import { useProductFilterStore } from '@/store';
import { QUERY_CONSTANTS } from '@/utils/constants';
import { Button, IconsComponent } from '@/components';
import { EmptyMessage } from '@/components/ui/empty-message';
import { useGetAllProducts, useURLSearchParams } from '@/components/hooks';

const FilterSidebarHeader = () => {
  const { resetAllProductFilters } = useProductFilterStore();
  const isProductFiltersSelected = useIsProductFiltersSelected();
  const { searchValue, setSearchParams } = useURLSearchParams(
    QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.SUB_CATEGORY,
  );

  const { isError: getAllProductsError } = useGetAllProducts();

  const handleClearAll = () => {
    setSearchParams(
      searchValue
        ? { [QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.SUB_CATEGORY]: searchValue }
        : {},
    );
    resetAllProductFilters();
  };

  return (
    <section
      className='flex flex-col'
      data-cy='filters-menu'
    >
      <div className='flex h-[60px] items-center justify-between'>
        <div className='flex w-full gap-2'>
          <IconsComponent icon='reg-filter' />
          <p className='text-xl font-semibold text-gray-800'>Filters</p>
        </div>
        <Button
          intent={'tertiary'}
          variant={isProductFiltersSelected ? 'blue' : 'gray'}
          className='px-3 text-sm'
          onClick={handleClearAll}
          disabled={!isProductFiltersSelected}
        >
          Clear All
        </Button>
      </div>
      {getAllProductsError ? (
        <EmptyMessage
          message='Selected filters not available'
          className='py-4'
          variant='400'
        />
      ) : (
        <SelectedFilterItemsByCategory />
      )}
      <hr />
    </section>
  );
};

const FilterSidebar = ({
  animationClass,
  handleSubmit,
}: {
  animationClass: string;
  handleSubmit: () => void;
}) => {
  return (
    <div
      className='pb-4'
      data-cy='filter-sidebar'
    >
      <FilterSidebarHeader />
      <div className={animationClass}>
        <PriceRangeFilter />
        <ShoeSizeFilter />
        <ShoeColorFilter />
        <AvailableStocksFilter />
        <IndustryFilter />
        <ShoeTechnology />
        <hr />
        <Button
          intent={'solid'}
          variant={'primary'}
          className='my-4 w-full'
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
            e.stopPropagation();
          }}
          disabled={!useIsProductFiltersSelected()}
        >
          Apply All
        </Button>
      </div>
    </div>
  );
};

export default FilterSidebar;
