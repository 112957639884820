import { z } from 'zod';

export const PaymentRequestSchema = z.object({
  purchaseOrderNumber: z.string(),
  expectedDeliveryDate: z.string(),
});

export const PaymentResponseSchema = z.object({
  message: z.string(),
  data: z.union([
    z.string(),
    z.object({
      checkoutUrl: z.string().optional(),
    }),
  ]),
  timestamp: z.string(),
});

export const PaymentConfirmRequestSchema = z.object({
  sessionId: z.string(),
});

export type TPaymentConfirmRequest = z.infer<
  typeof PaymentConfirmRequestSchema
>;

export const PaymentConfirmResponseSchema = z.object({
  message: z.string(),
  data: z.string(),
  timestamp: z.string(),
});
