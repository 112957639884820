import React, { useEffect, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import useCartStore from '@/store/cart.store';
import { Badge } from '@/components/ui/badge/Badge';
import { useToggleState } from '@/components/hooks';
import { Button } from '@/components/ui/button/Button';
import { isEmpty } from '@/utils/helper/general.helper';
import { Tooltip } from '@/components/ui/tooltip/ToolTip';
import IconsComponent from '@/components/ui/icons/IconsComponent';
import { QUERY_CONSTANTS } from '@/utils/constants/query.constants';
import { NumberInput } from '@/components/ui/input-field/NumberInput';
import { AppKeyConstants } from '@/utils/constants/app.key.constants';
import useSingleProductCartStore from '@/store/single-product-cart.store';
import { productPriceForDrawer } from '@/utils/helper/product/product-price';
import { CartDeleteModal } from '@/pages/cart/cart-components/CartDeleteModal';
import { useGetUserOrderSessionId } from '@/components/hooks/api/orders/useGetUserOrderSessionId';
import { TSingleOrderDetailItemVariantSchema } from '@/schemas/api/orders/single-order-detail.schema';
import { useDeleteProductVariantFromOrder } from '@/components/hooks/api/orders/useDeleteProductVariantFromOrder';

interface QuantityColumnProps
  extends CellContext<TSingleOrderDetailItemVariantSchema, unknown> {
  productId?: string;
}

function QuantityColumn(props: QuantityColumnProps) {
  const { productId, ...info } = props;
  const { id: shoeSizeId, variantId, product } = info.row.original;

  const {
    setSizeQuantityValues,
    setIsProductQuantityDirty,
    isProductQuantityDirty,
    sizeQuantityValues,
  } = useSingleProductCartStore();

  const { cartTableData, setCartTableData, setIsCartProductQuantityDirty } =
    useCartStore();

  const {
    state: deleteProductVariantModal,
    setState: setDeleteProductVariantModal,
    toggleState: toggleDeleteProductVariantModal,
  } = useToggleState();

  const {
    mutate: handleDeleteProductVariantFormOrderById,
    isPending: handleRemoveProductVariantFormOrderByIdLoading,
  } = useDeleteProductVariantFromOrder();
  const { mutate: getUserOrderSessionId } = useGetUserOrderSessionId();

  const productStockValue = sizeQuantityValues.find(
    (size) => size.shoeSizeId === shoeSizeId,
  )?.stock;

  const isPathCheckout = () =>
    window.location.pathname.includes(QUERY_CONSTANTS.CHECKOUT);

  const renderQuantityOptionButtons = () => {
    return (
      window.location.pathname.split('/')[1] ===
        (AppKeyConstants.CART as string) &&
      !isEmpty(productId) &&
      !window.location.pathname.includes(QUERY_CONSTANTS.CHECKOUT)
    );
  };

  const filterProductDataById = (productIdToFilterBy: string) => {
    if (productIdToFilterBy) {
      const currentProductData = cartTableData[productIdToFilterBy];

      const currentProductDataSizeQuantityValues = currentProductData.find(
        (item) => item.variantId === variantId,
      );

      const isCurrentEditedTableRow =
        currentProductDataSizeQuantityValues &&
        currentProductDataSizeQuantityValues.productId === productIdToFilterBy;

      return {
        currentProductDataSizeQuantityValues,
        isCurrentEditedTableRow,
        currentProductData,
      };
    }

    return {};
  };

  const updateQuantityRowData = (
    value: number | null,
    shoeVariantId: string,
  ) => {
    const updatedSizeQuantityValues = sizeQuantityValues.map((item) => {
      if (item.shoeSizeId === shoeVariantId) {
        return {
          ...item,
          stock: value ?? null,
        };
      }
      return item;
    });

    return setSizeQuantityValues(updatedSizeQuantityValues);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    shoeVariantId?: string,
    productIdToFilterBy?: string,
  ) => {
    const sanitizedInput = event.target.value;
    const value = sanitizedInput === '' ? null : Number(sanitizedInput);

    if (renderQuantityOptionButtons() && productIdToFilterBy) {
      const { currentProductDataSizeQuantityValues, isCurrentEditedTableRow } =
        filterProductDataById(productIdToFilterBy);

      if (isCurrentEditedTableRow && currentProductDataSizeQuantityValues) {
        const priceToUse = productPriceForDrawer(product?.prices, value ?? 0);
        currentProductDataSizeQuantityValues.quantity = value ?? 0;
        currentProductDataSizeQuantityValues.salePrice = String(priceToUse);

        setCartTableData(cartTableData);
        setIsCartProductQuantityDirty(true);
      } else {
        console.error('Product data not found or not an editable row.');
      }
    } else {
      if (!isProductQuantityDirty) {
        setIsProductQuantityDirty(true);
      }
      updateQuantityRowData(value ?? null, shoeVariantId!);
    }
  };

  const handleCartItemResetByShoeSize = (
    shoeId: string,
    productIdToFilterBy?: string | undefined,
  ) => {
    if (productIdToFilterBy) {
      const { currentProductDataSizeQuantityValues, isCurrentEditedTableRow } =
        filterProductDataById(productIdToFilterBy);

      if (isCurrentEditedTableRow && currentProductDataSizeQuantityValues) {
        currentProductDataSizeQuantityValues.quantity = 1;
        updateQuantityRowData(1, shoeId);
        setCartTableData(cartTableData);
        setIsCartProductQuantityDirty(true);
      } else {
        console.error('Product data not found or not an editable row.');
      }
    }
  };

  const handleCartItemDeleteByShoeSize = (
    productIdToFilterBy?: string | undefined,
  ) => {
    if (productIdToFilterBy) {
      const {
        currentProductDataSizeQuantityValues,
        isCurrentEditedTableRow,
        currentProductData,
      } = filterProductDataById(productIdToFilterBy);

      if (
        isCurrentEditedTableRow &&
        currentProductDataSizeQuantityValues?.variantId
      ) {
        getUserOrderSessionId(undefined, {
          onSuccess: (response) => {
            handleDeleteProductVariantFormOrderById(
              {
                sessionId: response.data.id,
                deleteProductVaraintPayload: {
                  productId: productIdToFilterBy,
                  variantId:
                    currentProductDataSizeQuantityValues.variantId ?? '',
                },
              },
              {
                onSuccess: () => {
                  const updatedProductData = currentProductData.filter(
                    (currentProduct) =>
                      currentProduct.id !== info.row.original.id,
                  );

                  cartTableData[productIdToFilterBy] = updatedProductData;
                  setCartTableData(cartTableData);
                  setIsCartProductQuantityDirty(true);
                  toggleDeleteProductVariantModal();
                },
              },
            );
          },
        });
      } else {
        console.error('Product data not found or not an editable row.');
      }
    }
  };

  //syncs drawer data for single product drawer
  useEffect(() => {
    if (!isEmpty(info.table.options.data)) {
      if (renderQuantityOptionButtons()) return;

      setSizeQuantityValues(
        info.table.options.data.map(({ id, size, cart: productCartData }) => ({
          shoeSizeId: id ?? '',
          size: Number(size ?? 0),
          stock: productCartData?.quantity ?? 0,
        })),
      );
    }
  }, [info.table.options.data]);

  return (
    <div className='flex items-center gap-2'>
      <Tooltip>
        <Tooltip.Trigger>
          <NumberInput
            disabled={isPathCheckout()}
            name='stock'
            className='min-w-12'
            value={
              productId
                ? (info.row.original.quantity ?? '')
                : (productStockValue ?? undefined)
            }
            placeholder={'Qty'}
            min={0}
            onChange={
              productId
                ? (event) =>
                    handleQuantityChange(
                      event,
                      shoeSizeId ?? '',
                      productId.toString(),
                    )
                : (event) => handleQuantityChange(event, shoeSizeId ?? '')
            }
          />
        </Tooltip.Trigger>

        {(sizeQuantityValues.find((size) => size.shoeSizeId === shoeSizeId)
          ?.stock ?? 0) < 0 && (
          <p className='mt-2 text-red-600'> Quantity cannot be negative</p>
        )}

        {(sizeQuantityValues.find((size) => size.shoeSizeId === shoeSizeId)
          ?.stock ?? 0) %
          1 !==
          0 && (
          <p className='mt-2 text-red-600'>
            Quantity cannot be a decimal number
          </p>
        )}

        {info.row.original.stock === 0 && (
          <Tooltip.Content
            position={info.row.index === 0 ? 'bottom' : 'left'}
            intent='focus'
          >
            This item is currently out of stock. Adding it to your cart will
            place on a back order. We&apos;ll fulfill your order as soon as
            it&apos;s restocked.
          </Tooltip.Content>
        )}
      </Tooltip>
      {renderQuantityOptionButtons() && (
        <>
          <Button
            intent={'tertiary'}
            className='ml-1 p-0'
            onClick={() => {
              handleCartItemResetByShoeSize(
                shoeSizeId ?? '',
                productId?.toString() ?? '',
              );
            }}
          >
            <IconsComponent
              icon='reg-reset'
              size='sm'
              fill='#4B5563'
            />
          </Button>
          <Button
            intent={'tertiary'}
            className='p-0'
            onClick={toggleDeleteProductVariantModal}
          >
            <IconsComponent
              icon='reg-trash'
              size='sm'
              fill='#4B5563'
            />
          </Button>

          <CartDeleteModal
            showDeleteModal={deleteProductVariantModal}
            setshowDeleteModal={(value) => setDeleteProductVariantModal(value)}
            title='Remove Product Variant'
            message={`Are you sure you want to remove product size ${product?.variants?.find((variant) => variant.id === variantId)?.size}?`}
            primaryButtonActionMessage='Remove Product Variant'
            primaryButtonAction={() =>
              handleCartItemDeleteByShoeSize(productId?.toString() ?? '')
            }
            isLoading={handleRemoveProductVariantFormOrderByIdLoading}
          />
        </>
      )}
    </div>
  );
}

function StockStatusColumn(
  info: CellContext<TSingleOrderDetailItemVariantSchema, unknown>,
) {
  const stockStatus = info.row.original.stock;

  if (!isEmpty(stockStatus)) {
    return <Badge variant='green'>In Stock</Badge>;
  } else if (stockStatus === 0) {
    return <Badge variant='red'>Out of Stock</Badge>;
  } else {
    return 'N/A';
  }
}

export function useProductVariantColumn() {
  const columns = useMemo<ColumnDef<TSingleOrderDetailItemVariantSchema>[]>(
    () => [
      {
        accessorKey: 'size',
        header: 'Size (UK)',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'stockStatus',
        header: 'Stock Status',
        enableSorting: false,
        cell: (info) => <StockStatusColumn {...info} />,
      },
      {
        accessorKey: 'stock',
        header: 'Available Stocks',
        enableSorting: false,
        cell: (info) => info.getValue() ?? 'N/A',
      },
      {
        accessorKey: 'leadTime',
        header: 'Lead Time',
        cell: (info) => info.getValue() ?? 'N/A',
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        cell: (info) => <QuantityColumn {...info} />,
      },
    ],
    [],
  );
  return { columns };
}

export function useCartProductVariantColumn(productId: string) {
  const columns = useMemo<ColumnDef<TSingleOrderDetailItemVariantSchema>[]>(
    () => [
      {
        accessorKey: 'size',
        header: 'Size (UK)',
        cell: (info) => {
          const infoRowData = info.row.original;

          return (
            info.getValue() ??
            infoRowData.product?.variants?.find(
              (variant) => variant.id === infoRowData.variantId,
            )?.size
          );
        },
      },
      {
        accessorKey: 'leadTime',
        header: 'Lead Time',
        cell: (info) => info.getValue() ?? 'N/A',
      },
      {
        accessorKey: 'stockStatus',
        header: 'Stock Status',
        enableSorting: false,
        cell: (info) => <StockStatusColumn {...info} />,
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        cell: (info) => (
          <QuantityColumn
            productId={productId}
            {...info}
          />
        ),
      },
    ],
    [],
  );
  return { columns };
}
