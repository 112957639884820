import { useUserStore } from '@/store';
import { V12CurrencySymbols } from '../constants/currency-helper';

/**
 *
 * This function retrieves the company's default currency settings from store
 * and formats the provided price with the currency unit.
 *
 * @param price - The price to be formatted.
 * @returns A string representing the formatted price with the company's default currency.
 */

const getCurrencySymbol = () => {
  const companySettings = useUserStore.getState().systemDefault;

  const currencySymbol = companySettings
    ? V12CurrencySymbols[
        companySettings.currency as keyof typeof V12CurrencySymbols
      ]
    : '';
  return currencySymbol;
};

const formatCompanyPrice = (price: number) => {
  const currencySymbol = getCurrencySymbol();

  return `${currencySymbol}${price.toFixed(2)}`;
};

export { formatCompanyPrice, getCurrencySymbol };
