import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import {
  CreateAccountNewPasswordFormSchema,
  TCreateNewPasswordForm,
  TCreateNewPasswordFormSchema,
} from '@/schemas';
import {
  useAlert,
  useResetNewPassword,
  useUserInviteVerifyToken,
} from '@/components/hooks';
import { handleApiError } from '@/utils/helper';
import { ROUTES } from '@/utils/constants';
import { IconSpinner } from '@/components/ui/icons/regular/spinner';
import {
  Alert,
  Button,
  Input,
  InvitationLoadingState,
  Label,
  PasswordInput,
} from '@/components';
import {
  AccountCreated,
  TokenExpired,
} from '@/utils/constants/alert-message.constant';

export interface ErrorResponse {
  data?: {
    message?: string;
  };
}

export default function CreateUserNewPasswordPage() {
  const createNewPasswordMethods = useForm<TCreateNewPasswordForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(CreateAccountNewPasswordFormSchema),
  });

  const navigate = useNavigate();
  useAlert();
  const { isAlertVisible, isAlertDismissed, alertMessage, isType, showAlert } =
    useAlert();
  const { userId, token } = useParams<{
    userId: string;
    token: string;
  }>();

  const { handleSubmit, setValue } = createNewPasswordMethods;

  const {
    mutate: verifyToken,
    isPending,
    isSuccess,
  } = useUserInviteVerifyToken();

  useEffect(() => {
    // Trigger token verification when the component loads
    if (userId && token) {
      const data = {
        id: userId,
        token: token,
      };

      verifyToken(data, {
        onSuccess: ({ data: { email } }) => {
          setValue('email', email);
        },
        onError: () => {
          navigate(`/${ROUTES.LOGIN}`);
          setTimeout(() => {
            showAlert(TokenExpired, 'error');
          }, 150);
        },
      });
    }
  }, [userId, token]);

  const {
    isPending: isNewPasswordSubmitLoading,
    mutate: handleSetNewPasssord,
  } = useResetNewPassword();

  const handleCreateNewPasswordSubmit: SubmitHandler<
    TCreateNewPasswordFormSchema
  > = (data) => {
    const { password } = data;
    const setData = {
      password,
      id: userId ?? '',
      token: token ?? '',
    };
    handleSetNewPasssord(setData, {
      onSuccess: () => {
        navigate(`/${ROUTES.LOGIN}`);
        setTimeout(() => {
          showAlert(AccountCreated, 'success');
        }, 150);
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {},
    });
  };

  if (isPending) {
    return (
      <div className='flex h-screen items-center justify-center'>
        {/* Full screen loading */}
        <InvitationLoadingState />
      </div>
    );
  }

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mt-4 mb-1.5'
          title={alertMessage}
        />
      )}{' '}
      {isSuccess && (
        <div className='flex min-h-[calc(100vh_-_76px)] justify-center pt-20'>
          <div className='flex w-full flex-col p-2 sm:max-w-[496px] md:p-10'>
            <h4>Create password</h4>
            <div>Enter a new password to continue to create account.</div>
            <FormProvider {...createNewPasswordMethods}>
              <form onSubmit={handleSubmit(handleCreateNewPasswordSubmit)}>
                <div className='mt-10 flex flex-col gap-4'>
                  <div>
                    <Label htmlFor='email'>Email</Label>
                    <Input
                      type='email'
                      name='email'
                      disabled
                      placeholder='Enter email address'
                    />
                  </div>
                  <div>
                    <Label htmlFor='password'> Password</Label>
                    <PasswordInput<TCreateNewPasswordForm>
                      name='password'
                      errorFieldName='password'
                      placeholder='Enter your password'
                    />
                  </div>
                  <div>
                    <Label htmlFor='confirmPassword'> Confirm Password</Label>
                    <PasswordInput<TCreateNewPasswordForm>
                      name='confirmPassword'
                      errorFieldName='confirmPassword'
                      placeholder='Confirm your password'
                      showPasswordTooltip={false}
                    />
                  </div>
                </div>
                <Button
                  className='mt-6 w-full'
                  type='submit'
                  disabled={isNewPasswordSubmitLoading}
                >
                  {isNewPasswordSubmitLoading && (
                    <IconSpinner
                      fill='white'
                      size={24}
                    />
                  )}
                  Create Account
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      )}
    </>
  );
}
