import { Link } from 'react-router-dom';

import { LOGO, ROUTES, V12_OLD_DOMAIN } from '@/utils/constants';
import { IconsComponent, ImageComponent } from '@/components';

const RestricedRoutesHeader = () => {
  const headerNavItemClass = 'flex gap-1 text-sm font-semibold text-gray-600';

  return (
    <div
      className='relative z-10 flex h-[76px] items-center justify-center border-b-2 border-gray-200 bg-white px-6 2lg:px-48'
      data-cy='header-not-logged-in'
    >
      <Link to={`/${ROUTES.LOGIN}`}>
        <ImageComponent
          src={LOGO.BLACK_REG}
          alt='V12 Distributor Logo'
          width={77}
          height={44}
        />
      </Link>
      <div className='text-base font-medium'> &nbsp;/&nbsp; Distributor</div>

      <div className='ml-auto hidden gap-6 sm:flex'>
        <div className={headerNavItemClass}>
          <IconsComponent
            icon='reg-question'
            size='md'
            fill='#4b5563'
          />

          <span>Need Help?</span>
        </div>
        <div className={`${headerNavItemClass} cursor-pointer`}>
          <Link
            to={V12_OLD_DOMAIN}
            target={'_blank'}
          >
            Visit our website
          </Link>
          <IconsComponent
            icon='reg-external-link'
            size='sm'
            fill='#4b5563'
          />
        </div>
      </div>
    </div>
  );
};

export default RestricedRoutesHeader;
