import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import {
  scrollToTop,
  useIsDeviceTabletOrLarger,
  useOutsideClick,
} from '@/components/hooks';
import { cn } from '@/utils/cn';
import { useSidebarStore } from '@/store';
import { Button, ImageComponent } from '@/components';
import IconsComponent, { icons } from '../icons/IconsComponent';
import useCompareProductsStore from '@/store/compare-products.store';
import { LogoutModal } from '@/pages/authentication/logout/LogoutModal';
import { AppKeyConstants, ROUTES, V12_OLD_DOMAIN } from '@/utils/constants';

interface MenuItem {
  name: string;
  link: string;
  icons?: string;
  children?: MenuItem[];
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

interface SideBarMenuProps {
  classes?: string;
  expandSideBar: boolean;
  sidebarMenus: MenuSection[];
  imageSrc?: string;
}

interface SideBarItemProps {
  item: MenuItem;
  expandSideBar: boolean;
  activeMenu: string | null;
  setActiveMenu: (menuName: string | null) => void;
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  item,
  expandSideBar,
  activeMenu,
  setActiveMenu,
}) => {
  const location = useLocation();
  const {
    showCompareProductsModal,
    toggleShowCompareProductsModal: toggleCompareProducts,
  } = useCompareProductsStore();
  const { setIsSidebarVisible } = useSidebarStore();

  const [isChildActive, setIsChildActive] = useState(false);

  const isMenuActive = activeMenu === item.name;

  const isPathCartOrCheckout = () => {
    return (
      window.location.pathname.includes(AppKeyConstants.CART) ||
      window.location.pathname.includes(AppKeyConstants.CHECKOUT)
    );
  };

  useEffect(() => {
    const childActive = item.children?.some((child) =>
      location.pathname.includes(child.link),
    );

    setIsChildActive(!!childActive);

    if (location.pathname.includes(item.link) || childActive) {
      setActiveMenu(item.name);
    }
  }, [location.pathname, item.children, item.link, setActiveMenu]);

  return (
    <li className='menu-item relative px-6'>
      <NavLink
        to={item.link}
        onClick={(e) => {
          e.stopPropagation();
          scrollToTop(false); // Use instant scrolling for route changes
          if (item.children) {
            setActiveMenu(isMenuActive ? null : item.name);
          }
          if (showCompareProductsModal) {
            toggleCompareProducts();
          }
          setIsSidebarVisible(false);
        }}
        className={({ isActive }) =>
          cn(
            'flex items-center justify-start gap-2 rounded-sm p-3 hover:bg-gray-900',
            (isActive ||
              isChildActive ||
              (item.name === 'Products' && isPathCartOrCheckout())) &&
              'bg-gray-900',
          )
        }
      >
        <span className='icon-wrap'>
          <IconsComponent
            icon={item.icons as keyof typeof icons}
            size='md'
            fill='#ffffff'
          />
        </span>
        <span
          className={`text-gray-300 transition-all duration-300 ease-in-out ${expandSideBar ? 'text-base opacity-100' : 'text-[0px] opacity-0'}`}
        >
          {item.name}
        </span>
      </NavLink>
      {item.children && expandSideBar && (
        <>
          <button
            className={`toggle flex h-4 w-4 items-center justify-center ${
              isMenuActive ? 'rotate-0' : 'rotate-180'
            } absolute right-8 top-4`}
            onClick={() => {
              setIsSidebarVisible(false);
              setActiveMenu(isMenuActive ? null : item.name);
            }}
          >
            <IconsComponent
              fill='#D1D5DB'
              icon='reg-chevron-up'
              size='xs'
            />
          </button>
          <ul
            className={`${
              isMenuActive ? 'open-true max-h-[1000px]' : 'open-false max-h-0'
            } overflow-hidden duration-300 ease-in-out`}
          >
            {item.children.map((child) => (
              <li
                key={child.name}
                className='submenu-item mt-3 pl-10'
              >
                <NavLink
                  to={child.link}
                  className={({ isActive }) =>
                    cn(
                      'text-4',
                      isActive ||
                        (child.name === 'All Products' &&
                          isPathCartOrCheckout())
                        ? 'text-gray-300'
                        : 'text-gray-400',
                    )
                  }
                  onClick={() => {
                    scrollToTop(false); // Use instant scrolling for route changes
                    setIsSidebarVisible(false);
                  }}
                >
                  {child.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

const SideBarMenu: React.FC<SideBarMenuProps> = ({
  classes,
  expandSideBar,
  sidebarMenus,
  imageSrc,
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { setIsSidebarVisible, isSidebarVisible } = useSidebarStore();
  const [showModal, setShowModal] = useState(false);

  const isTabletOrLarger: boolean = useIsDeviceTabletOrLarger();

  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  useOutsideClick(sidebarRef, () => {
    if (!isTabletOrLarger) {
      setIsSidebarVisible(false);
    }
  });

  const sidebarResponsiveClass = () => {
    if (isTabletOrLarger) {
      return expandSideBar ? 'left-0' : 'left-0 w-[96px]';
    } else if (isSidebarVisible) {
      return expandSideBar ? 'left-0' : 'left-0 w-[96px]';
    } else {
      return '!left-[-100%]';
    }
  };
  return (
    <aside
      className={cn(
        'sidebar-menu fixed bottom-0 top-0 z-100 min-h-screen w-[288px] overflow-y-auto bg-gray-950 py-6 text-white transition-all duration-200 ease-in-out',
        classes ?? '',
        sidebarResponsiveClass(),
      )}
      ref={sidebarRef}
      data-cy={`sidebar, sidebar is ${!expandSideBar ? 'collapsed' : 'not collapsed'}`}
    >
      <div className='grid h-full grid-cols-1 place-content-between'>
        <div>
          <div className='logo mb-5 px-6'>
            <Link to={`/${ROUTES.OVERVIEW}`}>
              <ImageComponent
                src={imageSrc ?? '../src/assets/images/placeholder-image.png'}
                width={expandSideBar ? 84 : 48}
                classes='transition duration-700 ease-in-out'
              />
            </Link>
          </div>
          {sidebarMenus.map((section) => (
            <div
              key={section.title}
              className='menu-section mb-6'
            >
              {expandSideBar ? (
                <h3 className='mb-3 px-6 text-xs font-semibold uppercase text-gray-500'>
                  {section.title}
                </h3>
              ) : (
                ''
              )}
              <ul className='flex list-none flex-col gap-2'>
                {section.items.map((item) => (
                  <SideBarItem
                    item={item}
                    key={item.name}
                    expandSideBar={expandSideBar}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                  />
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div>
          <ul className='list-none'>
            <li className={'menu-item relative px-6'}>
              <Link
                to={V12_OLD_DOMAIN}
                target={'_blank'}
                className='flex items-center justify-start gap-2 p-3 hover:bg-gray-900'
              >
                <span className='icon-wrap'>
                  <IconsComponent
                    icon='fill-website'
                    size='md'
                    fill='#ffffff'
                  />
                </span>
                {expandSideBar ? (
                  <span className='text-4 text-gray-300'>Visit website</span>
                ) : (
                  ''
                )}
                {expandSideBar ? (
                  <span className='icon-wrap ml-auto'>
                    <IconsComponent
                      icon='reg-external-link'
                      size='xs'
                      fill='#D1D5DB'
                    />
                  </span>
                ) : (
                  ''
                )}
              </Link>
            </li>
            <li className={'menu-item relative px-6'}>
              <Button
                intent={'tertiary'}
                className='flex items-center justify-start gap-2 p-3 hover:bg-gray-900'
                onClick={() => setShowModal(true)}
              >
                <span className='icon-wrap'>
                  <IconsComponent
                    icon='fill-logout'
                    size='md'
                    fill='#ffffff'
                  />
                </span>
                {expandSideBar ? (
                  <span className='text-4 text-gray-300'>Logout</span>
                ) : (
                  ''
                )}
              </Button>
              {/* Logout Confirmation Modal */}
              <LogoutModal
                showModal={showModal}
                setShowModal={setShowModal}
                // onLogoutConfirm={logout} // Call the logout function on confirmation
              />
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default SideBarMenu;
