import { createContext } from 'react';

/**
 * @description manage the state of the filter modal on table.
 */
export const FilterContext = createContext<{
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  setShowModal: () => {},
});
