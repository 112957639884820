import { z } from 'zod';

import { BaseResponseSchema } from '@/schemas/common.schema';

export const CompanyRegistrationRequestSchema = z.object({
  companyName: z.string(),
  companyEmail: z.string(),
  companyWebsite: z.string(),
  companyBusinessRegistrationNumber: z.string(),
  country: z.string(),
  state: z.string(),
  suburb: z.string(),
  companyPhoneNumber: z.string(),
  accountEmail: z.string(),
  accountPassword: z.string(),
});

export const CompanyRegistrationResponsSchema = BaseResponseSchema.extend({
  payload: CompanyRegistrationRequestSchema,
});

export const GetCompanyInfoResponseSchema = BaseResponseSchema.extend({
  payload: z.object({
    companyEmail: z.string(),
    companyName: z.string(),
    emailVerified: z.boolean(),
    owner: z.object({
      email: z.string(),
      userId: z.string(),
    }),
    passwordSet: z.boolean(),
    registrationCompleted: z.boolean(),
    status: z.string(),
    _id: z.string(),
  }),
});
export const GetCompanyInfoRequestSchema = z.string(); // Expect companyId as a string

export const GetCompanyResendInvitationResponseSchema = BaseResponseSchema;
// Define the schema for the register request
export const RegisterCompanyRequestSchema = z.object({
  email: z.string(),
  name: z.string(), // Adjust password requirements as needed
});

// Define the schema for the register response
export const RegisterCompanyResponseSchema = BaseResponseSchema.extend({
  data: z.object({
    name: z.string(),
    email: z.string(),
    id: z.string().uuid(),
  }),
  message: z.string(),
});

export const TCompanyRegistrationSchema = z.object({
  name: z.string(),
  email: z.string(),
  user: z.object({
    email: z.string(),
    password: z.string(),
  }),
  website: z.string(),
  country: z.string(),
  state: z.string(),
  city: z.string(),
  phone: z.string(),
  countryCode: z.string(),
  registrationComplete: z.boolean(),
  _id: z.string().optional(),
});

export type TCompanyRegistrationRequestSchema = z.infer<
  typeof TCompanyRegistrationSchema
>;

export const CompanyRegistrationResponseSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  website: z.string().url(),
  registrationNumber: z.string(),
  country: z.string(),
  countryCode: z.string(),
  state: z.string(),
  city: z.string(),
  phone: z.string(),
  users: z.array(
    z.object({
      id: z.string().uuid(),
      email: z.string().email(),
      role: z.enum(['ADMIN', 'USER']), // You can add more roles if needed
      accountStatus: z.enum(['ACTIVE', 'INACTIVE']), // Assuming there are only these statuses
      isPasswordSet: z.boolean(),
    }),
  ),
});

export const TCompanyRegistrationResponsSchema = z.any();
