import { useState } from 'react';

import { Label, Select, FormGroup } from '@/components';
import {
  useAlert,
  useScrollToTop,
  useUpdateUserRoles,
} from '@/components/hooks';
import { RoleOptions as RolesAndPermissionOptions } from '@/data/Users.data';
import { useQueryClient } from '@tanstack/react-query';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { Role } from '@/schemas';

export function RolesAndPermission({
  id,
  isInvited,
  userRole,
}: {
  id: string;
  isInvited?: boolean;
  userRole: string;
}) {
  const [selectedRole, setSelectedRole] = useState(
    userRole ? userRole : RolesAndPermissionOptions[0].value,
  );
  const queryClient = useQueryClient();
  const { mutate } = useUpdateUserRoles();
  const { showAlert } = useAlert();
  const scrollToTop = useScrollToTop();

  const handleUpdateUserStatus = (role: string) => {
    setSelectedRole(role);
    const updateUserRole = {
      users: [id],
      role: role as Role,
    };
    mutate(updateUserRole, {
      onSuccess: (response) => {
        showAlert(response.message, 'success');
        queryClient.invalidateQueries({
          queryKey: [TANSTACK_QUERY_KEY.USER],
        });
        queryClient.invalidateQueries({
          queryKey: [TANSTACK_QUERY_KEY.USER_DETAIL],
        });
      },
      onError: (error: { message: string }) => {
        showAlert(error.message, 'error');
      },
      onSettled: () => {
        scrollToTop();
      },
    });
  };

  return (
    <FormGroup
      title='Roles and Permission'
      description='Add your basic information.'
    >
      <div className='space-y-1'>
        <Label htmlFor='role'>Select Role</Label>
        <Select
          name='role'
          closeMenuOnSelect
          options={RolesAndPermissionOptions}
          value={RolesAndPermissionOptions.filter(
            (role) => role.value === selectedRole,
          )}
          onChange={(newValue: unknown) => {
            const role = (newValue as { value: string }).value;
            handleUpdateUserStatus(role);
          }}
          isDisabled={!isInvited}
        />
      </div>
    </FormGroup>
  );
}
