import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { LoginStep } from '@/utils/constants';
import { useAlert, useUpdatePersonalInfo } from '@/components/hooks';
import { Button, Input, Label } from '@/components';
import { handleApiError } from '@/utils/helper';
import { useUserStore } from '@/store/user-store-new';
import {
  PersonalInfoFormSchema,
  TPersonalInfoForm,
} from '@/schemas/settings/personal/personal.schema';
import { TUpdatePersonalInfo } from '@/schemas';
import { PhoneNumberInput } from '@/components/ui/input-field/phone-number-input';
interface PersonalInfoProps {
  setLoginStep: React.Dispatch<React.SetStateAction<LoginStep>>;
  userId?: string;
}

export const PersonalInfoForm = ({ setLoginStep }: PersonalInfoProps) => {
  const personalInfoMethods = useForm<TPersonalInfoForm>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: undefined,
      designation: '',
    },
    resolver: zodResolver(PersonalInfoFormSchema),
  });
  const { showAlert } = useAlert();
  const { updateAuth } = useUserStore();

  const {
    handleSubmit,
    formState: { isValid },
  } = personalInfoMethods;

  const { mutate: updatePersonalInfo, isPending: isUpdating } =
    useUpdatePersonalInfo();

  const onSubmit: SubmitHandler<TPersonalInfoForm> = ({
    designation,
    firstName,
    lastName,
    phone,
  }) => {
    const phoneNumberWithCode = phone.split('-');

    const updatePersonalInfoPayload: TUpdatePersonalInfo = {
      firstName,
      lastName,
      designation,
      phone: phoneNumberWithCode[1],
      countryCode: phoneNumberWithCode[0],
    };

    updatePersonalInfo(updatePersonalInfoPayload, {
      onSuccess: () => {
        setLoginStep(LoginStep.TWOFA);
        updateAuth({ hasUserDetail: true });
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
    });
  };

  return (
    <div className='flex w-full flex-col p-2 sm:max-w-[496px] md:p-10'>
      <h6 className='text-left font-[Futura] text-[20px] font-bold leading-[28px] text-gray-500'>
        Welcome
      </h6>
      <h4 className='mb-10'>
        Complete Your Personal
        <br /> Information
      </h4>
      <FormProvider {...personalInfoMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='space-y-4'
        >
          <div className='space-y-4'>
            <div className='grid gap-6 sm:grid-cols-2'>
              <div className='space-y-1'>
                <Label htmlFor='firstName'>First Name</Label>
                <Input
                  type='text'
                  name='firstName'
                  placeholder='Enter your first name'
                />
              </div>
              <div className='space-y-1'>
                <Label htmlFor='lastName'>Last Name</Label>
                <Input
                  type='text'
                  name='lastName'
                  placeholder='Enter your last name'
                />
              </div>
            </div>
            <div className='space-y-1'>
              <Label htmlFor='phone'>Phone Number</Label>
              <PhoneNumberInput name='phone' />
            </div>

            <div className='space-y-1'>
              <Label htmlFor='designation'>Designation</Label>
              <Input
                type='text'
                name='designation'
                placeholder='Your designation'
              />
            </div>
            <div className='space-y-4'>
              <Button
                disabled={!isValid || isUpdating}
                type='submit'
                className='w-full'
              >
                {isUpdating ? 'Saving...' : 'Save Details'}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
