import { useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import {
  Button,
  IconsComponent,
  ImageComponent,
  Input,
  Label,
  Tooltip,
} from '@/components';
import { ROUTES } from '@/utils/constants';
import { TVerifiyCodeFormSchema } from '@/schemas';
import {
  scrollToTop,
  useAlert,
  useGetLoggedInUser,
  useGetLoggedInUserCompanySetting,
  useUserVerifyCode,
} from '@/components/hooks';
import { handleApiError } from '@/utils/helper';
import { useUserStore } from '@/store/user-store-new';
import { IconSpinner } from '@/components/ui/icons/regular/spinner';
import { usePersonalSettingStore } from '@/store/personal-setting.store';
import TwoFactorAuthenticationIllustration from '@/assets/images/illustration/twoFactorAuthentication.svg';

interface TwoFactorAuthEnableProps {
  secret: string;
  totpUrl: string;
}

export const TwoFactorAuthEnable = ({
  secret,
  totpUrl,
}: TwoFactorAuthEnableProps) => {
  const [modalScanQRCode, setModalScanQRCode] = useState(false);
  const [copied, setCopied] = useState(false);
  const qrValue = totpUrl;
  const textToCopy = secret;
  const navigate = useNavigate();
  const { updateAuth, setUser, setSystemDefault, removeStorage } =
    useUserStore();

  const { refetch: refetchLoggedInUserDetail } = useGetLoggedInUser();
  const { refetch: refetchLoggedInUserCompanySetting } =
    useGetLoggedInUserCompanySetting();

  const setIsAuthenticatorAppEnabled = usePersonalSettingStore(
    (state) => state.setIsAuthenticatorAppEnabled,
  );
  const { showAlert } = useAlert();

  const ScanQRCodeMethod = useForm({
    defaultValues: {
      verificationCode: '',
    },
  });

  const { handleSubmit } = ScanQRCodeMethod;

  const {
    isPending: verificationCodeLoading,
    isSuccess: handleVerifyCodeSuccess,
    mutate: verifyCode,
  } = useUserVerifyCode();

  const onSubmit: SubmitHandler<TVerifiyCodeFormSchema> = ({
    verificationCode,
  }) => {
    verifyCode(
      { token: verificationCode },
      {
        onSuccess: async ({ data }) => {
          const userDetails = await refetchLoggedInUserDetail();
          const companySetting = await refetchLoggedInUserCompanySetting();

          if (userDetails.isSuccess) {
            setUser({ ...userDetails.data.data });
          } else {
            removeStorage();
          }

          if (companySetting.isSuccess) {
            setSystemDefault({ ...companySetting.data });
          }

          updateAuth({ valid: data, isTwoFactorSet: true });
          setIsAuthenticatorAppEnabled(true);
          navigate(`/${ROUTES.OVERVIEW}`);
        },
        onError: (error) => {
          scrollToTop();
          showAlert(handleApiError(error), 'error');
        },
      },
    );
  };

  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <div className='mx-auto p-4'>
      <ImageComponent
        src={TwoFactorAuthenticationIllustration}
        alt='Enter verification code illustration'
        classes='w-[150px] h-[108px]'
      />
      {!modalScanQRCode ? (
        <div>
          <h4 className='mb-2'>Enable 2FA</h4>
          <p className='mb-6 w-full break-normal text-base'>
            To ensure the highest level of security for your account,
            <br /> we&apos;re requiring Two-Factor Authentication (2FA)
            <br /> activation.
          </p>
          <p className='mb-4 block text-base'>
            We recommend using one of the following app.
          </p>
          <div className='authentication-app-suggestion flex flex-wrap items-start gap-y-3 rounded-md border border-gray-200 p-3'>
            <div className='flex w-full items-center gap-2 py-3'>
              <span className='icon inline-flex h-5 w-5'>
                <IconsComponent icon='google-authenticator' />
              </span>
              Google Authenticator
            </div>
            <hr className='w-full' />
            <div className='flex w-full items-center gap-2 border-none py-3'>
              <span className='icon inline-flex h-5 w-5 items-center justify-center rounded bg-[#0078D7]'>
                <IconsComponent
                  icon='fill-lock'
                  size='sm'
                  fill='#ffffff'
                />
              </span>
              Microsoft Authenticator
            </div>
          </div>
          <p className='my-4 block text-base'>
            Open your authenticator app and get ready to scan the QR Code.
          </p>
          <Button
            type='submit'
            className='w-full'
            onClick={() => setModalScanQRCode(true)}
          >
            Scan QR Code
          </Button>
          {/* <div className='mt-6 flex justify-center'>
            <span className='text-base'>Don’t have a smart phone?&nbsp;</span>
            <Link
              to=''
              className='text-base font-semibold text-primary-500'
            >
              Enable Using SMS
            </Link>
          </div> */}
        </div>
      ) : (
        <>
          <h4 className='mb-2'>Scan QR Code</h4>
          <p className='mb-4 text-base'>
            Open your authenticator app to scan the following QR code.
          </p>
          <figure className='relative mx-auto mb-8 h-44 w-44'>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qrValue}
              viewBox={'0 0 256 256'}
            />
          </figure>
          <div className='mb-8 text-center'>
            <p className='mb-2 block text-base'>
              Trouble scanning code? Enter this key in your app instead
            </p>
            <div className='text-base text-blue-600'>
              {textToCopy}
              <Tooltip>
                <Tooltip.Trigger>
                  <button
                    className='ml-1 inline-block align-middle'
                    onClick={handleCopyText}
                  >
                    <IconsComponent
                      icon='reg-copy'
                      size='md'
                      fill='#2563EB'
                    />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content
                  position='top'
                  className='w-[170px] text-center'
                >
                  {!copied ? 'Click to Copy Key !' : 'Copied !'}
                </Tooltip.Content>
              </Tooltip>
            </div>
          </div>
          <hr className='mb-8 w-full' />
          <FormProvider {...ScanQRCodeMethod}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='space-y-4'
            >
              <div className='space-y-1'>
                <Label htmlFor='password'>Enter your verification code</Label>
                <Input
                  type='text'
                  name='verificationCode'
                  placeholder='Your Code'
                />
              </div>

              <Button
                variant={handleVerifyCodeSuccess ? 'green' : 'primary'}
                className='mb-6 w-full'
                type='submit'
                disabled={verificationCodeLoading}
              >
                {handleVerifyCodeSuccess ? (
                  <IconsComponent icon='reg-checkbox-tick' />
                ) : (
                  verificationCodeLoading && (
                    <IconSpinner
                      fill='white'
                      size={24}
                    />
                  )
                )}
                Enable 2FA
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};
