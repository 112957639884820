export const PRODUCT_FILTERS = Object.freeze({
  SHOE_PRICE_RANGE: 'price_range',
  SHOE_SIZE: 'sizes',
  SHOE_COLOR: 'shoe_color',
  SHOE_AVAILABLE_STOCKS: 'stock',
  SHOE_INDUSTRY_FOR: 'industries',
  SHOE_TECHNOLOGY: 'technologies',
  MIN_STOCK: 'minStock',
});

export const SINGLE_PRODUCT_FILTERS = Object.freeze({
  SUB_CATEGORY: 'sub-category',
  PRODUCT_ID: 'product-id',
  PRODUCT_TYPE_ID: 'productTypeId',
});

const COMMON_QUERY_CONSTANTS = Object.freeze({
  MIN: 'min',
  MAX: 'max',
});

export const QUERY_CONSTANTS = Object.freeze({
  PATH: 'path',
  SIZE: 'size',
  ROLES: 'roles',
  COLOR: 'color',
  STATUS: 'status',
  SEARCH: 'search',
  SORT: {
    FIELD: 'sortField',
    ORDER: 'sortOrder',
  },
  PAGE: 'page',
  LIMIT: 'limit',
  TEAMS: 'teams',
  TEAM_NAME: 'teamName',
  UPDATED_DATE: 'date',
  CATEGORIES: 'categories',
  PRICE_RANGE: {
    FROM: 'price_from',
    TO: 'price_to',
  },
  STOCK_RANGE: {
    FROM: 'stock_from',
    TO: 'stock_to',
  },
  PRODUCT_FILTERS,
  STEP: 'step',
  TOKEN: 'token',
  VERIFY_CODE: 'verify-code',
  CREATE: 'create',
  PRODUCT_ID: 'productId',
  ID: 'id',
  EMAIL: 'email',
  SINGLE_PRODUCT_FILTERS,
  CODE: 'code',
  QUERY_Q: 'q',
  CHECKOUT: 'checkout',
  CATEGORY_ID: 'categoryId',
  COLOUR_HEX: 'colourHex',
  COMMON_QUERY_CONSTANTS,
  PROMO_ID: 'promoId',
  TAB: 'tab',
  ORDER_PLACED_DATE: {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
  },
  OVERVIEW_CHART: {
    START_DATE: 'start',
    END_DATE: 'end',
  },

  SHOW_PREVIOUS: 'showPrevious',
  STRIPE: {
    SESSION_ID: 'session_id',
    CALLBACK_TYPE: 'type',
    ORDER_ID: 'orderId',
  },

  /**
   * @description This is the updated query key for the search query and needs to migrate other query keys to this after the migration is done on the backend for other routes.
   */
  SEARCH_QUERY: 'q',
  PAGE_SIZE: 'pageSize',
  OVERVIEW_FILTER: 'filter',
  OVERVIEW_DATE: {
    START_DATE: 'overview-startDate',
    END_DATE: 'overview-endDate',
    SHOW_PREVIOUS: 'showPrevious',
  },
});
