import {
  Button,
  Breadcrumb,
  IconsComponent,
  BreadcrumbItem,
} from '@/components';
import { useAlert } from '@/components/hooks';
import { useExportProducts } from '@/components/hooks/api/products/useExportProducts';
import { useGetRealTimeStockList } from '@/components/hooks/api/stock';
import { downloadCSV } from '@/components/hooks/utils/useDownloadCsv';
import { Skeleton } from '@/components/lib/Skeleton';
import { ROUTES } from '@/utils/constants';
import { formatDate } from '@/utils/helper/datetime.helper';

function UpdateAtDate() {
  const { data: realTimeStock, isPending, isError } = useGetRealTimeStockList();

  if (isPending) {
    return <Skeleton className='h-6 w-40' />;
  }
  if (isError) {
    return;
  }

  return (
    <p className='whitespace-nowrap text-base font-medium text-gray-600'>
      {realTimeStock.lastUpdateDate &&
        `Last updated on ${formatDate(realTimeStock.lastUpdateDate)}`}
    </p>
  );
}

export function ActionBar() {
  const { showAlert } = useAlert();

  const { isLoading, refetch } = useExportProducts();

  const handleExport = async () => {
    try {
      const response = await refetch();
      downloadCSV(response.data ?? '', 'exported_products.csv');
    } catch (error) {
      showAlert('Error exporting file', 'error');
    }
  };

  return (
    <>
      <div className='pb-8'>
        <Breadcrumb>
          <BreadcrumbItem href={`/${ROUTES.OVERVIEW}`}>
            Dashboard
          </BreadcrumbItem>
          <BreadcrumbItem>Real Time Stock</BreadcrumbItem>
        </Breadcrumb>
        <div className='items-center justify-between gap-4 space-y-4 sm:flex sm:space-y-0'>
          <h4>Real Time Stock</h4>
          <div className='items-center justify-between gap-4 space-y-4 sm:flex sm:space-y-0'>
            <UpdateAtDate />
            <Button
              variant='gray'
              intent='outline'
              className='w-full'
              disabled={isLoading}
              onClick={handleExport}
            >
              <IconsComponent
                size='md'
                icon='reg-export'
              />{' '}
              Export
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
