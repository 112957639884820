import { AllProductsApi } from '@/services';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import { useQuery } from '@tanstack/react-query';

export function useExportProducts() {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.PRODUCTS.EXPORT],
    queryFn: () => AllProductsApi.exportProductList(),
    enabled: false,
  });
}
