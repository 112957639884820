import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  productBuyPrice,
  productPriceMoq,
  productPriceType,
  productPriceForDrawer,
  productDiscountPercentage,
  productPriceTypeForDrawer,
  productDiscountLabel,
} from '@/utils/helper/product/product-price';
import { cn } from '@/utils/cn';
import Alert from '@/components/ui/alert';
import { Modal } from '@/components/ui/modal/Modal';
import { Badge } from '@/components/ui/badge/Badge';
import { Button } from '@/components/ui/button/Button';
import { TANSTACK_QUERY_KEY } from '@/utils/constants';
import ImageComponent from '@/components/ui/image/Image';
import { ROUTES } from '@/utils/constants/routes.constant';
import { ProductVariantsTable } from './ProductVariantsTable';
import { isEmpty, sortArray } from '@/utils/helper/general.helper';
import { formatCompanyPrice } from '@/utils/helper/country-locale';
import { QUERY_CONSTANTS } from '@/utils/constants/query.constants';
import { AppKeyConstants } from '@/utils/constants/app.key.constants';
import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { scrollToTop } from '@/components/hooks/utils/useScrollToTop';
import { ProductColorLabel } from '@/components/ui/product-colour-label';
import useSingleProductCartStore from '@/store/single-product-cart.store';
import { useImageFallback } from '@/components/hooks/utils/useImageFallback';
import { useURLSearchParams } from '@/components/hooks/router/useURLSearchParams';
import { useRemoveQueryParam } from '@/components/hooks/router/useRemoveQueryParam';
import { VariantTableSkeleton } from '@/components/ui/skeleton/VariantTableSkeleton';
import { useGetProductById } from '@/components/hooks/api/products/useGetProductById';
import { useGetOrderByCompany } from '@/components/hooks/api/orders/useGetOrderByCompany';
import { TSingleProductDrawerHeaderSchema } from '@/schemas/api/products/products.common.schema';
import { useGetUserOrderSessionId } from '@/components/hooks/api/orders/useGetUserOrderSessionId';
import { useAddProductToOrderSession } from '@/components/hooks/api/orders/useAddProductToOrderSession';
import { ProductDrawerHeader as ProductDrawerHeaderSkeleton } from '@/components/ui/skeleton/ProductDrawerHeader';
import { DiscountLabel } from '@/utils/constants/promotion.constant';

interface ProductDetailDrawerProps {
  path?: string;
  showProductDetailDrawer: boolean;
  setShowProductDetailDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProductCardProps
  extends Omit<TSingleProductDrawerHeaderSchema, 'features'> {
  imageSrc: string;
}

function ProductCardHeader({
  discount,
  imageSrc,
  moq,
  price,
  name,
  rrp,
  skuCode,
  colourName,
  colourHex,
  priceType,
}: ProductCardProps) {
  const productImage = useImageFallback(imageSrc);
  return (
    <div className='flex flex-wrap items-center justify-between gap-4 pr-14'>
      <figure className='flex items-center space-x-2'>
        {productImage && (
          <div className='size-16 rounded border border-gray-200 object-contain'>
            <ImageComponent
              src={productImage}
              classes='object-cover object-center w-full h-full'
            />
          </div>
        )}
        <figcaption>
          <h6 className='font-bold text-gray-800'>{name}</h6>
          <div className='flex items-center space-x-4'>
            <p>
              SKU <span className='text-gray-8 00'>{skuCode}</span>
            </p>
            <ProductColorLabel
              colourName={colourName}
              colourHex={colourHex}
            />
          </div>
        </figcaption>
      </figure>
      <div className='flex flex-col space-y-1 sm:place-items-end'>
        <p className='font-semibold text-gray-400'>BUY PRICE</p>
        <div className='flex items-center space-x-3'>
          <div className='flex items-baseline space-x-1'>
            <p className='text-xl font-bold text-gray-800'>
              {formatCompanyPrice(Number(price))}
            </p>
            {rrp !== null &&
              !isEmpty(priceType) &&
              Number(price) - Number(discount) !== 0 &&
              priceType !== PRICETYPE.RRP && (
                <p className='text-gray-400 line-through'>
                  {formatCompanyPrice(Number(discount))}
                </p>
              )}
          </div>
          {rrp !== null &&
            !isEmpty(priceType) &&
            priceType !== PRICETYPE.RRP && (
              <Badge variant='blue'>{priceType}</Badge>
            )}
        </div>
        <div className='flex items-center space-x-3'>
          <p className='font-semibold text-gray-400'>
            MOQ <span className='font-bold text-gray-800'>{moq}+</span>
          </p>
          {rrp && (
            <p className='font-semibold text-gray-400'>
              RRP&nbsp;
              <span className='font-bold text-gray-800'>
                {formatCompanyPrice(Number(rrp))}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function PriceTable({ isQuantityNotValid }: { isQuantityNotValid: boolean }) {
  const { productQuantity, totalCost } = useSingleProductCartStore();

  return (
    <table className='w-full border-separate'>
      <tbody>
        <tr>
          <th className='whitespace-nowrap py-2 text-left text-base font-normal text-gray-800'>
            Total Selected Products
          </th>
          <td className='text-right text-lg font-semibold text-gray-800'>
            {isQuantityNotValid ? 0 : productQuantity}
          </td>
        </tr>
        <tr>
          <th className='py-2 text-left text-base font-normal text-gray-800'>
            Total Cost
          </th>
          <td className='text-right text-2xl font-semibold text-gray-800'>
            {formatCompanyPrice(isQuantityNotValid ? 0 : totalCost)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function ProductDetailDrawer({
  showProductDetailDrawer,
  setShowProductDetailDrawer,
  path = '',
}: ProductDetailDrawerProps) {
  const navigate = useNavigate();
  const skipCleanupRef = useRef(false);
  const queryClient = useQueryClient();

  const { removeQueryParam } = useRemoveQueryParam();
  const [isShoeSizeGuideActive, setIsShoeSizeGuideActive] = useState(false);

  const { refetch: refetchOrderDetails } = useGetOrderByCompany();

  const {
    cleanSingleProductCartStore,
    isProductQuantityDirty,
    setProductPrice,
    setSingleProductDetailVariants,
    singleProductDetailVariants,
    sizeQuantityValues,
    productQuantity,
    setSizeQuantityValues,
    totalCost,
  } = useSingleProductCartStore();

  const { searchValue: productId } = useURLSearchParams(
    QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_ID,
  );

  const {
    data: drawerProductDetail,
    isLoading: drawerProductDetailLoading,
    isSuccess: isDrawerProductDetailFetchSuccess,
    isFetched: isDrawerProductDetailFetched,
  } = useGetProductById(productId, true);

  const discountedPercentage =
    showProductDetailDrawer &&
    drawerProductDetail?.prices &&
    productDiscountPercentage(drawerProductDetail.prices);

  const discountedMoq =
    showProductDetailDrawer &&
    drawerProductDetail?.prices &&
    productPriceMoq(drawerProductDetail.prices);

  const currentProductPriceType =
    showProductDetailDrawer &&
    drawerProductDetail?.prices &&
    (productPriceType(drawerProductDetail.prices) as PRICETYPE);

  const {
    mutate: getUserOrderSessionId,
    isPending: getUserOrderSessionIdLoading,
    data: orderSessionId,
  } = useGetUserOrderSessionId();

  const {
    mutate: addProductToOrderBySessionId,
    isPending: addProductToOrderBySessionIdLoading,
  } = useAddProductToOrderSession();

  const showSpecialPriceBanner =
    drawerProductDetail?.prices &&
    Math.max(Number(discountedMoq) - productQuantity, 0) > 0 &&
    !isShoeSizeGuideActive &&
    isProductQuantityDirty;

  const isProductAlreadyInCart = () => {
    return drawerProductDetail?.variants?.some(
      (variant) => (variant.cart?.quantity ?? 0) > 0,
    );
  };

  const handleSetProductPriceToStore = () => {
    const { variants } = drawerProductDetail ?? {};
    if (variants) {
      setSingleProductDetailVariants(sortArray(variants, 'size'));
    }
  };

  const handleRedirectToCartAfterFetchingOrderSessionId = (
    sessionId: string,
  ) => {
    const formData = {
      productId: productId,
      variants: sizeQuantityValues
        .filter((variant) => variant.stock !== 0 && variant.stock !== null)
        .map((item) => ({
          id: item.shoeSizeId,
          quantity: Number(item.stock),
        })),
    };

    addProductToOrderBySessionId(
      {
        sessionId,
        data: [formData],
      },
      {
        onSuccess: () => {
          skipCleanupRef.current = true;
          setShowProductDetailDrawer(false);
          removeQueryParam(QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_ID);
          cleanSingleProductCartStore();

          if (
            window.location.pathname.includes(ROUTES.CART) ||
            window.location.pathname.includes(ROUTES.PRODUCT.COMPARISON)
          ) {
            queryClient.invalidateQueries({
              queryKey: [TANSTACK_QUERY_KEY.PENDING_ORDERS],
            });
            scrollToTop();
            return;
          }

          refetchOrderDetails().then((response) => {
            if (response.isSuccess) navigate(`/${ROUTES.CART}`);
          });
        },
      },
    );
  };

  const handleProductAddToCart = () => {
    if (orderSessionId?.data) {
      handleRedirectToCartAfterFetchingOrderSessionId(orderSessionId.data.id);
    }
  };

  const isCartButtonDisabled = (): boolean => {
    return (
      isEmpty(drawerProductDetail) ||
      drawerProductDetailLoading ||
      getUserOrderSessionIdLoading ||
      addProductToOrderBySessionIdLoading ||
      productQuantity === 0 ||
      sizeQuantityValues.some(
        (item) => (item.stock ?? 0) < 0 || (item.stock ?? 0) % 1 !== 0,
      ) ||
      totalCost <= 0 ||
      !isProductQuantityDirty
    );
  };

  useEffect(() => {
    const { prices } = drawerProductDetail ?? {};

    if (prices) {
      const buyPrice = productPriceForDrawer(prices, productQuantity);

      setProductPrice(buyPrice);
      setSizeQuantityValues([...sizeQuantityValues]);
    }
  }, [productQuantity, drawerProductDetail]);

  useEffect(() => {
    if (showProductDetailDrawer) {
      getUserOrderSessionId();
    }
    return () => {
      if (!skipCleanupRef.current && showProductDetailDrawer) {
        removeQueryParam([
          QUERY_CONSTANTS.PAGE,
          QUERY_CONSTANTS.SINGLE_PRODUCT_FILTERS.PRODUCT_ID,
        ]);
        cleanSingleProductCartStore();
      }
    };
  }, [showProductDetailDrawer]);

  useEffect(() => {
    if (isDrawerProductDetailFetchSuccess) {
      handleSetProductPriceToStore();
    }
  }, [isDrawerProductDetailFetchSuccess]);

  return (
    <Modal
      showModal={showProductDetailDrawer}
      setShowModal={setShowProductDetailDrawer}
    >
      <Modal.SheetContent
        className={cn('flex h-full w-full flex-col lg:w-[800px] xl:w-[1200px]')}
      >
        <Modal.Title>
          {drawerProductDetailLoading && !isDrawerProductDetailFetched ? (
            <ProductDrawerHeaderSkeleton />
          ) : isEmpty(drawerProductDetail) ? (
            <div className='py-2' />
          ) : (
            <ProductCardHeader
              imageSrc={
                drawerProductDetail?.medias?.find(
                  (media) => media.type === 'THUMBNAIL',
                )?.url ?? ''
              }
              moq={productPriceMoq(drawerProductDetail?.prices)}
              skuCode={drawerProductDetail?.skuCode}
              name={drawerProductDetail?.name ?? ''}
              colourName={drawerProductDetail?.colourName ?? 'N/A'}
              colourHex={drawerProductDetail?.colourHex}
              price={productPriceForDrawer(
                drawerProductDetail?.prices,
                productQuantity,
              ).toString()}
              rrp={String(drawerProductDetail?.price)}
              discount={Number(drawerProductDetail?.price)}
              priceType={
                productPriceTypeForDrawer(
                  drawerProductDetail?.prices,
                  productQuantity,
                ) as PRICETYPE
              }
            />
          )}
        </Modal.Title>
        <div className='flex-grow overflow-y-auto'>
          <Modal.Body>
            {drawerProductDetailLoading && !isDrawerProductDetailFetched ? (
              <VariantTableSkeleton />
            ) : (
              <ProductVariantsTable
                isShoeSizeGuideActive={isShoeSizeGuideActive}
                setIsShoeSizeGuideActive={setIsShoeSizeGuideActive}
                singleProductDetailVariants={singleProductDetailVariants}
              />
            )}
          </Modal.Body>
        </div>
        <Modal.Footer
          className={cn(
            'max-h-xs flex shrink-0 flex-col gap-6 gap-x-[72px] tablet:h-[200px]',
            isShoeSizeGuideActive ? '' : 'tablet:flex-row',
          )}
        >
          {showSpecialPriceBanner && (
            <Alert
              type='info'
              autoClose={false}
              title='Special Price'
              description={`Add ${Math.max(Number(discountedMoq) - productQuantity, 0)} more ${Number(discountedMoq) - productQuantity === 1 ? 'pair' : 'pairs'} to get special discount up to ${
                currentProductPriceType === PRICETYPE.PROMO
                  ? formatCompanyPrice(
                      Number(productBuyPrice(drawerProductDetail.prices)),
                    )
                  : productDiscountLabel(drawerProductDetail.prices) ===
                      DiscountLabel.FIXED
                    ? formatCompanyPrice(
                        Number(productBuyPrice(drawerProductDetail.prices)),
                      )
                    : `${Math.round(Number(discountedPercentage))}%`
              }. Enjoy exclusive savings when you buy in larger quantity.`}
            />
          )}
          <div
            className={cn(
              'w-full space-y-4',
              isShoeSizeGuideActive ? 'ml-auto tablet:w-2/5' : 'tablet:w-2/5',
            )}
          >
            <PriceTable
              isQuantityNotValid={sizeQuantityValues.some(
                (item) => (item.stock ?? 0) < 0 || (item.stock ?? 0) % 1 !== 0,
              )}
            />
            <Button
              className='w-full'
              onClick={handleProductAddToCart}
              disabled={isCartButtonDisabled()}
            >
              {path === AppKeyConstants.CART.toString() ||
              isProductAlreadyInCart()
                ? 'Update '
                : 'Add  to '}{' '}
              Cart
            </Button>
          </div>
        </Modal.Footer>
      </Modal.SheetContent>
    </Modal>
  );
}
