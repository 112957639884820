import React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { ROUTES } from '@/utils/constants';
import { handleApiError } from '@/utils/helper';
import ImageComponent from '@/components/ui/image/Image';
import { LoginStep } from '@/utils/constants/auth.constants';
import {
  useAlert,
  useGetLoggedInUser,
  useGetLoggedInUserCompanySetting,
  useUserVerifyCode,
} from '@/components/hooks';
import { IconSpinner } from '@/components/ui/icons/regular/spinner';
import { Button, IconsComponent, Input, Label } from '@/components';
import { usePersonalSettingStore } from '@/store/personal-setting.store';
import { TVerifiyCodeFormSchema, VerifiyCodeFormSchema } from '@/schemas';
import VerifyCodeIllustration from '@/assets/images/illustration/verifyCode.svg';
import { useUserStore } from '@/store/user-store-new';

export interface SetLoginStep {
  setLoginStep: React.Dispatch<React.SetStateAction<LoginStep>>;
}

const VerifyCodeHeaders = ({ setLoginStep }: SetLoginStep) => {
  const { reset } = useFormContext<TVerifiyCodeFormSchema>();

  const returnToLoginPage = () => {
    reset();
    setLoginStep(LoginStep.LOGIN);
  };

  return (
    <div className='flex flex-col items-start gap-4 pb-10'>
      <Button
        intent='tertiary'
        size='xs'
        className='flex items-center gap-2 p-0 text-sm font-semibold uppercase tracking-wider text-primary-600'
        onClick={returnToLoginPage}
      >
        <IconsComponent
          icon='reg-chevron-left'
          size='xs'
          fill='#6e2534'
        />
        Back to login
      </Button>

      <div className='flex flex-col justify-start gap-4'>
        <ImageComponent
          src={VerifyCodeIllustration}
          alt='Enter verification code illustration'
          classes='w-[119px] h-[108px]'
        />
        <h4>Enter Verification Code</h4>
        <span className='font-normal text-gray-600'>
          Enter the unique code displayed on your authentication app.
        </span>
      </div>
    </div>
  );
};

const VerifiyCode = ({ setLoginStep }: SetLoginStep) => {
  const navigate = useNavigate();
  const { updateAuth, setUser, removeStorage, setSystemDefault } =
    useUserStore();

  const setIsAuthenticatorAppEnabled = usePersonalSettingStore(
    (state) => state.setIsAuthenticatorAppEnabled,
  );

  const { refetch: refetchLoggedInUserDetail } = useGetLoggedInUser();
  const { refetch: refetchLoggedInUserCompanySetting } =
    useGetLoggedInUserCompanySetting();

  const verificationCodeMethod = useForm<TVerifiyCodeFormSchema>({
    mode: 'onChange',
    defaultValues: {
      verificationCode: undefined,
    },
    resolver: zodResolver(VerifiyCodeFormSchema),
  });

  const { showAlert } = useAlert();

  const { handleSubmit } = verificationCodeMethod;

  const {
    isPending: verificationCodeLoading,
    isSuccess: handleVerifyCodeSuccess,
    mutate: verifyCode,
  } = useUserVerifyCode();

  const onSubmit: SubmitHandler<TVerifiyCodeFormSchema> = ({
    verificationCode,
  }) => {
    verifyCode(
      { token: verificationCode },
      {
        onSuccess: async ({ data }) => {
          const userDetails = await refetchLoggedInUserDetail();
          const companySetting = await refetchLoggedInUserCompanySetting();

          if (userDetails.isSuccess) {
            setUser({ ...userDetails.data.data });
          } else {
            removeStorage();
          }

          if (companySetting.isSuccess) {
            setSystemDefault({ ...companySetting.data });
          }

          updateAuth({ valid: data });
          setIsAuthenticatorAppEnabled(true);
          navigate(window.location.origin + `/${ROUTES.OVERVIEW}`);
        },
        onError: (error) => {
          showAlert(handleApiError(error), 'error');
        },
      },
    );
  };

  return (
    <div className='flex w-full flex-col p-2 sm:max-w-[496px] md:p-10'>
      <FormProvider {...verificationCodeMethod}>
        <VerifyCodeHeaders setLoginStep={setLoginStep} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='space-y-1'>
            <Label htmlFor='verificationCode'>Verification Code</Label>
            <Input
              id='verificationCode'
              name='verificationCode'
              type='number'
              placeholder='eg, 123 456'
              className='remove-apperance'
            />
          </div>
          <Button
            variant={handleVerifyCodeSuccess ? 'green' : 'primary'}
            size='md'
            className='my-6 w-full'
            type='submit'
            disabled={verificationCodeLoading}
            data-cy='verify-code-button'
          >
            {handleVerifyCodeSuccess ? (
              <IconsComponent icon='reg-checkbox-tick' />
            ) : (
              verificationCodeLoading && (
                <IconSpinner
                  fill='white'
                  size={24}
                />
              )
            )}
            {handleVerifyCodeSuccess
              ? 'Verified'
              : verificationCodeLoading
                ? 'Verifying'
                : 'Verify'}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default VerifiyCode;
