import { z } from 'zod';
import { useState } from 'react';
import { AxiosError } from 'axios';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  CreateTeamRequestSchema,
  CreateTeamResponseSchema,
  TBulkExportTeam,
  TEditTeamSchema,
  TMemberSchema,
  TTeamSchema,
  TeamAddMembersResponseSchema,
  TeamAddMembersSchema,
  TeamDetailsResponseSchema,
} from '@/schemas';
import {
  DeleteBulkTeamResponseSchema,
  DeleteUserOnTeamResponseSchema,
  TDeleteBulkTeam,
} from '@/schemas/team/deleteTeam.schema';
import { buildQuery } from '@/utils/helper';
import { getSearchParams } from '@/components/hooks/router';
import { TeamAPI } from '@/services/api/teams/teams.service';
import {
  API_ENDPOINT,
  QUERY_CONSTANTS,
  TANSTACK_QUERY_KEY,
} from '@/utils/constants';

interface ErrorResponse {
  message: string;
}

interface PageData {
  teams: TTeamSchema[];
  nextPage: number | null;
}
interface SimplifiedTeam {
  _id: string;
  name: string;
}
export function useGetAllTeams() {
  const {
    teamName,
    date: updatedAt,
    search,
    limit,
    page,
    sortField,
    sortOrder,
  } = getSearchParams([
    QUERY_CONSTANTS.TEAM_NAME,
    QUERY_CONSTANTS.UPDATED_DATE,
    QUERY_CONSTANTS.SEARCH,
    QUERY_CONSTANTS.PAGE,
    QUERY_CONSTANTS.LIMIT,
    QUERY_CONSTANTS.SORT.FIELD,
    QUERY_CONSTANTS.SORT.ORDER,
  ]);
  const path = buildQuery(API_ENDPOINT.TEAMS.LIST);

  return useQuery({
    queryKey: [
      TANSTACK_QUERY_KEY.TEAM,

      teamName,
      updatedAt,
      search,
      page,
      limit,
      sortField,
      sortOrder,
    ],
    queryFn: () => TeamAPI.getAllTeams(path)(),
    select: (data) => {
      const teamList = data.data;
      const totalTeams = data.meta.pagination.total;
      const totalPages = data.meta.pagination.pageCount;
      const currentPage = data.meta.pagination.page;

      const normalizedData = teamList.map((team) => ({
        id: team.id,
        name: team.name,
        users: team.users,
        description: team.description,
        createdAt: team.createdAt,
        updatedAt: team.updatedAt,
        companyId: team.companyId,
      }));

      return {
        totalTeams,
        totalPages,
        currentPage,
        teams: normalizedData,
      };
    },
  });
}
export async function fetchTeamsByPage(
  pageParam: number,
  search = '',
): Promise<PageData> {
  const PAGE_SIZE = 10;

  if (pageParam > 1) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }

  try {
    const response = await TeamAPI.getAllTeamsList({
      limit: PAGE_SIZE,
      page: pageParam,
      sortOrder: 'asc',
      sortField: 'name',
      search,
    });

    const fetchedTeams = response.data;
    const hasNextPage = fetchedTeams.length === PAGE_SIZE;

    return {
      teams: fetchedTeams,
      nextPage: hasNextPage ? pageParam + 1 : null,
    };
  } catch (error) {
    console.error('Error fetching teams:', error);
    throw error;
  }
}
export function useGetAllTeamsList() {
  const [search, setSearch] = useState<string>('');

  const query = useInfiniteQuery<
    PageData,
    Error,
    InfiniteData<SimplifiedTeam[]>
  >({
    queryKey: ['teams-listing', search],
    queryFn: ({ pageParam }) => fetchTeamsByPage(pageParam as number, search),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    select: (data) => ({
      pages: data.pages.map((page) =>
        page.teams.map(
          (team): SimplifiedTeam => ({
            _id: team.id,
            name: team.name ?? '',
          }),
        ),
      ),
      pageParams: data.pageParams,
    }),
  });
  return { query, setSearch };
}

export function useGetAllMemberToADD({ teamId }: { teamId: string }) {
  return useQuery({
    queryKey: ['all-team-members-listing'],
    queryFn: () => TeamAPI.getAllMemberToADD({ teamId })(),
    select(data) {
      const normalizedData = data.data.map((member: TMemberSchema) => {
        return {
          id: member.id,
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email ? member.email : null,
          avatar: member.avatar,
        };
      });
      return normalizedData;
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
}

export function useGetAllAddedMemberToTeam({ teamId }: { teamId: string }) {
  return useQuery({
    queryKey: ['added-team-members-listing'],
    queryFn: () => TeamAPI.addedMemberToTeam({ teamId })(),
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
}

export function useAddMemberToTeam() {
  return useMutation<
    z.infer<typeof TeamAddMembersResponseSchema>,
    AxiosError<ErrorResponse>,
    { id: string; data: z.infer<typeof TeamAddMembersSchema> }
  >({
    mutationFn: ({ id, data }) => TeamAPI.addMemberToTeam(id)(data),
  });
}

export function useCreateTeam() {
  return useMutation<
    z.infer<typeof CreateTeamResponseSchema>,
    AxiosError<ErrorResponse>,
    { data: z.infer<typeof CreateTeamRequestSchema> }
  >({
    mutationFn: ({ data }) => TeamAPI.createTeam()(data),
  });
}

export function useDeleteMemberOnTeam() {
  return useMutation<
    z.infer<typeof DeleteUserOnTeamResponseSchema>,
    AxiosError,
    { id: string; data: { userIds: string[] } }
  >({
    mutationFn: ({ id, data }) => TeamAPI.deleteMemberOnTeam(id)(data),
  });
}

export function useGetTeamDetails({ teamId }: { teamId: string }) {
  return useQuery({
    queryKey: [TANSTACK_QUERY_KEY.TEAM_DETAIL, teamId],
    queryFn: () => TeamAPI.getTeamDetails(teamId),
    select: (data) => data.data,
    enabled: !!teamId,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
}
export function useEditTeam() {
  return useMutation<
    z.infer<typeof TeamDetailsResponseSchema>,
    AxiosError<ErrorResponse>,
    { teamId: string; data: TEditTeamSchema }
  >({
    mutationFn: ({ teamId, data }) => TeamAPI.editTeam(teamId)(data),
  });
}

export function useBulkDeleteTeam() {
  return useMutation<
    z.infer<typeof DeleteBulkTeamResponseSchema>,
    AxiosError<ErrorResponse>,
    TDeleteBulkTeam
  >({
    mutationFn: (data) => TeamAPI.deleteBulkTeam(data),
  });
}

export function useBulkExportTeam() {
  return useMutation({
    mutationFn: (data: TBulkExportTeam) => TeamAPI.bulkExportTeam(data),
  });
}
