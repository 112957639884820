export enum PaymentMethod {
  DIRECT = 'DIRECT',
  CREDIT = 'CREDIT',
  STRIPE = 'STRIPE',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum PaymentCallbackType {
  SUCCESS = 'success',
  ERROR = 'error',
}
