import { IconsComponent } from '@/components';
import { Button } from '@/components/ui/button';
import { ViewContactSupportModal } from '@/pages/orders/my-orders/modals';
import { formatCompanyPrice } from '@/utils/helper/country-locale';

// Interface for the order summary
interface Summary {
  subtotal: number;
  carriageCost: number;
  estimatedVAT: number;
  orderTotal: number;
  shippingAmount: number;
}

// Interface for the order summary props
interface OrderSummaryProps {
  summary: Summary | undefined;
  setShowReOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
  companyName: string;
  customId: string;
}

export function OrderSummary({
  summary,
  setShowReOrderModal,
  customId,
  companyName,
}: OrderSummaryProps) {
  return (
    <div className='mt-4 space-y-6'>
      <div className='space-y-4'>
        <div className='flex text-sm md:justify-end'>
          <div className='w-full space-y-2 text-right md:w-3/6 xl:w-1/3'>
            <div className='flex justify-between'>
              <span className='text-muted-foreground text-base'>Subtotal</span>
              <span className='text-base font-semibold'>
                {formatCompanyPrice(Number(summary?.subtotal))}
              </span>
            </div>
            <div className='flex justify-between'>
              <span className='text-muted-foreground text-base'>
                Carriage Cost
              </span>
              <div className='items-center'>
                <span className='flex items-center gap-1 text-base text-green-600'>
                  {Number(summary?.shippingAmount) <= 0 && (
                    <>
                      <IconsComponent
                        icon='reg-check-circle'
                        fill='#16A34A'
                        size='sm'
                      />
                      <span>Free Shipping&nbsp;</span>
                    </>
                  )}
                  <span className='ml-1 font-semibold text-gray-800'>
                    {formatCompanyPrice(Number(summary?.shippingAmount))}
                  </span>
                </span>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center gap-2'>
                <span className='text-muted-foreground text-base'>
                  Estimated VAT
                </span>
                <IconsComponent
                  icon='reg-info'
                  fill='#6B7280'
                  size='sm'
                />
              </div>
              <span className='text-base font-semibold'>
                {formatCompanyPrice(Number(summary?.estimatedVAT))}
              </span>
            </div>
            <div className='flex justify-between border-t pt-4 text-base font-medium'>
              <span className='text-lg font-bold'>Order Total</span>
              <span className='text-2xl font-extrabold'>
                {formatCompanyPrice(Number(summary?.orderTotal))}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end gap-3'>
        <Button
          variant='gray'
          intent='tertiary'
          className='px-4'
          onClick={() => setShowReOrderModal(true)}
        >
          <IconsComponent
            size='md'
            icon='reg-reset'
          />{' '}
          Re-order
        </Button>
        <ViewContactSupportModal
          companyName={companyName}
          customId={customId}
        />
      </div>
    </div>
  );
}
