import { z } from 'zod';

import {
  CompanyAddressResponseSchema,
  addressRequestSchema,
  addressResponseSchema,
  deleteAddressRequestSchema,
  deleteAddressResponseSchema,
  getAddressInformationRequest,
  getSpecificAddressRequestSchema,
  getSpecificAddressResponseSchema,
  settingAddressPrimaryRequestSchema,
  settingAddressPrimaryResponseSchema,
} from '@/schemas/createShippingAddress.schema';
import { api } from '@/utils/helper';
import { API_ENDPOINT, HttpMethods } from '@/utils/constants';
import { deleteApi } from '@/utils/helper/api/delete.helpers';

const addSystemAddress = () =>
  api<
    z.infer<typeof addressRequestSchema>,
    z.infer<typeof addressResponseSchema>
  >({
    method: 'POST',
    path: API_ENDPOINT.ADDRESS,
    requestSchema: addressRequestSchema,
    responseSchema: addressResponseSchema,
  });

export const getCompanySystemAddress = api<
  z.infer<typeof getAddressInformationRequest>,
  z.infer<typeof CompanyAddressResponseSchema>
>({
  path: API_ENDPOINT.ADDRESS,
  method: HttpMethods.GET,
  requestSchema: getAddressInformationRequest,
  responseSchema: CompanyAddressResponseSchema,
});

export const getSpecificAddress = ({ addressId }: { addressId: string }) => {
  return api<
    z.infer<typeof getSpecificAddressRequestSchema>,
    z.infer<typeof getSpecificAddressResponseSchema>
  >({
    path: `${API_ENDPOINT.ADDRESS}/${addressId}`,
    method: HttpMethods.GET,
    requestSchema: getSpecificAddressRequestSchema,
    responseSchema: getSpecificAddressResponseSchema,
  });
};

const updateSystemAddress = ({ addressId }: { addressId: string }) =>
  api<
    z.infer<typeof addressRequestSchema>,
    z.infer<typeof addressResponseSchema>
  >({
    method: 'PATCH',
    path: `${API_ENDPOINT.ADDRESS}/${addressId}`,
    requestSchema: addressRequestSchema,
    responseSchema: addressResponseSchema,
  });

const removeAddress = ({ addressId }: { addressId: string }) => {
  return deleteApi<
    z.infer<typeof deleteAddressRequestSchema>,
    z.infer<typeof deleteAddressResponseSchema>
  >({
    method: 'DELETE',
    path: `${API_ENDPOINT.ADDRESS}/${addressId}`,
    requestSchema: deleteAddressRequestSchema,
    responseSchema: deleteAddressResponseSchema,
  });
};

const settingAddressPrimary = ({ addressId }: { addressId: string }) =>
  api<
    z.infer<typeof settingAddressPrimaryRequestSchema>,
    z.infer<typeof settingAddressPrimaryResponseSchema>
  >({
    method: 'PATCH',
    path: `${API_ENDPOINT.ADDRESS}/${addressId}/primary`,
    requestSchema: settingAddressPrimaryRequestSchema,
    responseSchema: settingAddressPrimaryResponseSchema,
  });

export const systemAPI = {
  addSystemAddress,
  getCompanySystemAddress,
  getSpecificAddress,
  updateSystemAddress,
  removeAddress,
  settingAddressPrimary,
};
