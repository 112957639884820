import { useContext, useState } from 'react';
import { useLocation, useSearchParams, useSubmit } from 'react-router-dom';

import {
  DateRangeWithSuggestion,
  Dropdown,
  FilterBadge,
  IconsComponent,
  Modal,
  SearchInputForm,
  SlideInPanel,
} from '@/components';
import { QUERY_CONSTANTS } from '@/utils/constants';
import { DateRange } from 'react-day-picker';
import { formatDateToYearMonthDay } from '@/utils/helper/datetime.helper';
import { FilterContext } from '@/components/context/FilterContext';
import { useIsDeviceMobileOrLarger } from '@/components/hooks';

function DateFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const isMobileOrLarger = useIsDeviceMobileOrLarger();
  const { setShowModal } = useContext(FilterContext);

  const submit = useSubmit();

  const from = searchParams.get(QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE);

  const to = searchParams.get(QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE);

  const defaultSelectedDateRange: DateRange | undefined =
    from && to
      ? {
          from: new Date(from),
          to: new Date(to),
        }
      : undefined;

  const handleApply = (dateRange: DateRange | undefined) => {
    searchParams.delete(QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE);
    searchParams.delete(QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE);

    searchParams.append(
      QUERY_CONSTANTS.ORDER_PLACED_DATE.START_DATE,
      dateRange?.from ? formatDateToYearMonthDay(dateRange.from) : '',
    );

    searchParams.append(
      QUERY_CONSTANTS.ORDER_PLACED_DATE.END_DATE,
      dateRange?.to ? formatDateToYearMonthDay(dateRange.to) : '',
    );

    setSearchParams(searchParams);

    submit(searchParams, { action: location.pathname, method: 'GET' });

    setIsDropDownVisible(false);
    setShowModal(false);
  };

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Order Placed Date
      </Dropdown.Toggle>
      <Dropdown.Content
        position={isMobileOrLarger ? 'right' : 'center'}
        className='pr-2 pt-5'
      >
        <DateRangeWithSuggestion
          handleApply={handleApply}
          defaultSelectedDateRange={defaultSelectedDateRange}
        />
      </Dropdown.Content>
    </Dropdown>
  );
}

export function MobileResponsiveFilterForm() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <Modal.Toggle
        size='xs'
        variant='gray'
        intent='tertiary'
        className='px-3'
      >
        <IconsComponent
          fill='gray'
          icon='reg-filter'
          size='md'
        />
        Filter By
      </Modal.Toggle>
      <Modal.Content className='h-[calc(100vh_-_4rem)]'>
        <Modal.Title>
          <h6>Filter By</h6>
        </Modal.Title>
        <Modal.Body className='h-[calc(100vh_-_10rem)] overflow-y-auto'>
          <div className='flex flex-col gap-4'>
            <FilterContext.Provider value={{ setShowModal }}>
              <DateFilter />
            </FilterContext.Provider>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

export function FilterForm() {
  const isMobileOrLarger = useIsDeviceMobileOrLarger();

  return (
    <>
      <div className='-mb-4 flex flex-wrap items-center justify-end gap-4 py-3'>
        <div
          id='action-button'
          className='w-full sm:mr-auto'
        />
        <div className='z-10 flex flex-wrap items-center gap-4 space-x-2'>
          <SearchInputForm />
          {isMobileOrLarger ? (
            <SlideInPanel direction='right'>
              <DateFilter />
            </SlideInPanel>
          ) : (
            <MobileResponsiveFilterForm />
          )}
        </div>
      </div>
      <FilterBadge />
    </>
  );
}
