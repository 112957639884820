import { PRICETYPE } from '@/utils/constants/product-stock.constants';
import { TPricesSchema } from '@/schemas/api/products/detail/singleProductDetail.schema';
import { DiscountLabel } from '@/utils/constants/promotion.constant';

const PRIORITY_ORDER = ['PROMO', 'SPECIAL', 'LIST', 'RRP'];

const getProductPricingDetailsByPriority = (
  prices: TPricesSchema = [],
): {
  bestPrice: number;
  bestType: string;
  discountPercentage: number;
  listPrice: number;
  rrpPrice: number;
  discountLabel: DiscountLabel;
} => {
  if (!prices || prices.length === 0) {
    throw new Error('Products array is empty or undefined');
  }

  let bestPrice: number | undefined;
  let bestType: string | undefined;
  let discountPercentage: number | undefined;
  let listPrice: number | undefined;
  let discountLabel: DiscountLabel | null;
  let bestPriority = Infinity;

  for (const price of prices) {
    const currentPriority = PRIORITY_ORDER.indexOf(price.priceType ?? '');

    if (currentPriority === -1) continue;

    if (currentPriority < bestPriority) {
      bestPriority = currentPriority;
      bestPrice = price.salePrice ?? 0;
      bestType = price.priceType ?? '';
      discountPercentage = price.discount ?? 0;
      discountLabel = price.discountLabel ?? null;
    }
  }

  const rrpPrice = prices.find(
    (price) => price.priceType === PRICETYPE.RRP,
  )?.salePrice;

  const specialPriceIndex = prices.findIndex(
    (price) => price.priceType === PRICETYPE.SPECIAL,
  );

  const listPriceIndex = prices.findIndex(
    (price) => price.priceType === PRICETYPE.LIST,
  );

  if (specialPriceIndex !== -1 && listPriceIndex !== -1) {
    listPrice =
      prices.find((price) => price.priceType === PRICETYPE.LIST)?.salePrice ??
      0;
  }

  return {
    bestPrice: bestPrice ?? 0,
    bestType: bestType ?? '',
    discountPercentage: discountPercentage ?? 0,
    listPrice: listPrice ?? 0,
    rrpPrice: rrpPrice ?? 0,
    discountLabel: discountLabel!,
  };
};

export const productBuyPrice = (prices: TPricesSchema = []): number => {
  return getProductPricingDetailsByPriority(prices).bestPrice;
};

export const productDiscountLabel = (
  prices: TPricesSchema = [],
): DiscountLabel => {
  return getProductPricingDetailsByPriority(prices).discountLabel;
};

export const productListPrice = (prices: TPricesSchema = []): number => {
  return getProductPricingDetailsByPriority(prices).listPrice;
};

export const productRrpPrice = (prices: TPricesSchema = []): number => {
  return getProductPricingDetailsByPriority(prices).rrpPrice;
};

export const productPriceType = (prices: TPricesSchema = []): string => {
  return getProductPricingDetailsByPriority(prices).bestType;
};

export const productPriceMoq = (prices: TPricesSchema = []): number => {
  if (!prices || prices.length === 0) {
    return 0;
  }

  for (const price of prices) {
    if (price.priceType === PRICETYPE.SPECIAL) {
      return Number(price.moq ?? 1);
    }
  }

  return 1;
};

export const productDiscountPercentage = (
  prices: TPricesSchema = [],
): number => {
  return getProductPricingDetailsByPriority(prices).discountPercentage;
};

export const isProductDynamicPricing = (
  prices: TPricesSchema = [],
): boolean => {
  if (!prices || prices.length === 0) {
    throw new Error('Products array is empty or undefined');
  }

  const priceTypes = new Set(prices.map((product) => product.priceType));

  return priceTypes.has('RRP' as PRICETYPE) && priceTypes.size > 1;
};

// Drawer related functions
export const productPriceTypeForDrawer = (
  prices: TPricesSchema = [],
  productQuantity = 0,
): string => {
  if (!prices?.length) {
    throw new Error('Products array is empty or undefined');
  }

  const specialPriceMoqValue = productPriceMoq(prices);
  const bestType = getProductPricingDetailsByPriority(prices).bestType;

  const doesListPriceExist = prices.some(
    (price) => price.priceType === PRICETYPE.LIST,
  );

  switch (bestType as PRICETYPE) {
    case PRICETYPE.SPECIAL:
      return productQuantity >= specialPriceMoqValue
        ? bestType
        : doesListPriceExist
          ? PRICETYPE.LIST
          : bestType;

    default:
      return bestType;
  }
};

export const productPriceForDrawer = (
  prices: TPricesSchema = [],
  productQuantity = 0,
): number => {
  if (!prices?.length) {
    throw new Error('Products array is empty or undefined');
  }

  const { bestPrice, listPrice, rrpPrice } =
    getProductPricingDetailsByPriority(prices);
  const specialPriceMoqValue = productPriceMoq(prices);
  const priceType = productPriceType(prices);

  const doesListPriceExist = prices.some(
    (price) => price.priceType === PRICETYPE.LIST,
  );

  if ((priceType as PRICETYPE) === PRICETYPE.SPECIAL) {
    return productQuantity >= specialPriceMoqValue
      ? bestPrice
      : doesListPriceExist
        ? listPrice
        : rrpPrice;
  }

  return bestPrice;
};

// Cart related functions
export const getProductPriceTypeForCart = (
  salePrice: number,
  prices: TPricesSchema = [],
) => {
  const priceType = prices?.find(
    (price) => Number(price.salePrice) === salePrice,
  )?.priceType;

  return priceType! as PRICETYPE;
};
