import { format } from 'date-fns';
import { useUserStore } from '@/store/user-store-new';

/**
 * @description Converts a 12-hour time format string to a 24-hour time format string.
 */
export function formatTo24Hour(time: string): string {
  const [hourMin, period] = time.split(' ');
  if (!hourMin || !period) throw new Error('Invalid time format');

  const [hourStr, minuteStr] = hourMin.split(':');
  const hour = Number(hourStr);
  const minute = Number(minuteStr);

  if (isNaN(hour) || isNaN(minute)) throw new Error('Invalid time format');

  const isPM = period.toLowerCase() === 'pm';
  const formattedHour =
    isPM && hour !== 12 ? hour + 12 : !isPM && hour === 12 ? 0 : hour;

  return `${String(formattedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
}

/**-
 * @description Converts a 24-hour time format string to a 12-hour time format string.
 */
export function formatTo12Hour(time: string): string {
  const [hourStr, minuteStr] = time.split(':');
  const hour = Number(hourStr);
  const minute = Number(minuteStr);

  if (isNaN(hour) || isNaN(minute)) throw new Error('Invalid time format');

  const period = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

  return `${String(formattedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
}

/**
 * @description Formats a time string based on the user's system default time format.
 */
export function formatTime(time: string): string {
  const timeFormat = useUserStore.getState().systemDefault?.timeFormat;
  return timeFormat === 'HOUR_12' ? formatTo12Hour(time) : formatTo24Hour(time);
}

/**
 * @description Formats a date string with timezone based on the user's system default timezone.
 */
function formatDateWithTimezone(date: string | Date) {
  const timeZone = useUserStore.getState().systemDefault?.timezone;
  return new Date(date).toLocaleString('en-US', { timeZone });
}

/**
 * @description Formats a date string based on the user's system default date format.
 */
export function formatDate(
  date: string | Date | undefined,
  isChart = false,
): string {
  if (!date) return 'N/A';

  const dateFormat = useUserStore.getState().systemDefault?.dateFormat;

  switch (dateFormat) {
    case 'MM_DD_YYYY':
      return format(
        formatDateWithTimezone(date),
        isChart ? 'MM/dd' : 'MM/dd/yyyy',
      );
    case 'DD_MM_YYYY':
      return format(
        formatDateWithTimezone(date),
        isChart ? 'dd/MM' : 'dd/MM/yyyy',
      );
    case 'YYYY_MM_DD':
      return format(
        formatDateWithTimezone(date),
        isChart ? 'yyyy/MM' : 'yyyy/MM/dd',
      );

    case 'DD_MMMM_YYYY':
      return format(
        formatDateWithTimezone(date),
        isChart ? 'dd MMMM' : 'dd MMMM, yyyy',
      );
    default:
      return format(
        formatDateWithTimezone(date),
        isChart ? 'MM-dd' : 'MM-dd-yyyy',
      );
  }
}

/**
 * @description Formats a date string to 'MM/dd/yyyy' format.
 */
export function formatDateToMonthDayYear(date: string | Date) {
  return format(new Date(date), 'MM/dd/yyyy');
}

/**
 * @description Formats a date string to 'yyyy-MM-dd' format.
 */
export function formatDateToYearMonthDay(date: string | Date) {
  return format(new Date(date), 'yyyy-MM-dd');
}
