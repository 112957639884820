import { Skeleton } from '@/components/lib/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

interface RealTimeStockSkeletonProps {
  rowCount?: number;
  cellCount?: number;
  headers?: string[];
}

export function RealTimeStockSkeleton({
  rowCount = 10,
  cellCount = 8,
  headers = [
    'Product',
    'Sku',
    'Color',
    'Size',
    'Price',
    'Stock',
    'Category',
    'Actions',
  ],
}: RealTimeStockSkeletonProps) {
  return (
    <Table className='RealTimeStockSkeleton mt-[104px] w-full overflow-auto'>
      <TableHeader>
        <TableRow>
          {headers.map((header, index) => (
            <TableHead key={index}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: rowCount }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from({ length: cellCount }).map((__, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
