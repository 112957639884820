import React from 'react';
import { IconProps } from '../iconProps';

export const IconSmileySad: React.FC<IconProps> = ({
  size,
  fill,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2363_19)'>
        <path
          d='M12 24C5.3835 24 0 18.6165 0 12C0 5.3835 5.3835 0 12 0C18.6165 0 24 5.3835 24 12C24 18.6165 18.6165 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM15.7507 19.5C15.4807 19.5 15.2212 19.3545 15.087 19.101C15.0457 19.0305 14.397 18 12 18C9.57075 18 8.93775 19.0575 8.9115 19.1033C8.709 19.455 8.259 19.5915 7.90125 19.4017C7.54275 19.2097 7.39725 18.7778 7.57875 18.4147C7.677 18.2183 8.634 16.5 12 16.5C15.366 16.5 16.323 18.2183 16.4212 18.4147C16.6065 18.7852 16.4565 19.236 16.086 19.4212C15.978 19.4745 15.8632 19.5 15.7507 19.5ZM16.125 15C14.199 15 13.5 13.6545 13.5 12.75C13.5 12.336 13.8352 12 14.25 12C14.6602 12 14.9932 12.3292 15 12.7372C15.0105 12.969 15.1418 13.5 16.125 13.5C17.196 13.5 17.25 12.8752 17.25 12.75C17.25 12.336 17.5853 12 18 12C18.4147 12 18.75 12.336 18.75 12.75C18.75 13.6545 18.051 15 16.125 15ZM7.875 15C5.949 15 5.25 13.6545 5.25 12.75C5.25 12.336 5.586 12 6 12C6.41025 12 6.74325 12.3292 6.75 12.7372C6.7605 12.969 6.89175 13.5 7.875 13.5C8.946 13.5 9 12.8752 9 12.75C9 12.336 9.336 12 9.75 12C10.164 12 10.5 12.336 10.5 12.75C10.5 13.6545 9.801 15 7.875 15Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2363_19'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
