import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { TBulkDeleteUser } from '@/schemas';
import { Button, Modal } from '@/components';
import { handleApiError } from '@/utils/helper';
import { useUserStore } from '@/store/user-store-new';
import { ROUTES, TANSTACK_QUERY_KEY, UserDelete } from '@/utils/constants';
import { useBulkDeleteUser, scrollToTop, useAlert } from '@/components/hooks';

/**
 * @description DeleteUserModal component
 */
export function DeleteUserModal({
  selectedRowId,
  handleResetRowSelection,
}: {
  selectedRowId: string[];
  handleResetRowSelection: () => void;
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const { isPending, mutate } = useBulkDeleteUser();

  const { user } = useUserStore();
  const isAdminLoggedIn = selectedRowId.some((id) => user?.id === id);
  const handleBulkDelete = () => {
    const bulkDeleteUser: TBulkDeleteUser = {
      users: selectedRowId,
    };
    mutate(bulkDeleteUser, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [TANSTACK_QUERY_KEY.USER] });
        handleResetRowSelection();
        navigate(`/${ROUTES.USER_AND_TEAMS.USER.LIST}`);
        setTimeout(() => {
          showAlert(UserDelete, 'success');
        }, 150);
      },
      onError: (error) => {
        showAlert(handleApiError(error), 'error');
      },
      onSettled: () => {
        scrollToTop();
        setShowDeleteModal(false);
      },
    });
  };
  return (
    <Modal
      showModal={showDeleteModal}
      setShowModal={setShowDeleteModal}
    >
      <Modal.Toggle
        size='xs'
        variant='red'
        intent='outline'
        disabled={isAdminLoggedIn}
        className='w-full px-3'
      >
        Delete Users
      </Modal.Toggle>
      <Modal.Content>
        <Modal.Title>
          <h6 className=''>Delete Users</h6>
        </Modal.Title>

        <Modal.Body className='space-y-4'>
          <p className='text-base text-gray-800'>
            You are about to delete{' '}
            <span className='font-bold'>{selectedRowId.length} User.</span>
            All the information related to these users will be lost.
          </p>
          <p className='text-base font-bold text-gray-800'>
            Are you sure you want to delete users?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            variant='red'
            disabled={isPending}
            onClick={handleBulkDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
