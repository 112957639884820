import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-router-dom';

import {
  Button,
  ColorBadge,
  Dropdown,
  DropDownLoadingSkeleton,
  FilterBadge,
  FilterCheckboxOption,
  IconsComponent,
  Modal,
  Range,
  SearchInputForm,
  SlideInPanel,
} from '@/components';
import { HttpMethods, QUERY_CONSTANTS } from '@/utils/constants';
import {
  useFilterSubmit,
  useIsDeviceMobileOrLarger,
  useRangeFilter,
  useURLSearchParams,
} from '@/components/hooks';

import { TableFilterContext } from '.';
import { ProductFilterKeys, useProductFilterStore } from '@/store';
import { isEmpty } from '@/utils/helper';
import { useGetCategoriesFilterOptions } from '@/components/hooks/api/stock';
import { getCurrencySymbol } from '@/utils/helper/country-locale';
import { FilterContext } from '@/components/context/FilterContext';

function ColorFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const filter = useContext(TableFilterContext)?.colours;

  const colorOption = filter?.map((color) => ({
    value: color.hex,
    label: color.name,
  }));

  const {
    handleSubmit,
    selectedOptions,
    isButtonDisabled,
    setSelectedOptions,
  } = useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR);

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Colour
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <Form
          method={HttpMethods.GET}
          onSubmit={(event) => {
            handleSubmit(event);
            setIsDropDownVisible(false);
          }}
        >
          <p className='px-5 pt-4 text-base font-bold text-gray-800'>Colour</p>
          <div className='max-h-36 overflow-y-auto p-2'>
            {colorOption?.map((color) => (
              <FilterCheckboxOption
                key={color.value}
                name={QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_COLOR}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                {...color}
              >
                <ColorBadge shoeProductColor={color.value} />
              </FilterCheckboxOption>
            ))}
          </div>
          <hr />
          <div className='p-4'>
            <Button
              size='xs'
              type='submit'
              className='w-full'
              disabled={isButtonDisabled}
            >
              Apply Filter
            </Button>
          </div>
        </Form>
      </Dropdown.Content>
    </Dropdown>
  );
}

function SizeFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const filter = useContext(TableFilterContext)?.sizes;

  const sizeOption = filter?.map((item) => ({
    value: item.size,
    label: item.size,
  }));

  const {
    handleSubmit,
    selectedOptions,
    isButtonDisabled,
    setSelectedOptions,
  } = useFilterSubmit(QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE);

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Size
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <Form
          method={HttpMethods.GET}
          onSubmit={(event) => {
            handleSubmit(event);
            setIsDropDownVisible(false);
          }}
        >
          <p className='px-5 pt-4 text-base font-bold text-gray-800'>Color</p>
          <div className='max-h-36 overflow-y-auto p-2'>
            {sizeOption?.map((size) => (
              <FilterCheckboxOption
                key={size.value}
                name={QUERY_CONSTANTS.PRODUCT_FILTERS.SHOE_SIZE}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                {...size}
              />
            ))}
          </div>
          <hr />
          <div className='p-4'>
            <Button
              size='xs'
              type='submit'
              className='w-full'
              disabled={isButtonDisabled}
            >
              Apply Filter
            </Button>
          </div>
        </Form>
      </Dropdown.Content>
    </Dropdown>
  );
}

function CategoryFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { setShowModal } = useContext(FilterContext);

  const {
    handleSubmit,
    selectedOptions,
    isButtonDisabled,
    setSelectedOptions,
  } = useFilterSubmit(QUERY_CONSTANTS.CATEGORIES);

  const { data, isPending, isError } = useGetCategoriesFilterOptions();

  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Categories
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        {(() => {
          switch (true) {
            case isPending:
              return <DropDownLoadingSkeleton />;
            case isError:
              return (
                <div className='px-2 py-4 text-center font-medium text-red-500'>
                  Error! Please try again
                </div>
              );
            default:
              return (
                <Form
                  method={HttpMethods.GET}
                  onSubmit={(event) => {
                    handleSubmit(event);
                    setIsDropDownVisible(false);
                    setShowModal(false);
                  }}
                >
                  <p className='px-5 pt-4 text-base font-bold text-gray-800'>
                    Categories
                  </p>
                  <div className='p-2'>
                    {data.categories.map((category) => (
                      <FilterCheckboxOption
                        key={category.value}
                        name={QUERY_CONSTANTS.CATEGORIES}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        {...category}
                      />
                    ))}
                  </div>
                  <hr />
                  <div className='p-4'>
                    <Button
                      size='xs'
                      type='submit'
                      className='w-full'
                      disabled={isButtonDisabled}
                    >
                      Apply Filter
                    </Button>
                  </div>
                </Form>
              );
          }
        })()}
      </Dropdown.Content>
    </Dropdown>
  );
}

function PriceRangeFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const priceRangeData = useContext(TableFilterContext)?.price;

  const currencySymbol = getCurrencySymbol();

  const {
    toggleFilter,
    showPriceRangeSlider,
    setShoePriceRange,
    setInitialShoePriceRange,
    initialShoePriceRange,
  } = useProductFilterStore();

  const roundRangeValues = (value: number | string) => {
    return Math.round(Number(value));
  };

  const { searchValue: urlShoePriceRangeFrom } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.FROM,
  );
  const { searchValue: urlShoePriceRangeTo } = useURLSearchParams(
    QUERY_CONSTANTS.PRICE_RANGE.TO,
  );

  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: Infinity,
  });

  const { handleSubmit, isButtonDisabled, range, setRange } = useRangeFilter({
    initialRange: initialShoePriceRange,
    queryKeyFrom: QUERY_CONSTANTS.PRICE_RANGE.FROM,
    queryKeyTo: QUERY_CONSTANTS.PRICE_RANGE.TO,
    ...priceRange,
  });

  const handleSubmitNewPriceRange = () => {
    handleSubmit();
    setIsDropDownVisible(false);
  };

  useEffect(() => {
    setShoePriceRange(
      roundRangeValues(range.inputFrom),
      roundRangeValues(range.inputTo),
    );
  }, [range]);

  useEffect(() => {
    if (!isEmpty(urlShoePriceRangeFrom) && !showPriceRangeSlider) {
      toggleFilter(ProductFilterKeys.PriceRangeSlider);
    }
  }, [urlShoePriceRangeFrom]);

  useEffect(() => {
    const min = roundRangeValues(priceRangeData?.min ?? 0);
    const max = roundRangeValues(priceRangeData?.max ?? 100);

    const prevMin = roundRangeValues(initialShoePriceRange.inputFrom);
    const prevMax = roundRangeValues(initialShoePriceRange.inputTo);

    const urlMin = roundRangeValues(urlShoePriceRangeFrom);
    const urlMax = roundRangeValues(urlShoePriceRangeTo);

    if (isEmpty(priceRangeData?.min) && isEmpty(priceRangeData?.max)) {
      if (priceRange.min === 0 && priceRange.max === Infinity) {
        setPriceRange({
          min: urlMin,
          max: urlMax,
        });
      } else return;
    } else {
      setPriceRange({
        min: Math.min(urlMin, min, prevMin),
        max: Math.max(urlMax, max, prevMax),
      });

      if (urlMax > 0 && urlMin > 0) {
        setInitialShoePriceRange(urlMin, urlMax);
      } else {
        setInitialShoePriceRange(min, max);
      }
    }
  }, [priceRangeData]);
  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Price Range
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <p className='px-5 py-4 text-base font-bold text-gray-800'>
          Price Range
        </p>
        <div className='p-4'>
          <div>
            <Range
              range={range}
              setRange={setRange}
              {...priceRange}
            >
              <Range.Slider prefixSymbol={currencySymbol} />
              <Range.Input />
            </Range>
          </div>
        </div>
        <hr />
        <div className='p-4'>
          <Button
            size='xs'
            type='button'
            className='w-full'
            onClick={handleSubmitNewPriceRange}
            disabled={isButtonDisabled}
          >
            Apply Filter
          </Button>
        </div>
      </Dropdown.Content>
    </Dropdown>
  );
}

function StockRangeFilter() {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const stockRangeData = useContext(TableFilterContext)?.stock;

  const {
    toggleFilter,
    showStockRangeSlider,
    setShoeStockRange,
    setInitialShoeStockRange,
    initialShoeStockRange,
  } = useProductFilterStore();

  const roundRangeValues = (value: number | string) => {
    return Math.round(Number(value));
  };

  const { searchValue: urlShoeStockRangeFrom } = useURLSearchParams(
    QUERY_CONSTANTS.STOCK_RANGE.FROM,
  );
  const { searchValue: urlShoeStockRangeTo } = useURLSearchParams(
    QUERY_CONSTANTS.STOCK_RANGE.TO,
  );

  const [stockRange, setStockRange] = useState({
    min: 0,
    max: Infinity,
  });

  const { handleSubmit, isButtonDisabled, range, setRange } = useRangeFilter({
    initialRange: initialShoeStockRange,
    queryKeyFrom: QUERY_CONSTANTS.STOCK_RANGE.FROM,
    queryKeyTo: QUERY_CONSTANTS.STOCK_RANGE.TO,
    ...stockRange,
  });

  const handleSubmitNewPriceRange = () => {
    handleSubmit();
    setIsDropDownVisible(false);
  };

  useEffect(() => {
    setShoeStockRange(
      roundRangeValues(range.inputFrom),
      roundRangeValues(range.inputTo),
    );
  }, [range]);

  useEffect(() => {
    if (!isEmpty(urlShoeStockRangeFrom) && !showStockRangeSlider) {
      toggleFilter(ProductFilterKeys.StockRangeSlider);
    }
  }, [urlShoeStockRangeFrom]);

  useEffect(() => {
    const min = roundRangeValues(stockRangeData?.min ?? 0);
    const max = roundRangeValues(stockRangeData?.max ?? 100);

    const prevMin = roundRangeValues(initialShoeStockRange.inputFrom);
    const prevMax = roundRangeValues(initialShoeStockRange.inputTo);

    const urlMin = roundRangeValues(urlShoeStockRangeFrom);
    const urlMax = roundRangeValues(urlShoeStockRangeTo);

    if (isEmpty(stockRangeData?.min) && isEmpty(stockRangeData?.max)) {
      if (stockRange.min === 0 && stockRange.max === Infinity) {
        setStockRange({
          min: urlMin,
          max: urlMax,
        });
      } else return;
    } else {
      setStockRange({
        min: Math.min(urlMin, min, prevMin),
        max: Math.max(urlMax, max, prevMax),
      });

      if (urlMax > 0 && urlMin > 0) {
        setInitialShoeStockRange(urlMin, urlMax);
      } else {
        setInitialShoeStockRange(min, max);
      }
    }
  }, [stockRangeData]);
  return (
    <Dropdown
      isVisbile={isDropDownVisible}
      setIsVisible={setIsDropDownVisible}
    >
      <Dropdown.Toggle
        size='xs'
        showIcon
        variant='gray'
        className='w-full px-3'
        intent='outline'
        iconFillColor='#4b5563'
      >
        Stock Range
      </Dropdown.Toggle>
      <Dropdown.Content
        position='right'
        className='w-full p-0 sm:w-60'
      >
        <p className='px-5 py-4 text-base font-bold text-gray-800'>
          Stock Range
        </p>
        <div className='p-4'>
          <div>
            <Range
              range={range}
              setRange={setRange}
              {...stockRange}
            >
              <Range.Slider />
              <Range.Input />
            </Range>
          </div>
        </div>
        <hr />
        <div className='p-4'>
          <Button
            size='xs'
            type='button'
            className='w-full'
            onClick={handleSubmitNewPriceRange}
            disabled={isButtonDisabled}
          >
            Apply Filter
          </Button>
        </div>
      </Dropdown.Content>
    </Dropdown>
  );
}

function FilterFormGroup() {
  return (
    <>
      <CategoryFilter />
      <ColorFilter />
      <StockRangeFilter />
      <SizeFilter />
      <PriceRangeFilter />
    </>
  );
}

function MobileResponsiveFilterForm() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <Modal.Toggle
        size='xs'
        variant='gray'
        intent='tertiary'
        className='px-3'
      >
        <IconsComponent
          fill='gray'
          icon='reg-filter'
          size='md'
        />
        Filter By
      </Modal.Toggle>
      <Modal.Content className='h-[calc(100vh_-_4rem)]'>
        <Modal.Title>
          <h6>Filter By</h6>
        </Modal.Title>
        <Modal.Body>
          <div className='flex flex-col gap-4'>
            <FilterContext.Provider value={{ setShowModal }}>
              <FilterFormGroup />
            </FilterContext.Provider>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

export function FilterForm() {
  const isMobileOrLarger = useIsDeviceMobileOrLarger();

  return (
    <>
      <div className='-mb-4 flex flex-wrap items-center justify-end gap-4 py-3'>
        <div
          id='action-button'
          className='w-full sm:mr-auto'
        />
        <div className='z-10 flex flex-wrap items-center gap-2 lg:flex-nowrap'>
          <SearchInputForm />
          {isMobileOrLarger ? (
            <SlideInPanel direction='right'>
              <FilterFormGroup />
            </SlideInPanel>
          ) : (
            <MobileResponsiveFilterForm />
          )}
        </div>
      </div>
      <FilterBadge />
    </>
  );
}
