import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  RegisterCompanyFormSchema,
  TRegisterCompanyFormSchema,
} from '@/schemas';
import { useAlert } from '@/components/hooks';
import { handleApiError } from '@/utils/helper';
import ImageComponent from '@/components/ui/image/Image';
import {
  InvitationLink,
  LinkExpired,
  RegisterStep,
  ROUTES,
} from '@/utils/constants';
import CompleteRegistration from './CompleteRegistration';
import { IconSpinner } from '@/components/ui/icons/regular/spinner';
import { Alert, Button, IconsComponent, Input, Label } from '@/components';
import { useRegistration } from '@/components/hooks/api/auth/useCompanyRegistration';
import { useResendCompanyRegistrationLink } from '@/components/hooks/api/company/useResendCompanyRegistrationLink';
import RegisterInvitationSentSuccessfullyIllustration from '@/assets/images/illustration/registerInvitationSentSuccessfully.svg';

interface RegisterCompanyFormProps {
  setRegisterStep: React.Dispatch<React.SetStateAction<RegisterStep>>;
  onSuccess: (id: string) => void; // Update to accept the company ID
}
interface RegisterInvitationSentSuccessfullyProps {
  companyId: string | null; // Accept company ID as a prop
}

const RedirectToLogin = () => {
  const navigate = useNavigate();

  const returnToLoginPage = () => {
    navigate(`/${ROUTES.LOGIN}`);
  };

  return (
    <Button
      intent='tertiary'
      size='xs'
      className='mb-6 flex items-center !justify-start p-0 text-sm font-semibold uppercase tracking-wider'
      onClick={returnToLoginPage}
    >
      <IconsComponent
        icon='reg-chevron-left'
        size='xs'
        fill='#6e2534'
      />
      Back to login
    </Button>
  );
};

export const RegisterCompanyForm: React.FC<RegisterCompanyFormProps> = ({
  setRegisterStep,
  onSuccess,
}) => {
  const { handleSubmit, getValues } =
    useFormContext<TRegisterCompanyFormSchema>();
  const { showAlert } = useAlert();

  const { mutate: handleRegistration, isPending: isRegistrationLoading } =
    useRegistration();

  const onSubmit = () => {
    const companyData = getValues();
    handleRegistration(companyData, {
      onSuccess: (data) => {
        if (data.data.id) {
          onSuccess(data.data.id);
          setRegisterStep(RegisterStep.REGISTER_INVITATION_SUCCESSFUL);
        }
      },
      onError: (error: Error) => {
        showAlert(handleApiError(error), 'error');
      },
    });
  };

  return (
    <div className='flex w-full flex-col p-2 sm:max-w-[496px] md:p-10'>
      <RedirectToLogin />
      <h4 className='mb-10'>Create a new account</h4>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          void handleSubmit(onSubmit)();
        }}
        className='space-y-4 md:w-[416px]'
      >
        <div className='space-y-1'>
          <Label htmlFor='companyName'>Company Name</Label>
          <Input
            type='text'
            name='name'
            placeholder='Enter company name'
          />
        </div>
        <div className='space-y-1'>
          <Label htmlFor='companyEmail'>Email</Label>
          <Input
            type='email'
            name='email'
            placeholder='Enter company email'
          />
        </div>
        <Button
          className='mb-6 w-full'
          type='submit'
          variant={'primary'}
          disabled={isRegistrationLoading}
          data-cy='send-invitation-link-button'
        >
          {isRegistrationLoading && (
            <IconSpinner
              fill='white'
              size={24}
            />
          )}
          Send Invitation Link
        </Button>
      </form>
    </div>
  );
};

const RegisterInvitationSentSuccessfully: React.FC<
  RegisterInvitationSentSuccessfullyProps
> = ({ companyId }) => {
  const { getValues } = useFormContext<TRegisterCompanyFormSchema>();
  const companyEmail = getValues('email');
  const email = companyEmail;
  const [localPart] = email.split('@');
  const maskedEmail = `${localPart.charAt(0)}***@******.com`;
  const { showAlert } = useAlert();

  const { mutate: resendLink, isPending: isResending } =
    useResendCompanyRegistrationLink();

  const handleResendLink = () => {
    if (companyId) {
      resendLink(companyId, {
        onSuccess: () => {
          showAlert(InvitationLink, 'success');
        },
        onError: () => {
          showAlert(LinkExpired, 'error');
        },
      });
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <ImageComponent
        src={RegisterInvitationSentSuccessfullyIllustration}
        alt='Enter verification code illustration'
        classes='w-[400px] h-[240px]'
      />
      <h4 className='text-center'>Invitation link sent successfully</h4>
      <p className='text-center text-base text-gray-800'>
        Check your inbox for an email sent to&nbsp;
        <span className='font-bold text-primary-700'>{maskedEmail}</span>
      </p>
      <div className='mt-14 flex flex-col items-center justify-center'>
        <div className='text-gray-800'>Didn&apos;t receive the link?</div>
        <Button
          className='font-bold text-primary-700'
          intent={'tertiary'}
          onClick={handleResendLink}
          disabled={isResending}
        >
          Resend Link
        </Button>
      </div>
    </div>
  );
};

const Register = () => {
  const [registerStep, setRegisterStep] = useState<RegisterStep>(
    RegisterStep.REGISTER,
  );
  const { isAlertVisible, isAlertDismissed, alertMessage, isType } = useAlert(); // Use the custom hook
  const [companyId, setCompanyId] = useState<string | null>(null); // State for company ID

  const registerMethods = useForm<TRegisterCompanyFormSchema>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
    },
    resolver: zodResolver(RegisterCompanyFormSchema),
  });

  const stepComponents = {
    [RegisterStep.REGISTER]: (
      <RegisterCompanyForm
        setRegisterStep={setRegisterStep}
        onSuccess={setCompanyId} // Pass the company ID on success
      />
    ),
    [RegisterStep.REGISTER_INVITATION_SUCCESSFUL]: (
      <RegisterInvitationSentSuccessfully companyId={companyId} />
    ),
    [RegisterStep.COMPLETE_REGISTRATION]: <CompleteRegistration />,
  };

  const currentComponent = stepComponents[registerStep];

  return (
    <>
      {isAlertVisible && !isAlertDismissed && (
        <Alert
          type={isType}
          classes='mt-4 mb-1.5'
          title={alertMessage}
        />
      )}
      {registerStep !== RegisterStep.COMPLETE_REGISTRATION ? (
        <div className='flex min-h-[calc(100vh_-_76px)] justify-center py-24'>
          <FormProvider {...registerMethods}>{currentComponent}</FormProvider>
        </div>
      ) : (
        <CompleteRegistration />
      )}
    </>
  );
};
export default Register;
