import { z } from 'zod';

import { BaseResponseSchema, phoneNumberSchema } from './common.schema';

export const addressType = z.enum(['BILLING', 'SHIPPING']);
export const CreateShippingAddressSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Title is required' })
    .max(100, { message: 'Title is too long' })
    .trim(),
  contactName: z
    .string()
    .min(1, { message: 'Name is required' })
    .max(100, { message: 'Name is too long' })
    .trim(),
  companyName: z
    .string()
    .min(1, { message: 'Company is required' })
    .max(100, { message: 'Company is too long' })
    .trim(),
  country: z.string().min(1, { message: 'Country is required' }),
  state: z.string().nullable().optional(),
  city: z
    .string()
    .min(1, { message: 'Suburb is required' })
    .max(60, { message: 'City is too long' }),
  phone: phoneNumberSchema,
  isActive: z.boolean().default(false).optional(),
  isPrimary: z.boolean().default(false).optional(),
});

export type TCreateShippingAddress = z.infer<
  typeof CreateShippingAddressSchema
>;

export const addressRequestSchema = z.object({
  addressType: addressType,
  title: z.string(),
  contactName: z.string(),
  companyName: z.string(),
  country: z.string(),
  countryCode: z.string(),
  state: z.string().nullable().optional(),
  city: z.string(),
  phone: z.string().optional(),
  isActive: z.boolean(),
  isPrimary: z.boolean(),
});

export type TAddressRequestSchema = z.infer<typeof addressRequestSchema>;

export const addressResponseSchema = z.object({
  message: z.literal('Success'),
  timestamp: z.string().optional(),
  data: z.object({
    id: z.string(),
    companyId: z.string(),
    addressType: addressType,
    title: z.string(),
    companyName: z.string(),
    phone: z.string(),
    country: z.string(),
    countryCode: z.string(),
    state: z.string().nullable().optional(),
    city: z.string(),
    contactName: z.string(),
    isActive: z.boolean(),
    isPrimary: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
    deletedAt: z.string().nullable().optional(),
  }),
});
export type TAddressResSchema = z.infer<typeof addressResponseSchema>;

export const getAddressInformationRequest = z.void();

export const AddressSchema = z.object({
  id: z.string().uuid(),
  companyId: z.string().uuid().optional(),
  addressType: addressType,
  title: z.string(),
  companyName: z.string(),
  phone: z.string().optional(),
  country: z.string(),
  countryCode: z.string(),
  state: z.string().nullable().optional(),
  city: z.string(),
  contactName: z.string(),
  isActive: z.boolean(),
  isPrimary: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable().optional(),
});

export type TAddressSchema = z.infer<typeof AddressSchema>;

// Define the main response schema
export const CompanyAddressResponseSchema = z.object({
  message: z.string(),
  data: z.array(AddressSchema),
  timestamp: z.string().optional(),
});

export const getSpecificAddressRequestSchema = z.void();

export type TGetSpecificAddressSchema = z.infer<
  typeof getSpecificAddressRequestSchema
>;

export const getSpecificAddressResponseSchema = z.object({
  message: z.string(),
  data: AddressSchema,
  timestamp: z.string().optional(),
});

export type TGetSpecificAddressResponseSchema = z.infer<
  typeof getSpecificAddressResponseSchema
>;

export const deleteAddressRequestSchema = z.void();

export const deleteAddressResponseSchema = z.object({
  message: z.string(),
});

export const settingAddressPrimaryRequestSchema = z.object({
  addressType: addressType,
});

export const settingAddressPrimaryResponseSchema = BaseResponseSchema;

export const cartAddressUpdateRequestSchema = z.object({
  billingAddressId: z.string().uuid(),
  shippingAddressId: z.string().uuid(),
});

export const cartAddressUpdateResponseSchema = BaseResponseSchema;
