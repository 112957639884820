import ImageDefaultSrc from '@/assets/images/illustration/NoTeamsFound.svg';
import { cn } from '@/utils/cn';

interface NoResultProps {
  title?: string;
  desc?: string;
  classes?: string;
  imageSrc?: string;
  children?: React.ReactNode;
  descriptionClasses?: string;
}

// React functional component
const NoResult = ({
  title,
  desc,
  classes,
  imageSrc,
  children,
  descriptionClasses,
}: NoResultProps) => {
  return (
    <div className={cn('no-result px-5 py-16 text-center', classes)}>
      <div>
        <figure className='mb-[50px] flex items-center justify-center'>
          <img
            src={imageSrc ? imageSrc : ImageDefaultSrc}
            alt='no result'
          />
        </figure>
        {title && (
          <h2 className='mb-4 text-3xl font-bold text-gray-800'>{title}</h2>
        )}
        {desc && (
          <div
            className={cn(
              'text-base font-normal text-gray-800',
              descriptionClasses,
            )}
          >
            {desc}
          </div>
        )}

        {children && (
          <div className='mt-14 flex items-center justify-center'>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default NoResult;
